import { get, patch } from '../utils/axios';

export const getBolForm = async () => {
  const { data } = await get(`/forms`);
  return data;
};

export const saveOrEditForm = async (form: any[], formId?: string) => {
  const url = formId ? `/forms/${formId}` : '/forms';
  const { data } = await patch(url, { form });
  return data;
};
