import axios from 'axios';
import { auth } from '../firebase';

const baseURL = process.env.REACT_APP_API_URL;

const getHeaders = async () => {
  const token = await auth?.currentUser?.getIdToken();

  const headers: any = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };

  if (token) headers.Authorization = `Bearer ${token}`;

  return headers;
};

export const get = async (url: string, params = {}) => {
  const headers = await getHeaders();

  return axios({
    url: `${baseURL}${url}`,
    headers,
    params,
  });
};

export const post = async (url: string, data: any) => {
  const headers = await getHeaders();

  return axios({
    url: `${baseURL}${url}`,
    headers,
    method: 'POST',
    data,
    timeout: 1000 * 60, // 60 seconds
  });
};

export const patch = async (url: string, data: any) => {
  const headers = await getHeaders();

  return axios({
    url: `${baseURL}${url}`,
    headers,
    method: 'PATCH',
    data,
    timeout: 1000 * 60, // 60 seconds
  });
};
