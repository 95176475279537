import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import { useHistory } from 'react-router-dom';
import moment from 'moment';
import { CalendarMonth } from 'mdi-material-ui';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Col, Row } from 'reactstrap';
import clsx from 'clsx';
import FormComponent from '../FormComponent';
import Loader from '../Loader';
import { get } from '../../utils/axios';
import { visits } from '../../utils/constants';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../constants';
import { AuthContext } from '../../auth';

interface ComponentProps {
  cancel?: () => void;
  apply?: (fields) => any;
  isSubmitting?: boolean;
}

const useStyles = makeStyles(() => ({
  wrapper: {
    minWidth: '360px',
  },
  formLabel: {
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #ADADAD',
    paddingBottom: '0.5rem',
    fontWeight: 'bold',
  },
  formLabelExpand: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'space-between',
    cursor: 'pointer',
  },
  radio: {
    appearance: 'none',
    borderRadius: '25px',
    border: '2px solid #707070',
    padding: '8px',
    marginRight: '10px',
    marginLeft: '13px',
    background: 'rgba(255,226,2,0.04)',
  },
  checked: {
    background: 'rgba(255,226,2,0.04)',
  },
  visit: {
    fontSize: 17,
    margin: '8px 0 6px 0',
  },
}));

export type Logistic = {
  _id: string;
  name: string;
};

export default function WaireFilter({ isSubmitting, cancel, apply }: ComponentProps) {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const { handleSubmit, control } = useForm();
  const [fieldValues, setFieldValues] = useState(JSON.parse(localStorage.getItem('waire-field-values')) || {});

  const history = useHistory();
  const params = new URLSearchParams(history?.location?.search);
  const creator = params.get('creator');

  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    placeholderData: [],
  });

  const { data: logistics = [] } = useQuery<Logistic[]>({
    queryKey: QUERY_KEYS.all_logistics_customers_filter,
    queryFn: async () => {
      const { data } = await get('/logistic/all');
      return data;
    },
    enabled: user?.features?.logistic,
    placeholderData: [],
  });

  const updateFieldValues = (field: string, value: string) => {
    const newValues = { ...fieldValues };
    newValues[field] = value;
    setFieldValues(newValues);
    localStorage.setItem('waire-field-values', JSON.stringify(newValues));
  };

  const handleFilter = (data = {}) => {
    apply(data);
  };

  const renderCustomers = () => {
    if (!logistics.length || !user?.features?.logistic) return;

    return (
      <>
        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mb-2': true, 'mt-3': true })}>
          Customers
        </FormLabel>
        <Controller
          name="logistic"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              name="logistic"
              value={value || fieldValues['logistic']}
              onChange={(e) => {
                onChange(e.target.value);
                updateFieldValues('logistic', e.target.value);
              }}
            >
              <FormControlLabel key={'all'} value={'All'} control={<Radio />} label={'All'} />
              {logistics.map((logistic) => (
                <FormControlLabel key={logistic._id} value={logistic._id} control={<Radio />} label={logistic.name} />
              ))}
            </RadioGroup>
          )}
        />
      </>
    );
  };

  const renderTotalTruckTrips = () => {
    return (
      <>
        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mb-2': true, 'mt-3': true })}>
          Total Truck Trips
        </FormLabel>
        <Controller
          name="trips"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              name="trips"
              value={value || fieldValues['trips']}
              onChange={(e) => {
                onChange(e.target.value);
                updateFieldValues('trips', e.target.value);
              }}
            >
              <FormControlLabel key={'all'} value={'All'} control={<Radio />} label={'All'} />
              <FormControlLabel key={'class-8'} value={'Class 8'} control={<Radio />} label={'Class 8'} />
              <FormControlLabel key={'class-2E-7'} value={'Class 2E-7'} control={<Radio />} label={'Class 2E-7'} />
            </RadioGroup>
          )}
        />
      </>
    );
  };

  const renderTruckVisits = () => {
    return (
      <>
        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mb-2': true, 'mt-3': true })}>
          Truck Visits Categories
        </FormLabel>

        <Controller
          name="visits"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <RadioGroup
              name="visits"
              value={value || fieldValues['visits']}
              onChange={(e) => {
                onChange(e.target.value);
                updateFieldValues('visits', e.target.value);
              }}
            >
              <FormControlLabel key={'all'} value={'All'} control={<Radio />} label={'All'} />

              <span className={classes.visit}>ZE (Zero-Emission)</span>

              {visits.zeroEmission.map((visit) => (
                <FormControlLabel key={visit.value} value={visit.value} control={<Radio />} label={visit.label} />
              ))}

              <span className={classes.visit}>NZE (Near Zero-Emission)</span>

              {visits.nearZeroEmission.map((visit) => (
                <FormControlLabel key={visit.value} value={visit.value} control={<Radio />} label={visit.label} />
              ))}
            </RadioGroup>
          )}
        />
      </>
    );
  };

  return (
    <div className={classes.wrapper}>
      <FormComponent onSubmit={handleSubmit(handleFilter)} hasBorder={false} hasPadding={false}>
        <FormControl component="fieldset" className="mb-3">
          <FormLabel component="legend" className={classes.formLabel}>
            <Row className="mt-2">
              <Col md={6}>Timeframe</Col>
              <Col md={6} className="reset">
                <span onClick={cancel}>Reset</span>
              </Col>
            </Row>
          </FormLabel>
          <Row className="mt-2">
            <Col md={6}>
              <Controller
                defaultValue={fieldValues['startDate'] ? moment(fieldValues['startDate']) : null}
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const maxDate = new Date();
                  maxDate.setDate(maxDate.getDate() + 30);
                  return (
                    <KeyboardDatePicker
                      keyboardIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '112px',
                          }}
                        >
                          <CalendarMonth />
                          {value ? (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>{moment(value).format('MM/DD/yyyy')}</p>
                          ) : (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>Start Date</p>
                          )}
                        </div>
                      }
                      InputAdornmentProps={{ position: 'start' }}
                      style={!value ? { backgroundColor: '#F7F0B7', boxShadow: '0 3px 6px 0px #00000029' } : {}}
                      okLabel={<span style={{ color: '#000' }}>Ok</span>}
                      cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                      maxDateMessage={`Max selectable date is ${moment(maxDate).format('MM/DD/yyyy')}`}
                      maxDate={maxDate}
                      inputVariant="outlined"
                      disableToolbar
                      id="outboundDate"
                      name="outboundDate"
                      format="MM/DD/yyyy"
                      views={['year', 'month', 'date']}
                      value={value}
                      onChange={(date) => {
                        onChange(date);
                        updateFieldValues('startDate', date.format('MM/DD/yyyy'));
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md={6}>
              <Controller
                defaultValue={fieldValues['endDate'] ? moment(fieldValues['endDate']) : null}
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const maxDate = new Date();

                  maxDate.setDate(maxDate.getDate() + 30);
                  return (
                    <KeyboardDatePicker
                      keyboardIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '112px',
                          }}
                        >
                          <CalendarMonth />
                          {value ? (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>{moment(value).format('MM/DD/yyyy')}</p>
                          ) : (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>End Date</p>
                          )}
                        </div>
                      }
                      InputAdornmentProps={{ position: 'start' }}
                      style={!value ? { backgroundColor: '#F7F0B7', boxShadow: '0 3px 6px 0px #00000029' } : {}}
                      okLabel={<span style={{ color: '#000' }}>Ok</span>}
                      cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                      maxDateMessage={`Max selectable date is ${moment(maxDate).format('MM/DD/yyyy')}`}
                      maxDate={maxDate}
                      inputVariant="outlined"
                      disableToolbar
                      id="inboundDate"
                      name="inboundDate"
                      format="MM/DD/yyyy"
                      views={['year', 'month', 'date']}
                      value={value}
                      onChange={(date) => {
                        onChange(date);
                        updateFieldValues('endDate', date.format('MM/DD/yyyy'));
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </FormControl>

        {!creator && (
          <>
            <FormLabel component="legend" className={clsx(classes.formLabel, { 'mt-2': true, 'mb-2': true })}>
              Locations
            </FormLabel>

            <Controller
              name="outbound-location"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl variant="outlined" component="fieldset" className="mb-1" margin="normal">
                    <InputLabel id="outbound-location">Outbound ({locations?.length})</InputLabel>
                    <Select
                      value={value || fieldValues['outbound-location']}
                      onChange={(e) => {
                        onChange(e.target.value);
                        updateFieldValues('outbound-location', e?.target?.value as unknown as string);
                      }}
                      labelId="outbound-location"
                      label={`Outbound (${locations?.length})`}
                    >
                      {locations?.map((option, i) => (
                        <MenuItem key={i} value={option._id}>
                          {option.firstName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />

            <Controller
              name="inbound-location"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl variant="outlined" component="fieldset" className="mb-1" margin="normal">
                    <InputLabel id="inbound-location">Inbound ({locations?.length})</InputLabel>
                    <Select
                      value={value || fieldValues['inbound-location']}
                      onChange={(e) => {
                        onChange(e.target.value);
                        updateFieldValues('inbound-location', e?.target?.value as unknown as string);
                      }}
                      labelId="inbound-location"
                      label={`Inbound (${locations?.length})`}
                    >
                      {locations?.map((option, i) => (
                        <MenuItem key={i} value={option._id}>
                          {option.firstName}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </>
        )}

        {renderCustomers()}

        {renderTotalTruckTrips()}

        {renderTruckVisits()}

        <Row className="mt-4">
          <Col>
            <Button type="button" fullWidth variant="contained" color="default" onClick={cancel}>
              Cancel
            </Button>
          </Col>
          <Col>
            <Loader isLoading={isSubmitting} small>
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                APPLY
              </Button>
            </Loader>
          </Col>
        </Row>
      </FormComponent>
    </div>
  );
}
