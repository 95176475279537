import React, { useState, useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/styles';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import { AuthContext } from '../../auth';
import FormComponent from '../../components/FormComponent';
import { post } from '../../utils/axios';
import { queryClient } from '../../api/queryClient';
import { QUERY_KEYS } from '../../constants';
import { InputLabel } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  tooltipContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '12px',
  },
  checkboxes: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  label: {
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
  },
  input: {
    marginBottom: 20,
  },
}));

const PageLocationList = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const { handleSubmit, control, reset } = useForm();

  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleCreate = async (fields) => {
    setIsSubmitting(true);
    setApiError(null);

    const { email, address, name, features } = fields;

    try {
      await post('/user/location', { email, address, name, features });

      reset();

      queryClient.resetQueries({ queryKey: QUERY_KEYS.all_locations });

      toast.success('User created !', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setApiError(error?.response?.data?.message || error.message);
    }

    setIsSubmitting(false);
  };

  return (
    <>
      <Row>
        <Col>
          <Typography variant="h5">
            <b>Add New Location</b>
          </Typography>
        </Col>
      </Row>

      <Row>
        <Col md={8} lg={6} className="mt-4 mb-4">
          <Typography style={{ fontSize: '17px' }}>
            Adding a new location creates a mobile app user associated with your company’s Babaco Fulfillment account.
            An autogenerated password will be sent to the email you include below. Your team can use that to log into
            the mobile app. Note: the Location Name cannot be changed later.
          </Typography>
        </Col>
      </Row>

      <Row>
        <Col md={8} lg={6} className="mt-2">
          <FormComponent onSubmit={handleSubmit(handleCreate)} error={apiError}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="Location name"
                  name="name"
                  inputProps={{ maxLength: 64 }}
                />
              )}
              rules={{
                required: 'Location name required',
              }}
            />

            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="address"
                  label="Address"
                  name="address"
                  inputProps={{ maxLength: 64 }}
                />
              )}
              rules={{
                required: 'Address required',
              }}
            />

            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  label="Email"
                  name="email"
                  autoComplete="email"
                />
              )}
              rules={{
                required: 'Email required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Enter a valid e-mail address',
                },
              }}
            />

            <div className={classes.checkboxes}>
              {user?.features?.bol && (
                <Controller
                  name="features.bol"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={classes.checkbox}>
                      <Checkbox id="bol" name="bol" checked={value} onChange={onChange} />
                      <InputLabel htmlFor="bol" className={classes.label} style={{ marginTop: 10 }}>
                        BOL
                      </InputLabel>
                    </div>
                  )}
                />
              )}

              {user?.features?.inspection && (
                <Controller
                  name="features.inspection"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={classes.checkbox}>
                      <Checkbox id="inspection" name="inspection" checked={value} onChange={onChange} />
                      <InputLabel htmlFor="inspection" className={classes.label} style={{ marginTop: 10 }}>
                        Inspection
                      </InputLabel>
                    </div>
                  )}
                />
              )}

              {user?.features?.waire && (
                <Controller
                  name="features.waire"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={classes.checkbox}>
                      <Checkbox id="waire" name="waire" checked={value} onChange={onChange} />
                      <InputLabel htmlFor="waire" className={classes.label} style={{ marginTop: 10 }}>
                        WAIRE
                      </InputLabel>
                    </div>
                  )}
                />
              )}

              {user?.features?.logistic && (
                <Controller
                  name="features.logistic"
                  control={control}
                  render={({ field: { onChange, value } }) => (
                    <div className={classes.checkbox}>
                      <Checkbox id="logistic" name="logistic" checked={value} onChange={onChange} />
                      <InputLabel htmlFor="logistic" className={classes.label} style={{ marginTop: 10 }}>
                        3PL
                      </InputLabel>
                    </div>
                  )}
                />
              )}
            </div>

            <Button
              className="mt-3"
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              disabled={isSubmitting}
            >
              CREATE
            </Button>
          </FormComponent>
        </Col>
      </Row>

      <Row>
        <Col md={8} lg={6} className="mt-4">
          <Typography style={{ fontSize: '17px' }}>
            After you create the new location, your team member using a mobile device will (1) click the confirmation
            link in their email to verify that they received the invite (2) download and install the BabacoFulfillment
            app Google Play or the App Store, and (3) open the app and log in using their email and the password that
            was sent to them automatically.
          </Typography>
        </Col>
      </Row>
    </>
  );
};

export default PageLocationList;
