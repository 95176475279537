import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiDialog-paper': {
      maxWidth: 440,
      padding: 40,
      height: 400,
    },
  },
  heading: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 20,
  },
  desc: {
    fontSize: 12,
    maxWidth: '75%',
    margin: '20px auto',
    lineHeight: 1.1,
  },
  menu: {
    margin: '0 auto',
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const WaireDialog: React.FC<Props> = ({ open, onClose, children }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.container} onClose={onClose}>
      <Typography className={classes.heading}>CHOOSE TIMEFRAME</Typography>
      <Typography className={classes.desc}>
        In order to view and use your data, you must choose a timeframe from the Choose Timeframe dropdown menu.
      </Typography>

      <Box className={classes.menu}>{children}</Box>
    </Dialog>
  );
};

export default WaireDialog;
