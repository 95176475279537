import React from 'react';
import { VerifiedUser } from '@material-ui/icons';
import { LogoWrapper, TextWrapper } from './LogoText.style';

interface ComponentProps {
  filled?: boolean;
}

export default function LogoText({ filled }: ComponentProps) {
  return (
    <LogoWrapper filled={filled} style={{ marginLeft: '16px' }}>
      <VerifiedUser style={{ fontSize: '2rem' }} />
      <TextWrapper>
        Babaco<span>Fulfillment</span>
      </TextWrapper>
    </LogoWrapper>
  );
}
