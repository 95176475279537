import moment from 'moment';
import { visits } from '../utils/constants';

export const bolListKeys = (filters: any = {}): string[] => {
  const keys = [];

  let status = filters?.status?.open && !filters?.status?.closed && 1;
  if (!filters?.status?.open && filters?.status?.closed) status = 0;

  const params: any = {
    startDate: filters?.outboundDate && moment(filters.outboundDate).toISOString(),
    endDate: filters?.inboundDate && moment(filters.inboundDate).toISOString(),
    status,
    outbound: filters?.outboundLocation && filters?.outboundLocation,
    inbound: filters?.inboundLocation && filters?.inboundLocation,
    late: filters?.late,
    differences: filters?.alerts,
  };

  for (const field in filters?.search) {
    if (filters?.search?.[field] && typeof filters?.search?.[field] !== 'object')
      keys.push(`${field}:${filters?.search?.[field]}`);
    else if (typeof filters?.search?.[field] !== 'object') {
      for (const subfield in filters?.search?.[field]) {
        if (filters?.search?.[field]?.[subfield]) keys.push(`${field}.${subfield}:${filters?.search?.[field]}`);
      }
    }
  }

  for (const param in params) {
    if (params[param]?.length || params[param] === true || typeof params[param] === 'number')
      keys.push(`${param}=${params[param]}`);
  }

  return keys;
};

export const inspectionListKeys = (filters: any = {}): string[] => {
  const keys = [];

  const params: any = {
    startDate: filters?.startDate && moment(filters.startDate).toISOString(),
    endDate: filters?.endDate && moment(filters.endDate).toISOString(),
    inspector: filters?.inspector,
    location: filters?.location,
    tags: [],
  };

  if (filters?.issues) params['issues'] = filters?.issues;

  if (filters?.tags) {
    Object.keys(filters.tags).forEach((tag: string) => {
      if (filters?.tags?.[tag]) {
        keys.push(`${tag}:${filters.tags?.[tag]}`);
      }
    });
  }

  for (const param in params) {
    if (params[param]?.length || params[param] === true || typeof params[param] === 'number')
      keys.push(`${param}=${params[param]}`);
  }

  return keys;
};

export const waireListKeys = (filters: any = {}, locations: any, logistics: any): string[] => {
  const keys = [];

  let inbound = '';
  let outbound = '';

  let gvwr;
  let fuelType;

  if (filters?.['outbound-location']) {
    outbound = filters?.['outbound-location'];
    const found = locations.find((loc) => loc._id === outbound)?.firstName;
    keys.push(found);
  } else if (filters?.['inbound-location']) {
    inbound = filters?.['inbound-location'];
    const found = locations.find((loc) => loc._id === outbound)?.firstName;
    keys.push(found);
  }

  if (filters?.visits) {
    gvwr = filters?.visits?.split('/')[0]?.trim();
    fuelType = filters?.visits?.split('/')[1]?.trim();
  }

  if (filters?.startDate && filters?.endDate)
    keys.push(
      `${moment(filters.startDate).format('MMMM DD, YYYY')} - ${moment(filters.endDate).format('MMMM DD, YYYY')}`,
    );

  if (filters?.trips) keys.push(filters?.trips);

  if (filters?.visits) {
    const normalizedVisits = Object.keys(visits).reduce((acc, key) => {
      acc = [...acc, ...visits[key]];
      return acc;
    }, []);

    const found = normalizedVisits.find((item) => item.value === filters.visits)?.label;
    if (found) {
      keys.push(found.split('/')[0]?.trim());
      keys.push(found.split('/')[1]?.trim());
    }
  }

  if (filters?.logistic) {
    const found = logistics.find((loc) => loc._id === filters?.logistic)?.name;
    keys.push(found || 'All');
  }

  return keys;
};
