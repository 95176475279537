import { Checkbox, OutlinedInput } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Col } from 'reactstrap';
import { fetchCustomerProfile, updateCustomerProfile } from '../../api/customer';
import { queryClient } from '../../api/queryClient';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { toastError } from '../../utils/toastError';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  container: {
    marginTop: 40,
  },
  form: {
    width: '100%',
  },
  label: {
    marginBottom: 10,
    fontSize: 12,
    fontWeight: 'bold',
    color: '#000000',
  },
  input: {
    marginBottom: 20,
  },
  checkboxes: {
    display: 'flex',
    alignItems: 'center',
  },
  checkbox: {
    display: 'flex',
    alignItems: 'center',
  },
  picker: {
    marginTop: 20,
    marginBottom: 30,
  },
}));

export class Features {
  bol: boolean;
  inspection: boolean;
  waire: boolean;
  logistic: boolean;
}

type CustomerProfile = {
  firstName: string;
  lastName: string;
  company: string;
  address: string;
  email: string;
  phone: string;
  features: Features;
  overageFee: number;
  overageThreshold: number;
  billingCycle?: Date;
};

const Profile = () => {
  const params: any = useParams();

  const classes = useStyles();

  const [showPassword, setShowPassword] = useState(false);

  const {
    data: profile,
    error,
    isLoading,
  } = useQuery<any, any, CustomerProfile, string[]>({
    queryKey: [QUERY_KEYS.customer_profile, params.id],
    queryFn: () => fetchCustomerProfile(params.id),
    enabled: !!params.id,
    placeholderData: {},
  });

  const { handleSubmit, control } = useForm({
    defaultValues: {
      bol: false,
      waire: false,
      logistic: false,
      inspection: false,
    },
    values: {
      firstName: profile?.firstName,
      lastName: profile?.lastName,
      email: profile?.email,
      company: profile?.company,
      address: profile?.address,
      phoneNumber: profile?.phone,
      overageFee: profile?.overageFee,
      overageThreshold: profile?.overageThreshold,
      bol: profile?.features?.bol,
      inspection: profile?.features?.inspection,
      waire: profile?.features?.waire,
      logistic: profile?.features?.logistic,
      billingCycle: profile?.billingCycle || undefined,
      password: '',
    },
  });

  const { mutate: update, isLoading: updateIsLoading } = useMutation(
    (fields: any) => updateCustomerProfile(profile, fields, params.id),
    {
      onError: toastError,
      onSuccess: () => {
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.customer_profile, params.id] });
        queryClient.refetchQueries({ queryKey: [QUERY_KEYS.customers] });
        toast.success('Profile updated successfully', TOAST_CONFIG);
      },
    },
  );

  return (
    <div className={classes.container}>
      <Col md={{ size: 5, offset: 0 }}>
        <FormComponent
          className={classes.form}
          onSubmit={handleSubmit(update as any)}
          error={error?.response?.data?.message || ''}
        >
          <Controller
            name="firstName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="firstName" className={classes.label}>
                  First Name
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="firstName"
                  id="firstName"
                  required
                />
              </div>
            )}
            rules={{ required: 'First Name required' }}
          />

          <Controller
            name="lastName"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="lastName" className={classes.label}>
                  Last Name
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  required
                  fullWidth
                  name="lastName"
                  id="lastName"
                />
              </div>
            )}
            rules={{ required: 'Last Name required' }}
          />

          <Controller
            name="company"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="company" className={classes.label}>
                  Company
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  required
                  fullWidth
                  name="company"
                  id="company"
                />
              </div>
            )}
            rules={{
              required: 'Company required',
            }}
          />

          <Controller
            name="address"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="address" className={classes.label}>
                  Address
                </InputLabel>
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  required
                  fullWidth
                  name="address"
                  id="address"
                />
              </div>
            )}
            rules={{ required: 'Address required' }}
          />

          <Controller
            name="phoneNumber"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="phoneNumber" className={classes.label}>
                  Phone Number
                </InputLabel>
                <TextField
                  type="tel"
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  required
                  fullWidth
                  name="phoneNumber"
                  id="phoneNumber"
                />
              </div>
            )}
            rules={{
              required: 'Phone number required',
              pattern: {
                value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                message: 'Enter a valid phone number. Example: 212-456-7890',
              },
            }}
          />

          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="email" className={classes.label}>
                  Email
                </InputLabel>
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  fullWidth
                  id="email"
                  name="email"
                  autoComplete="email"
                />
              </div>
            )}
            rules={{
              required: 'Email required',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                message: 'Enter a valid e-mail address',
              },
            }}
          />

          <Controller
            name="password"
            control={control}
            render={({ field: { onChange, value } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="password" className={classes.label}>
                  Password
                </InputLabel>
                <TextField
                  onChange={onChange}
                  value={value}
                  variant="outlined"
                  fullWidth
                  name="password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
          />

          <Controller
            name="overageFee"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="overageFee" className={classes.label}>
                  Overage Fee
                </InputLabel>
                <OutlinedInput
                  onChange={onChange}
                  error={!!error}
                  value={value}
                  required
                  fullWidth
                  name="overageFee"
                  id="overageFee"
                  type="number"
                  startAdornment={<InputAdornment position="start">$</InputAdornment>}
                />
              </div>
            )}
            rules={{ required: 'Location Fee is required' }}
          />

          <Controller
            name="overageThreshold"
            control={control}
            render={({ field: { onChange, value }, fieldState: { error } }) => (
              <div className={classes.input}>
                <InputLabel htmlFor="overageThreshold" className={classes.label}>
                  Overage Threshold (Records)
                </InputLabel>
                <OutlinedInput
                  onChange={onChange}
                  error={!!error}
                  value={value}
                  required
                  fullWidth
                  name="overageThreshold"
                  id="overageThreshold"
                  type="number"
                />
              </div>
            )}
          />

          <div className={classes.checkboxes}>
            <Controller
              name="bol"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={classes.checkbox}>
                  <Checkbox id="bol" name="bol" checked={value} onChange={onChange} />
                  <InputLabel htmlFor="bol" className={classes.label} style={{ marginTop: 10 }}>
                    BOL
                  </InputLabel>
                </div>
              )}
            />

            <Controller
              name="inspection"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={classes.checkbox}>
                  <Checkbox id="inspection" name="inspection" checked={value} onChange={onChange} />
                  <InputLabel htmlFor="inspection" className={classes.label} style={{ marginTop: 10 }}>
                    Inspection
                  </InputLabel>
                </div>
              )}
            />

            <Controller
              name="waire"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={classes.checkbox}>
                  <Checkbox id="waire" name="waire" checked={value} onChange={onChange} />
                  <InputLabel htmlFor="waire" className={classes.label} style={{ marginTop: 10 }}>
                    WAIRE
                  </InputLabel>
                </div>
              )}
            />

            <Controller
              name="logistic"
              control={control}
              render={({ field: { onChange, value } }) => (
                <div className={classes.checkbox}>
                  <Checkbox
                    id="logistic"
                    name="logistic"
                    checked={value}
                    onChange={onChange}
                    disabled={!profile?.features?.waire}
                  />
                  <InputLabel htmlFor="logistic" className={classes.label} style={{ marginTop: 10 }}>
                    3PL
                  </InputLabel>
                </div>
              )}
            />
          </div>

          <Controller
            name="billingCycle"
            control={control}
            render={({ field: { onChange, value } }) => {
              return (
                <div className={classes.picker}>
                  <InputLabel htmlFor="billingCycle" className={classes.label}>
                    Billing Cycle Start Date
                  </InputLabel>
                  <KeyboardDatePicker
                    placeholder="Choose Date"
                    InputAdornmentProps={{ position: 'start' }}
                    style={!value ? { boxShadow: '0 3px 6px 0px #00000029' } : {}}
                    okLabel={<span style={{ color: '#000' }}>Ok</span>}
                    cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                    inputVariant="outlined"
                    disableToolbar
                    id="billingCycle"
                    name="billingCycle"
                    format="MM/DD/yyyy"
                    views={['year', 'month', 'date']}
                    value={moment(value)}
                    disabled={!!profile?.billingCycle}
                    onChange={onChange}
                  />
                </div>
              );
            }}
          />

          <Loader isLoading={isLoading || updateIsLoading} small>
            <Button type="submit" fullWidth variant="contained" color="primary">
              UPDATE
            </Button>
          </Loader>
        </FormComponent>
      </Col>
    </div>
  );
};

export default Profile;
