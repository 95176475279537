import React, { useState } from 'react';
import { Button, makeStyles, TextField, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { Controller, useForm } from 'react-hook-form';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { post, get } from '../../utils/axios';
import { QUERY_KEYS } from '../../constants';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toastError } from '../../utils/toastError';
import { queryClient } from '../../api/queryClient';

export const FieldList = styled.div``;
export const FieldRow = styled.div`
  margin: 36px 0 12px;

  .row {
    align-items: center;
  }
`;
export const FieldType = styled.div`
  font-family: 'Roboto Italic';
  padding: 16px;
  border: 1px solid #cccccc;
`;

export const FieldInfoContainer = styled.div`
  position: relative;
`;

const useStyles = makeStyles(() => ({
  form: {
    padding: '44px 20px',
  },
  locations: {
    marginTop: 20,
    marginBottom: 32,
  },
  location: {
    display: 'flex',
    alignItems: 'center',
    gap: 10,
    marginBottom: 16,
  },
  checkbox: {
    border: '1px solid #707070',
    height: 30,
    width: 30,
    cursor: 'pointer',
  },
}));

const CreateLogisticCustomer = () => {
  const classes = useStyles();

  const history = useHistory();

  const { handleSubmit, control } = useForm();

  const [selected, setSelected] = useState<Set<string>>(new Set());

  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    placeholderData: [],
  });

  const { mutate, isLoading } = useMutation(
    async (form: any) => {
      const { data } = await post(`/logistic`, { name: form.name, locations: Array.from(selected) });
      return data;
    },
    {
      onError: toastError,
      onSuccess: () => {
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.all_logistics_customers] });
        history.push(`/logistics`);
      },
    },
  );

  const selectLocation = (locationId: string) => {
    const updated = new Set(selected);
    if (updated.has(locationId)) updated.delete(locationId);
    else updated.add(locationId);
    setSelected(updated);
  };

  return (
    <>
      <Row className="mb-5">
        <Col md={5}>
          <Typography variant="h5" className="mb-3">
            WAIRE Compliance: Create 3PL Customer
          </Typography>
        </Col>
      </Row>
      <Row className="mb-4">
        <Col md={5}>
          <FormComponent onSubmit={handleSubmit(mutate as any)} hasBorder hasPadding={false} className={classes.form}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoComplete="off"
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="name"
                  label="*Customer Name"
                  name="name"
                />
              )}
              rules={{
                required: 'Customer Name required',
              }}
            />

            <Typography className="mt-3">Assign to Any 3PL-Managed Location:</Typography>

            <div className={classes.locations}>
              {locations.map((location) => (
                <div key={location._id} className={classes.location}>
                  <span
                    className={classes.checkbox}
                    style={{ background: selected.has(location._id) ? '#FFE202' : 'none' }}
                    onClick={() => selectLocation(location._id)}
                  />
                  <Typography>{location.firstName}</Typography>
                </div>
              ))}
            </div>

            <Loader isLoading={isLoading} small className="mt-2">
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isLoading}>
                CREATE
              </Button>
            </Loader>
          </FormComponent>
        </Col>
      </Row>
    </>
  );
};

export default CreateLogisticCustomer;
