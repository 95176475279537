import React, { useContext, useEffect, useState } from 'react';
import { Document, Font, Image, Page, PDFDownloadLink, StyleSheet, Text, View } from '@react-pdf/renderer';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { useParams } from 'react-router-dom';
import { FileDownload } from 'mdi-material-ui';
import { toast } from 'react-toastify';
import { AuthContext } from '../../auth';
import { formatDate } from '../../utils/helpers';
import Roboto from '../../styles/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../styles/fonts/Roboto-Bold.ttf';
import { get } from '../../utils/axios';
import { FUEL_TYPES } from '../../utils/constants';

const useStyles = makeStyles(() => ({
  box: {
    border: '1px solid',
    width: '372px',
    maxWidth: '400px',
    padding: '20px',
  },
  boxWrapper: {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'column',
  },
  pdfLink: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  listText: {
    fontSize: '14px',
    wordWrap: 'break-word',
  },
  pdfHeader: {
    fontSize: '24px',
    lineHeight: 1.3,
    wordWrap: 'break-word',
    borderBottom: '4px solid black',
    paddingBottom: '6px',
  },
  boxesWrapper: {
    display: 'flex',
  },
  waireType: {
    fontSize: '14px',
    lineHeight: 1.3,
    wordWrap: 'break-word',
    fontWeight: 'bold',
    margin: '12px 0px',
  },
  creator: {
    fontSize: '14px',
  },
}));

Font.register({ family: 'Roboto', src: Roboto });
Font.register({ family: 'RobotoBold', src: RobotoBold });

const pdfStyles = StyleSheet.create({
  page: {
    width: '100%',
  },
  mainView: {
    padding: '12px 12px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  section: {
    margin: 10,
    padding: 20,
    border: '1px solid black',
    width: '60%',
  },
  title: {
    fontSize: 18,
    fontFamily: 'RobotoBold',
    whiteSpace: 'wrap',
    fontWeight: 'bold',
    borderBottom: '4px solid black',
    paddingBottom: '6px',
  },
  subtitle: {
    fontSize: 14,
    lineHeight: 1.3,
    fontFamily: 'RobotoBold',
    marginBottom: '10px',
    marginTop: '10px',
  },
  text: {
    fontSize: 12,
    fontFamily: 'Roboto',
    lineHeight: 1.2,
    wordWrap: 'break-word',
  },
  image: {
    width: '100%',
    height: 360,
    objectFit: 'fill',
    border: '1px solid gray',
    marginTop: 10,
  },
});

const Waire = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const [waire, setWaire] = useState(null);

  const params: any = useParams();

  const fetchWaire = async () => {
    try {
      const { data } = await get(`/waires/${params.id}`);

      setWaire(data);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    fetchWaire();
  }, []);

  const PDFPage = ({ waire, user }: any) => (
    <Document>
      <Page style={pdfStyles.page}>
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignContent: 'center',
            backgroundColor: 'black',
            padding: '30 0',
            marginBottom: 16,
          }}
        >
          <Image src="/assets/babaco-logo.png" style={{ height: '', objectFit: 'cover', width: '300' }} />
        </View>
        <View style={pdfStyles.mainView}>
          <View style={pdfStyles.section}>
            <Text style={pdfStyles.title}>WAIRE {waire?.code ? waire.code : ''}</Text>
            <Text style={pdfStyles.subtitle}>{waire.type}</Text>
            <Text style={pdfStyles.text}>{formatDate(waire?.createdAt, user?.timezone)}</Text>
            <Text style={pdfStyles.text}>VIN: {waire?.vin || '-'}</Text>
            <Text style={pdfStyles.text}>Vehicle Class: {waire?.gvwr || waire?.vehicleType || '-'}</Text>
            <Text style={pdfStyles.text}>
              Fuel Type: {FUEL_TYPES[waire?.fuelTypePrimary] || waire?.fuelTypePrimary || '-'}
            </Text>
            {waire?.logistic && <Text style={pdfStyles.text}>3PL Customer: {waire.logistic}</Text>}
            {waire?.image && <Image style={pdfStyles.image} src={waire.image} />}
          </View>
        </View>

        <View
          style={{
            backgroundColor: 'black',
            padding: '16px 0',
            display: 'flex',
            justifyContent: 'center',
            alignContent: 'center',
            textAlign: 'center',
            marginTop: 'auto',
          }}
        ></View>
      </Page>
    </Document>
  );

  const generatePDFDownloadLink = () => {
    return (
      <PDFDownloadLink
        document={<PDFPage waire={waire} user={user} />}
        fileName={`waire_${waire?.code ? waire.code : ''}.pdf`}
        className={classes.pdfLink}
      >
        {() => <FileDownload className="mb-3" />}
      </PDFDownloadLink>
    );
  };

  return (
    waire && (
      <div style={{ width: 'fit-content' }}>
        <div style={{ textAlign: 'right' }} className="mx-2">
          {generatePDFDownloadLink()}
        </div>

        <div className={classes.boxesWrapper}>
          <Box className={[classes.boxWrapper, 'mx-2'].join(' ')}>
            <Box className={classes.box}>
              <Typography className={classes.pdfHeader}>
                <strong>WAIRE {waire?.code ? waire.code : ''}</strong>
              </Typography>

              <Typography className={classes.waireType}>{waire.type}</Typography>

              <Box>
                <Typography className={classes.creator}>{waire?.creator}</Typography>
                <Typography className={classes.listText}>{formatDate(waire?.createdAt, user?.timezone)}</Typography>
                <Typography className={classes.listText}>VIN: {waire?.vin || '-'}</Typography>
                <Typography className={classes.listText}>
                  Vehicle Class: {waire?.gvwr || waire?.vehicleType || '-'}
                </Typography>
                <Typography className={classes.listText}>
                  Fuel Type: {FUEL_TYPES[waire?.fuelTypePrimary] || waire?.fuelTypePrimary || '-'}
                </Typography>
                {waire?.logistic && (
                  <Typography className={classes.listText}>3PL Customer: {waire?.logistic}</Typography>
                )}
                {waire?.image && (
                  <img
                    src={waire.image}
                    style={{
                      width: '100%',
                      height: 360,
                      objectFit: 'fill',
                      border: '1px solid gray',
                      marginTop: 10,
                    }}
                  />
                )}
              </Box>
            </Box>
          </Box>
        </div>
      </div>
    )
  );
};

export default Waire;
