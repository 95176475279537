import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import moment from 'moment-timezone';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Container, Row } from 'reactstrap';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { post } from '../../utils/axios';

const useStyles = makeStyles((theme) => ({
  container: {
    // marginTop: theme.spacing(16),
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  forgotPassword: {
    marginTop: theme.spacing(2),
  },
}));

const Register = () => {
  const classes = useStyles();
  const { handleSubmit, control } = useForm();

  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleRegister = async (fields) => {
    setIsSubmitting(true);
    setApiError(null);

    const { email, password, firstName, lastName, phoneNumber, company, companyAddress } = fields;

    try {
      await post('/company/register', {
        email,
        password,
        firstName,
        lastName,
        company,
        address: companyAddress,
        phone: phoneNumber,
        timezone: moment.tz.guess(),
      });

      toast.success(
        `User account created !
         Please verify your email address in order to log in.`,
        {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        },
      );
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setIsSubmitting(false);
  };

  return (
    <Container className={classes.container}>
      <Row>
        <Col md={{ size: 8, offset: 0 }}>
          <Row style={{ marginBottom: '60px' }}>
            <Typography variant="h4" style={{ fontWeight: 'bold', fontSize: '20px' }}>
              Register
            </Typography>
          </Row>
          <Row>
            <Col lg={{ size: 8, offset: 0 }}>
              <FormComponent className={classes.form} onSubmit={handleSubmit(handleRegister)} error={apiError?.message}>
                <Controller
                  name="email"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      autoFocus
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      fullWidth
                      id="email"
                      label="Email Address"
                      name="email"
                      autoComplete="email"
                    />
                  )}
                  rules={{
                    required: 'Email required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                      message: 'Enter a valid e-mail address',
                    },
                  }}
                />
                <Controller
                  name="password"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Password"
                      id="password"
                      autoComplete="current-password"
                      type={showPassword ? 'text' : 'password'}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )}
                  rules={{ required: 'Password required' }}
                />
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="firstName"
                      label="First Name"
                      id="firstName"
                      autoComplete="First name"
                    />
                  )}
                  rules={{ required: 'First Name required' }}
                />
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="lastName"
                      label="Last Name"
                      id="lastName"
                      autoComplete="Last name"
                    />
                  )}
                  rules={{ required: 'Last Name required' }}
                />
                <Controller
                  name="company"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="company"
                      label="Company"
                      id="company"
                      autoComplete="Company"
                    />
                  )}
                  rules={{
                    required: 'Company required',
                  }}
                />
                <Controller
                  name="companyAddress"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="companyAddress"
                      label="Company Address"
                      id="companyAddress"
                      autoComplete="Address"
                    />
                  )}
                  rules={{ required: 'Address required' }}
                />
                <Controller
                  name="phoneNumber"
                  control={control}
                  defaultValue=""
                  render={({ field: { onChange, value }, fieldState: { error } }) => (
                    <TextField
                      type="tel"
                      onChange={onChange}
                      error={!!error}
                      helperText={error ? error.message : null}
                      value={value}
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="phoneNumber"
                      label="Phone Number"
                      id="phoneNumber"
                      autoComplete="Phone number"
                    />
                  )}
                  rules={{
                    required: 'Phone number required',
                    pattern: {
                      value: /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/i,
                      message: 'Enter a valid phone number. Example: 212-456-7890',
                    },
                  }}
                />

                <Loader isLoading={isSubmitting} small>
                  <Button type="submit" fullWidth variant="contained" color="primary" className="mt-2">
                    CREATE ACCOUNT
                  </Button>
                </Loader>
              </FormComponent>
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default Register;
