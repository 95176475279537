import MomentUtils from '@date-io/moment';
import { makeStyles, MuiThemeProvider } from '@material-ui/core/styles';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ConfigProvider } from 'antd';
import React from 'react';
import { QueryClientProvider } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ThemeProvider } from 'styled-components';
import { queryClient } from './api/queryClient';
import './App.scss';
import { AuthProvider } from './auth';
import AppLayout from './layout/appLayout';
import { GlobalStyles } from './styles/global';
import { BabacoTheme, MuiTheme } from './styles/theme';

const useStyles = makeStyles((theme) => ({
  root: {},
  appBar: {
    backgroundColor: '#333333',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  main: {
    paddingTop: theme.spacing(8),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

function App() {
  const classes = useStyles();
  toast.configure();

  return (
    <div className={`${classes.root} app-wrapper`}>
      <QueryClientProvider client={queryClient}>
        <ConfigProvider
          theme={{
            components: {
              Table: {
                colorBgContainer: '#ffffff',
                borderColor: '#ffffff',
                headerBg: '#ffffff',
                rowExpandedBg: '#ffffff',
              },
            },
          }}
        >
          <MuiThemeProvider theme={MuiTheme}>
            <ThemeProvider theme={BabacoTheme}>
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <GlobalStyles />
                <AuthProvider>
                  <BrowserRouter>
                    <AppLayout />
                  </BrowserRouter>
                </AuthProvider>
              </MuiPickersUtilsProvider>
            </ThemeProvider>
          </MuiThemeProvider>
        </ConfigProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
