import styled from 'styled-components';

export const FormWrapper = styled.div<{ hasBorder: boolean; hasPadding: boolean }>`
  padding: ${({ theme, hasPadding }) => (hasPadding ? theme.spacing.md : 0)};
  border: ${({ hasBorder }) => (hasBorder ? '1px solid #cccccc' : 'none')};

  .MuiFormControl-root:first-of-type {
    margin-top: 0;
  }
`;

export const ErrorWrapper = styled.div`
  color: #f44336;
  margin-bottom: ${({ theme }) => theme.spacing.md};
`;
