import React from 'react';

export const getDynamicFields = (form = []) => {
  const dynamicFields = [];

  form?.forEach((el: any) => {
    if (el.type === 'object') {
      el.children?.forEach((childEl: any) => {
        if (childEl.type !== 'images') {
          dynamicFields.push({
            title: `${el.name}: ${childEl.name}`,
            field: `${el.name}.${childEl.name}`,
            default: childEl.default,
            render: (rowData: any) => {
              return (
                (rowData?.outbound?.values?.[el.name] && rowData?.outbound?.values?.[el.name]?.[childEl.name]) || '-'
              );
            },
          });
        } else {
          dynamicFields.push({
            title: `${el.name}: ${childEl.name}`,
            field: `${el.name}.${childEl.name}`,
            default: childEl.default,
            render: (rowData: any) => {
              const outboundLength = rowData?.outbound?.values?.[el.name]?.[childEl.name]?.length || 0;
              const inboundLength = rowData?.inbound?.values?.[el.name]?.[childEl.name]?.length || 0;
              const total = outboundLength + inboundLength;

              if (rowData.type !== 'inbound_only' && total)
                return (
                  <div>
                    {total} Photo{total > 1 && 's'}
                  </div>
                );

              return '-';
            },
          });
        }
      });
    } else if (el.type === 'images') {
      dynamicFields.push({
        title: el.name,
        field: el.name,
        default: el.default,
        render: (rowData: any) => {
          const outboundLength = rowData?.outbound?.values?.[el.name]?.length || 0;
          const inboundLength = rowData?.inbound?.values?.[el.name]?.length || 0;
          const total = outboundLength + inboundLength;

          if (rowData.type !== 'inbound_only' && total)
            return (
              <div>
                {total} Photo{total > 1 && 's'}
              </div>
            );

          return '-';
        },
      });
    } else if (el.type !== 'object') {
      dynamicFields.push({
        title: el.name,
        field: el.name,
        default: el.default,
        render: (rowData: any) => {
          return rowData?.outbound?.values?.[el.name] || '-';
        },
      });
    }
  });

  return dynamicFields;
};
