import {
  Button,
  FormControl,
  makeStyles,
  Select,
  TextField,
  Typography,
  InputAdornment,
  IconButton,
} from '@material-ui/core';
import { VisibilityOff, Visibility } from '@material-ui/icons';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';
import moment from 'moment';

import FormComponent from '../../components/FormComponent';
import { AuthContext } from '../../auth';
import { BabacoTheme } from '../../styles/theme';
import Loader from '../../components/Loader';
import { patch } from '../../utils/axios';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  rightCol: {
    paddingLeft: `${theme.spacing(6)}px`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(3)}px`,
    },
  },
  formLabel: {},
  containerSpecific: {
    padding: BabacoTheme.spacing.sm,
  },
}));

const PageAccount = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(AuthContext);

  const { handleSubmit, control, setValue } = useForm();

  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleEditAccount = async (fields) => {
    setApiError(null);
    setLoading(true);

    const { email, password, firstName, lastName, phoneNumber, company, companyAddress, timezone } = fields;

    try {
      const { data } = await patch(`/company/account`, {
        email,
        password,
        firstName,
        lastName,
        phone: phoneNumber,
        address: companyAddress,
        company,
        timezone,
      });

      setUser({
        ...user,
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        address: data.address,
        company: data.name,
        phone: data.phone,
        timezone: data.timezone,
      });

      toast.success('Changes saved', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setApiError(error?.response?.data?.message);

      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false);
  };

  return (
    <Loader isLoading={loading}>
      <Row>
        <Col md={6} lg={5} className="mb-4">
          <FormComponent
            className={classes.form}
            hasBorder={false}
            hasPadding={false}
            onSubmit={handleSubmit(handleEditAccount)}
            error={apiError?.message}
          >
            <Typography variant="h5" className="mb-5">
              <b>My Account</b>
            </Typography>

            <Controller
              name="email"
              control={control}
              defaultValue={user.email}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="email"
                  placeholder="Email Address"
                  name="email"
                  autoComplete="email"
                />
              )}
              rules={{
                required: 'Email required',
                pattern: {
                  value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                  message: 'Enter a valid e-mail address',
                },
              }}
            />
            <Controller
              name="password"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  name="password"
                  placeholder="Password"
                  id="password"
                  autoComplete="current-password"
                  type={showPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              defaultValue={user.firstName}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="firstName"
                  placeholder="First Name"
                  id="firstName"
                  autoComplete="First name"
                />
              )}
              rules={{ required: 'First Name required' }}
            />
            <Controller
              name="lastName"
              control={control}
              defaultValue={user.lastName}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="lastName"
                  placeholder="Last Name"
                  id="lastName"
                  autoComplete="Last name"
                />
              )}
              rules={{ required: 'Last Name required' }}
            />
            <Controller
              name="company"
              control={control}
              defaultValue={user.company}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="company"
                  placeholder="Company"
                  id="company"
                  autoComplete="Company"
                />
              )}
              rules={{ required: 'Company required' }}
            />
            <Controller
              name="companyAddress"
              control={control}
              defaultValue={user.address}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="companyAddress"
                  placeholder="Company Address"
                  id="companyAddress"
                  autoComplete="Address"
                />
              )}
              rules={{ required: 'Address required' }}
            />
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue={user.phone}
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="phoneNumber"
                  placeholder="Phone Number"
                  id="phoneNumber"
                  autoComplete="Phone number"
                />
              )}
              rules={{ required: 'Phone number required' }}
            />
            <Controller
              name="timezone"
              control={control}
              defaultValue={user.timezone || 'Etc/Greenwich'}
              render={({ field: { onChange, value } }) => (
                <FormControl variant="outlined" component="fieldset" className="mt-3" margin="normal">
                  <Select native value={value} onChange={onChange}>
                    {moment.tz.names().map((option, i) => (
                      <option key={i} value={option}>
                        {option}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              )}
            />

            <Button type="submit" fullWidth variant="contained" color="primary" className="mt-3">
              UPDATE
            </Button>
          </FormComponent>
        </Col>
      </Row>
    </Loader>
  );
};

export default PageAccount;
