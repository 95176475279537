import 'bootstrap/dist/css/bootstrap.min.css';
import { createGlobalStyle } from 'styled-components';
import { fontFaces } from './font-faces';

export const GlobalStyles = createGlobalStyle`
  ${fontFaces};

  legend {
    float:none;
  }
  html body {
    font-family: 'Roboto';
  }

  html,body,#root {
    height:100%;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  .MuiCheckbox-root {
    &.Mui-checked {
      .MuiIconButton-label {
        &:before {
          content: '';
          display: block;
          height: 18px;
          width: 18px;
          border: 2px solid;
          background-color: #ffe202;
          position: absolute;
        }
      }
    }

    .MuiIconButton-label {
      position: right;
      &:after {
        content: '';
        display: block;
        height: 18px;
        width: 18px;
        border: 2px solid;
        color: #707070;
        position: absolute;
      }
    }

    &.Mui-disabled {
      .MuiIconButton-label {
          &:after {
            color: #ccc;
          }
        }
      }
  }
`;
