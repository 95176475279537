export const dropDownOptions = [
  {
    label: 'Text',
    value: 'string',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Dropdown',
    value: 'list',
  },
  {
    label: 'Photos',
    value: 'images',
  },
  {
    label: 'Location',
    value: 'location',
  },
  {
    label: 'Field with Multiple Sub-Items of Different Type',
    value: 'object',
  },
];

export const dropDownOptionsForSubField = [
  {
    label: 'Text',
    value: 'string',
  },
  {
    label: 'Number',
    value: 'number',
  },
  {
    label: 'Dropdown',
    value: 'list',
  },
  {
    label: 'Photos',
    value: 'images',
  },
  {
    label: 'Location',
    value: 'location',
  },
];

export const TYPE_MAP = {
  string: 'Text',
  number: 'Number',
  list: 'List',
  images: 'Images',
  object: 'Sub-field',
  'object-optional': 'Optional Sub-field',
  location: 'Location',
};
