import Checkbox from '@material-ui/core/Checkbox';
import React, { useContext, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, InputAdornment, OutlinedInput, Button } from '@material-ui/core';
import { fetchCustomerLocations } from '../../api/customer';
import { queryClient } from '../../api/queryClient';
import Table from '../../components/Table';
import { PAGE_LIMIT, QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { patch } from '../../utils/axios';
import { toastError } from '../../utils/toastError';
import { formatDate } from '../../utils/helpers';
import { AuthContext } from '../../auth';

type CustomerLocationData = {
  locations: any[];
  total: number;
};

const LocationFee = ({ locationFee = 0, locationID }) => {
  const [fee, setFee] = useState<string>(String(locationFee));
  const [loading, setLoading] = useState(false);

  const updateFee = async () => {
    if (Number.isNaN(Number.parseInt(fee))) {
      toast.error('Please enter a valid number', TOAST_CONFIG);
      return;
    }

    if (Number.parseInt(fee) <= 0) {
      toast.error('Please enter a positive number', TOAST_CONFIG);
      return;
    }

    setLoading(true);

    try {
      await patch(`/customers/location/${locationID}`, { locationFee: Number.parseInt(fee) });
      toast.success('Location fee updated.', TOAST_CONFIG);
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }

    setLoading(false);
  };

  return (
    <Box style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
      <OutlinedInput
        onChange={(e) => setFee(e.target.value)}
        value={fee}
        required
        name="locationFee"
        id="locationFee"
        startAdornment={<InputAdornment position="start">$</InputAdornment>}
        style={{ maxWidth: 120, height: 40 }}
      />
      <Button
        variant="contained"
        color="primary"
        style={{ maxWidth: 100, height: 40, fontWeight: 'normal' }}
        onClick={updateFee}
        disabled={loading}
      >
        UPDATE
      </Button>
    </Box>
  );
};

const Permissions = () => {
  const params: any = useParams();

  const { user } = useContext(AuthContext);

  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, unknown, CustomerLocationData>({
    queryKey: [QUERY_KEYS.customer_locations, params.id],
    queryFn: ({ pageParam = 1 }) => fetchCustomerLocations(pageParam, params.id),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
  });

  const total = data?.pages?.[0]?.total || 0;
  const hasMoreData = data?.pages?.length * PAGE_LIMIT < total;
  const locations = data?.pages?.map((item) => item.locations);

  const updateLocation = (locationID, updates) => {
    return async (e) => {
      e.stopPropagation();

      try {
        await patch(`/customers/location/${locationID}`, updates);
        queryClient.resetQueries({ queryKey: [QUERY_KEYS.customer_locations] });
      } catch (error) {
        toast.error(error?.response?.data?.message, TOAST_CONFIG);
      }
    };
  };

  const columns = [
    { title: 'Location', field: 'name', default: true, minWidth: '200px' },
    {
      title: 'BOL',
      field: 'bol',
      default: true,
      render: (data) => (
        <Checkbox checked={data.features.bol} onClick={updateLocation(data._id, { bol: !data.features.bol })} />
      ),
    },
    {
      title: 'Inspection',
      field: 'inspection',
      default: true,
      render: (data) => (
        <Checkbox
          checked={data.features.inspection}
          onClick={updateLocation(data._id, { inspection: !data.features.inspection })}
        />
      ),
    },
    {
      title: 'WAIRE',
      field: 'waire',
      default: true,
      render: (data) => (
        <Checkbox checked={data.features.waire} onClick={updateLocation(data._id, { waire: !data.features.waire })} />
      ),
    },
    {
      title: '3PL',
      field: 'logistic',
      default: true,
      render: (data) => (
        <Checkbox
          disabled={!data.features.waire}
          checked={data.features.logistic}
          onClick={updateLocation(data._id, {
            logistic: !data.features.logistic,
          })}
        />
      ),
    },
    {
      title: 'Location Fee',
      field: 'locationFee',
      minWidth: '250px',
      default: true,
      render: (data) => (
        <LocationFee locationFee={data?.locationFee ? Number(data.locationFee) : 0} locationID={data._id} />
      ),
    },
    { title: 'Email', field: 'email', default: true, minWidth: '300px' },
    { title: 'Address', field: 'address', default: true },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    {
      title: 'Last Signed In',
      field: 'signIn',
      default: true,
      render: (data) => (data?.signIn ? formatDate(data?.signIn, user?.timezone) : '-'),
    },
  ];

  return (
    <Table
      pages={locations || []}
      columns={columns}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      hasMoreData={hasMoreData}
      autoTableCell
      sorting={false}
      headerRefresh={false}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default Permissions;
