import React from 'react';
import { ColSelectWrapper, ColumnCard, CTitle } from './TableColumnSelect.style';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Checkbox from '@material-ui/core/Checkbox';
import { ReorderHorizontal } from 'mdi-material-ui';

interface ColumnProp {
  title: string;
  field: string;
  active: boolean;
  tableData?: any;
  mandatory?: boolean;
}
interface ComponentProps {
  columns: ColumnProp[];
  setColumns: (cols: ColumnProp[]) => void;
}

export default function TableColumnSelect({ columns, setColumns }: ComponentProps) {
  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((el: ColumnProp, i) => ({ ...el, tableData: { ...el.tableData, columnOrder: i } }));
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = reorder(columns, result.source.index, result.destination.index);

    setColumns(items as ColumnProp[]);
  };

  const handleChangeCheckbox = (field: string) => {
    const newCols = columns.map((col) => (col.field === field ? { ...col, active: !col.active } : col));
    setColumns(newCols);
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable">
        {(provided, snapshot) => (
          <ColSelectWrapper {...provided.droppableProps} ref={provided.innerRef}>
            {columns.map((item, index) => (
              <Draggable key={item.field} draggableId={item.field} index={index}>
                {(provided, snapshot) => (
                  <ColumnCard
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      padding: 0,
                    }}
                  >
                    <ReorderHorizontal style={{ color: '#CCCCCC' }} />
                    <Checkbox
                      checked={item.active}
                      onChange={() => handleChangeCheckbox(item.field)}
                      disabled={item.mandatory}
                    />
                    <CTitle>
                      {item.title}
                      {item.mandatory && '*'}
                    </CTitle>
                  </ColumnCard>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </ColSelectWrapper>
        )}
      </Droppable>
    </DragDropContext>
  );
}
