import React from 'react';
import { ErrorWrapper, FormWrapper } from './FormComponent.style';

interface ComponentProps {
  hasBorder?: boolean;
  hasPadding?: boolean;
  onSubmit: (any) => void;
  className?: string;
  error?: string;
}

const FormComponent: React.FC<ComponentProps> = (props) => {
  const { children, hasBorder = true, hasPadding = true, onSubmit, error, ...rest } = props;

  return (
    <FormWrapper hasBorder={hasBorder} hasPadding={hasPadding}>
      {error && <ErrorWrapper>{error}</ErrorWrapper>}

      <form onSubmit={onSubmit} {...rest}>
        {children}
      </form>
    </FormWrapper>
  );
};

export default FormComponent;
