import React from 'react';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { Calendar } from 'mdi-material-ui';

const useStyles = makeStyles(() => ({
  timeframeBtn: {
    fontWeight: 400,
    padding: 13,
    borderRadius: 5,
  },
  timeframeMenu: {
    '& .MuiPaper-root': {
      transform: 'translateY(46px) !important',
      width: 240,
      background: '#FFFFFF',
      boxShadow: '0px 3px 6px #00000029',
      border: '0.5px solid #ADADAD',
    },
  },
}));

interface Props {
  year: string;
  menu: null | HTMLElement;
  menuOpen: (event: React.MouseEvent<HTMLButtonElement>) => void;
  menuClose: () => void;
  selectYear: (year: string) => void;
}

const WaireMenu: React.FC<Props> = ({ menu, year, menuClose, menuOpen, selectYear }) => {
  const classes = useStyles();
  const open = Boolean(menu);

  return (
    <>
      <Button
        id="timeframe-button"
        aria-controls={open ? 'timeframe-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        color="primary"
        startIcon={<Calendar />}
        className={classes.timeframeBtn}
        onClick={menuOpen}
      >
        {year || 'Choose Timeframe'}
      </Button>
      <Menu
        id="timeframe-menu"
        open={open}
        anchorEl={menu}
        onClose={menuClose}
        MenuListProps={{
          'aria-labelledby': 'timeframe-button',
        }}
        className={classes.timeframeMenu}
        elevation={0}
      >
        <MenuItem onClick={() => selectYear('')}>All Years</MenuItem>
        <MenuItem selected={year === '2022'} onClick={() => selectYear('2022')}>
          2022
        </MenuItem>
        <MenuItem selected={year === '2023'} onClick={() => selectYear('2023')}>
          2023
        </MenuItem>
      </Menu>
    </>
  );
};

export default WaireMenu;
