import React from 'react';
import { createStyles, Switch, SwitchClassKey, SwitchProps, Theme, withStyles } from '@material-ui/core';

interface Styles extends Partial<Record<SwitchClassKey, string>> {
  focusVisible?: string;
}

interface Props extends SwitchProps {
  classes: Styles;
}

const CustomSwitch = withStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 50,
      height: 24,
      padding: 0,
      margin: theme.spacing(1),
      overflow: 'visible',
    },
    switchBase: {
      padding: 1,
      '&$checked': {
        transform: 'translateX(24px)',
        color: theme.palette.common.white,
        '& + $track': {
          border: '1px solid #CCCCCC',
          backgroundColor: 'transparent',
          opacity: 1,
        },
        '& $thumb': {
          backgroundColor: '#ffe202',
        },
      },
      '&$focusVisible $thumb': {
        color: 'transparent',
        border: '6px solid #fff',
      },
    },
    thumb: {
      width: 24,
      height: 24,
      position: 'relative',
      top: '-1px',
      border: '1px solid #CCCCCC',
      backgroundColor: 'transparent',
    },
    track: {
      borderRadius: 26 / 2,
      border: `1px solid ${theme.palette.grey[400]}`,
      backgroundColor: theme.palette.grey[50],
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
    focusVisible: {},
  }),
)(({ classes, ...props }: Props) => {
  return (
    <Switch
      color={'primary'}
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});

export default CustomSwitch;
