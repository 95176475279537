import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { signInWithEmailAndPassword } from 'firebase/auth';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Link, RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchUserDetails } from '../../api/user';
import { AuthContext } from '../../auth';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { TOAST_CONFIG } from '../../constants';
import { auth } from '../../firebase';
import { getRedirectUrlBasedOnFeaturesAvailable } from '../../utils/helpers';

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  form: {
    width: '70%',
    margin: '0 auto',
  },
  forgotPassword: {
    marginTop: 16,
    textAlign: 'left',
  },
});

const Login = ({ history }: RouteComponentProps) => {
  const classes = useStyles();
  const { setUser } = useContext(AuthContext);
  const { handleSubmit, control } = useForm();

  const [apiError, setApiError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const handleLogin = async (fields) => {
    setIsSubmitting(true);
    setApiError(null);

    const { email, password } = fields;

    try {
      const res = await signInWithEmailAndPassword(auth, email, password);
      const data = await fetchUserDetails();
      setUser(data);

      if (res?.user?.emailVerified) {
        if (data?.superAdmin) history.push('/customers');
        else history.push(getRedirectUrlBasedOnFeaturesAvailable(data?.features));
      } else if (!res?.user?.emailVerified) {
        await auth.signOut();

        toast.info(`Please verify your email address in order to log in.`, TOAST_CONFIG);
      }
    } catch (error) {
      await auth.signOut();
      toast.info(`Please contact your admin for privileges`, TOAST_CONFIG);

      setApiError(error);
      setIsSubmitting(false);
    }
  };

  return (
    <div className={classes.container}>
      <FormComponent
        className={classes.form}
        onSubmit={handleSubmit(handleLogin)}
        error={apiError?.message}
        hasBorder={false}
      >
        <Controller
          name="email"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              autoFocus
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              value={value}
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
            />
          )}
          rules={{
            required: 'Email required',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: 'Enter a valid e-mail address',
            },
          }}
        />
        <Controller
          name="password"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value }, fieldState: { error } }) => (
            <TextField
              onChange={onChange}
              error={!!error}
              helperText={error ? error.message : null}
              value={value}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              id="password"
              autoComplete="current-password"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={() => setShowPassword(!showPassword)}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
          rules={{ required: 'Email required' }}
        />

        <Loader isLoading={isSubmitting} small>
          <Button type="submit" fullWidth variant="contained" color="primary" className="mt-2">
            SIGN IN
          </Button>
        </Loader>

        <p className={classes.forgotPassword}>
          Forgot your password?{' '}
          <Link to="/reset-password" style={{ color: '#0d6efd' }}>
            Reset it here
          </Link>
        </p>
      </FormComponent>
    </div>
  );
};

export default Login;
