import React from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/styles';
import { ContentCopy } from 'mdi-material-ui';

const useStyles = makeStyles(() => ({
  button: {
    fontWeight: 600,
    width: '440px',
    textTransform: 'none',
    marginTop: '10px',
    '&:disabled': {
      background: '#F7F0B7',
    },
  },
}));

interface Props {
  disabled: boolean;
  children: React.ReactNode;
  copyText: (text: any) => void;
}

const WaireButton: React.FC<Props> = ({ disabled, children, copyText }) => {
  const classes = useStyles();

  const disabledStyle = {
    background: disabled ? '#F7F0B7' : '#ffe202',
    color: disabled ? 'rgba(0, 0, 0, 0.26)' : 'black',
  };

  return (
    <Button
      variant="contained"
      color="primary"
      className={classes.button}
      startIcon={<ContentCopy />}
      style={disabledStyle}
      disableElevation={disabled}
      onClick={copyText}
    >
      {children}
    </Button>
  );
};

export default WaireButton;
