import React from 'react';
import { Document, Image, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import { formatDate } from '../../utils/helpers';

const styles = StyleSheet.create({
  page: {
    width: '100%',
    padding: 15,
    fontSize: 10,
    fontFamily: 'Roboto',
    display: 'flex',
    flexDirection: 'row',
  },
  container: {
    borderRight: '1px solid #ccc',
    paddingRight: 10,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  details: {
    width: '50%',
    position: 'relative',
    paddingBottom: 80,
  },
  code: { fontSize: 18, marginBottom: 6, fontFamily: 'RobotoBold' },
  type: { fontSize: 12, fontFamily: 'RobotoBold' },
  textContainerM10: { marginTop: 10, display: 'flex', flexDirection: 'row', alignItems: 'center' },
  textContainerM4: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  textContainerM16: {
    marginTop: 16,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  bold: { fontFamily: 'RobotoBold' },
  tagValues: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginBottom: 4 },
  issuesContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: 340,
  },
  issues: { display: 'flex', flexDirection: 'row', alignItems: 'center', marginVertical: 4 },
  issue: {
    height: 16,
    width: 16,
    border: '1px solid black',
    marginRight: 8,
  },
  signatureContainer: { border: '1px solid #ccc', marginTop: 8, width: 240 },
  signature: {
    objectFit: 'cover',
    height: 100,
  },
  evidenceContainer: { paddingLeft: 10, width: '50%', alignSelf: 'flex-start' },
  evidenceHeading: { fontFamily: 'RobotoBold', borderBottom: '1px solid #ccc', paddingBottom: 10 },
  issueName: {
    borderBottom: '1px solid #ccc',
    width: '95%',
    paddingVertical: 10,
  },
});

const InspectionPDF = ({ containerInspection, timezone, moreThanOnePageOfImages }) => {
  const renderIssues = () => {
    return (
      <View style={styles.issuesContainer}>
        <View>
          {containerInspection.issues.slice(0, 9).map((issue, idx) => {
            return (
              <View key={idx} style={styles.issues}>
                <View
                  style={{
                    ...styles.issue,
                    backgroundColor: issue.issue ? 'red' : 'none',
                  }}
                />
                <Text>
                  [{idx + 1}] {issue.name}
                </Text>
              </View>
            );
          })}
        </View>

        {containerInspection.type === '17' ? (
          <View>
            {containerInspection.issues.slice(9).map((issue, idx) => {
              return (
                <View key={idx + 9} style={styles.issues}>
                  <View
                    style={{
                      ...styles.issue,
                      backgroundColor: issue.issue ? 'red' : 'none',
                    }}
                  />
                  <Text>
                    [{idx + 9}] {issue.name}
                  </Text>
                </View>
              );
            })}
          </View>
        ) : null}
      </View>
    );
  };

  return (
    <Document>
      <Page size="LETTER" style={{ padding: 10 }}>
        <View style={styles.page}>
          <View style={styles.container}>
            <View style={styles.details}>
              <Text style={styles.code}>{containerInspection.code}</Text>

              <Text style={styles.type}>
                {`${containerInspection.type === '17' ? '1' : ''}7-Pt CTPAT Container Inspection`}
              </Text>

              <View style={styles.textContainerM10}>
                <Text>Location:</Text>
                <Text style={{ flexGrow: 1 }}>{containerInspection?.location}</Text>
              </View>

              <View style={styles.textContainerM4}>
                <Text>Date:</Text>
                <Text style={{ flexGrow: 1 }}>{formatDate(containerInspection?.createdAt, timezone, true)}</Text>
              </View>

              <View style={styles.textContainerM4}>
                <Text>Seal Number (on arrival):</Text>
                <Text style={{ flexGrow: 1 }}>{containerInspection?.seal?.arrival || ''}</Text>
              </View>

              <View style={{ ...styles.textContainerM4, marginBottom: 16 }}>
                <Text>Seal Number (on departure):</Text>
                <Text style={{ flexGrow: 1 }}>{containerInspection?.seal?.departure || ''}</Text>
              </View>

              {containerInspection?.tags ? (
                <View>
                  <Text style={{ marginBottom: 8, ...styles.bold }}>Custom Tags</Text>
                  {Object.keys(containerInspection.tags).map((tag) => (
                    <View key={tag} style={styles.tagValues}>
                      <Text>{tag}: </Text>
                      <Text>{containerInspection.tags[tag]}</Text>
                    </View>
                  ))}
                </View>
              ) : null}

              <View>
                <Text style={{ marginTop: 16, marginBottom: 8, ...styles.bold }}>Container Areas with Issues</Text>

                {renderIssues()}
              </View>

              <View style={{ marginTop: 16 }}>
                <Text style={styles.bold}>Agricultural Inspection for Pest Contamination</Text>

                <View style={styles.textContainerM10}>
                  <View
                    style={{
                      ...styles.issue,
                      width: 18,
                      backgroundColor: containerInspection?.extraInspection?.agriculture ? '#ffe202' : 'none',
                    }}
                  />
                  <Text style={{ fontSize: 8 }}>Yes - I made an Agricultural Inspection for Pest Contamination.</Text>
                </View>
              </View>

              <View style={{ marginTop: 16 }}>
                <Text style={styles.bold}>Hardware Inspection</Text>

                <View style={styles.textContainerM10}>
                  <View
                    style={{
                      ...styles.issue,
                      width: 18,
                      backgroundColor: containerInspection?.extraInspection?.hardware ? '#ffe202' : 'none',
                    }}
                  />
                  <View style={{ display: 'flex' }}>
                    <Text style={{ fontSize: 8 }}>Yes - I made a Hardware Inspection</Text>
                    <Text style={{ fontSize: 8 }}>(Doors, Handles, Rods, Hasps, Rivets, Brackets, etc.)</Text>
                  </View>
                </View>
              </View>

              <View>
                <View style={styles.textContainerM16}>
                  <Text>Inspector: </Text>
                  <Text>{containerInspection?.inspector?.name}</Text>
                </View>
                <View style={styles.signatureContainer}>
                  <Image style={styles.signature} src={containerInspection?.inspector?.signature} />
                </View>
              </View>

              <Image
                src="/assets/ctpat-logo.png"
                style={{ width: 140, height: 32, objectFit: 'fill', position: 'absolute', bottom: 0 }}
              />
            </View>
          </View>

          <View style={styles.evidenceContainer}>
            <Text style={styles.evidenceHeading}>Photo Evidence of Issues</Text>

            <View style={{ display: 'flex' }}>
              {containerInspection.issues.map((issue, idx) => {
                if (issue.issue) {
                  return (
                    <View key={idx} style={styles.issueName}>
                      <Text>
                        [{idx + 1}] {issue.name}
                      </Text>

                      <View
                        style={{
                          display: 'flex',
                          flexWrap: moreThanOnePageOfImages ? 'nowrap' : 'wrap',
                          flexDirection: 'row',
                          marginVertical: 8,
                          width: '90%',
                        }}
                      >
                        {issue?.photos?.map((photo: string, photoIdx: number) => (
                          <Image
                            key={photoIdx}
                            src={photo}
                            style={{
                              margin: 4,
                              width: moreThanOnePageOfImages ? '20%' : '30%',
                              height: 100,
                              border: '1px solid gray',
                              objectFit: 'contain',
                              backgroundColor: 'black',
                            }}
                          />
                        ))}
                      </View>

                      {issue.comment ? <Text>{issue.comment}</Text> : null}
                    </View>
                  );
                }

                return <View key={idx} />;
              })}
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default InspectionPDF;
