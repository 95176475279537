import React, { useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core';
import { useHistory, useLocation } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useInfiniteQuery, useQuery } from 'react-query';
import { formatDate } from '../../utils/helpers';
import Table from '../../components/Table';
import { AuthContext } from '../../auth';
import WaireSummary from '../../components/WaireSummary';
import WaireFilter from '../../components/WaireFilter';
import { WAIRE } from '../../types';
import { FUEL_TYPES } from '../../utils/constants';
import { fetchWaires } from '../../api/waire';
import { PAGE_LIMIT, QUERY_KEYS } from '../../constants';
import { queryClient } from '../../api/queryClient';
import { waireListKeys } from '../../utils/queryKeys';
import { toastError } from '../../utils/toastError';
import { get } from '../../utils/axios';
import 'react-tabs/style/react-tabs.css';
import { Logistic } from '../../components/WaireFilter/WaireFilter';

type WaireData = {
  waires: WAIRE[];
  total: number;
  trips: number;
  visits: number;
};

const useStyles = makeStyles((theme) => ({
  header: {
    marginBottom: 60,
  },
  heading: {
    display: 'block',
    whiteSpace: 'nowrap',
    fontSize: '22px',
    fontWeight: 'bold',
    marginBottom: '20px',
  },
  desc: {
    fontSize: '16px',
  },
  link: {
    color: '#5572EA',
    textDecoration: 'underline',
  },
  filterBtn: {
    borderRadius: '5px',
    fontWeight: 400,
    textTransform: 'capitalize',
  },
  tabs: {
    borderBottom: '2px solid black',
    paddingLeft: 0,
    alignItems: 'center',
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      alignItems: 'flex-end',
    },
  },
  filters: {
    display: 'inline',
    marginLeft: 16,
    marginTop: 6,
    wordWrap: 'break-word',
    [theme.breakpoints.down('md')]: {
      marginTop: 10,
    },
  },
}));

const Waires = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const history = useHistory();

  const { search } = useLocation();
  const params = Object.fromEntries(new URLSearchParams(search));

  const [tabIndex, setTabIndex] = useState(params.tab ? Number(params.tab) : 0);
  const [filters, setFilters] = useState<any>({});

  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    placeholderData: [],
    onError: toastError,
  });

  const { data: logistics = [] } = useQuery<Logistic[]>({
    queryKey: QUERY_KEYS.all_logistics_customers_filter,
    queryFn: async () => {
      const { data } = await get('/logistic/all');
      return data;
    },
    enabled: user?.features?.logistic,
    placeholderData: [],
  });

  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, unknown, WaireData>({
    queryKey: [QUERY_KEYS.waires, ...waireListKeys(filters, locations, logistics)],
    queryFn: ({ pageParam = 1 }) => fetchWaires(pageParam, filters, locations),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
  });

  const total = data?.pages?.[0]?.total || 0;
  const trips = data?.pages?.[0]?.trips || 0;
  const visits = data?.pages?.[0]?.visits || 0;
  const waires = data?.pages?.map((item) => item.waires);
  const appliedFilters = waireListKeys(filters, locations, logistics);

  const hasMoreData = data?.pages?.length * PAGE_LIMIT < total;

  const handleRefresh = async () => {
    setFilters({});
    queryClient.resetQueries({ queryKey: [QUERY_KEYS.waires] });
    localStorage.removeItem('waire-field-values');
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('waire-field-values');
    };
  }, []);

  const onTabSelect = (tab) => {
    setTabIndex(tab);
    history.push(`/waire?tab=${tab}`);
  };

  const columns = [
    {
      title: 'Trip',
      field: 'code',
      default: true,
      render: (data) => data?.code || '-',
      minWidth: 60,
    },
    {
      title: 'Date',
      field: 'createdAt',
      default: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    { title: 'Location', field: 'creator', default: true },
    { title: 'Direction', field: 'type', default: true },
    { title: 'VIN', field: 'vin', default: true, render: (data) => data?.vin || '-' },
    {
      title: 'Vehicle Class',
      field: 'gvwr',
      default: true,
      render: (data) => data?.gvwr || data?.vehicleType || '-',
    },
    {
      title: 'Fuel Type',
      field: 'fuelTypePrimary',
      default: true,
      render: (data) => {
        const fuelType = FUEL_TYPES[data?.fuelTypePrimary] || data?.fuelTypePrimary || '-';
        if (fuelType.length > 16) return `${fuelType.slice(0, 15)}...`;
        return fuelType;
      },
    },
    {
      title: 'Comments',
      field: 'image',
      default: true,
      render: (data) => (data.image ? 'Photo Added' : '-'),
    },
  ];

  if (user?.features?.['logistic']) {
    columns.push({
      title: 'Customer',
      field: 'logistic',
      default: true,
      render: (data) => data?.logistic || '-',
    });
  }

  const onRowClick = (_, rowData) => {
    history.push(`/waire/${rowData._id}`);
  };

  const headerText = (
    <Box className={classes.header}>
      <Typography className={classes.heading} variant="h1">
        WAIRE Compliance - Warehouse Actions and Investments to Reduce Emissions
      </Typography>
      <Typography className={classes.desc} variant="body1">
        Use the data on this page to complete the{' '}
        <Link
          href="http://www.aqmd.gov/home/rules-compliance/compliance/waire-program"
          className={classes.link}
          target="_blank"
        >
          South Coast AQMD WAIRE Program compliance and reporting requirements.
        </Link>
      </Typography>
    </Box>
  );

  return (
    <Box>
      {headerText}

      <Tabs selectedIndex={tabIndex} onSelect={onTabSelect}>
        <TabList className={classes.tabs}>
          <Tab>All Trips</Tab>
          <Tab>Compliance Data</Tab>
          {tabIndex === 0 && (
            <span className={classes.filters}>
              <b>Records:</b> {total} | <b>Trips:</b> {trips} | <b>Visits:</b> {visits} |<b> Filter:</b>{' '}
              <i>{appliedFilters?.length > 0 ? appliedFilters.join(' | ') : 'All'}</i>
            </span>
          )}
        </TabList>

        <TabPanel>
          <Table
            pages={waires || []}
            columns={columns}
            onRowClick={onRowClick}
            toolbarContent={[]}
            showToolbar={true}
            filterComponent={WaireFilter}
            isLoading={isLoading}
            hasMoreData={hasMoreData}
            autoTableCell
            sorting={false}
            headerText={null}
            headerRefresh={false}
            showShowAndHideColumns={false}
            waire={true}
            filters={filters}
            setFilters={setFilters}
            fetchNextPage={fetchNextPage}
            onRefresh={handleRefresh}
          />
        </TabPanel>
        <TabPanel>
          <WaireSummary />
        </TabPanel>
      </Tabs>
    </Box>
  );
};

export default Waires;
