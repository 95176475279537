import styled from 'styled-components';
import { BabacoTheme } from '../../styles/theme';

export const LoadingWrapper = styled.div`
  position: relative;
`;
export const ProgressWrapper = styled.div<{ wide: boolean }>`
  background-color: rgb(255 255 255 / 50%);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 ${({ wide }) => (wide ? '-15px' : '0')};
  color: ${BabacoTheme.colors.yellow};

  display: flex;
  justify-content: center;
  align-items: center;
`;
