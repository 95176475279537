import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiDialog-paper': {
      maxWidth: 800,
      padding: '40px 60px',
    },
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  desc: {
    fontSize: 12,
    marginTop: 20,
    lineHeight: 1.1,
  },
  imgContainer: {
    marginTop: 20,
  },
  img: {
    width: 400,
    objectFit: 'cover',
  },
  menuImg: {
    width: 400,
    objectFit: 'cover',
    marginTop: 20,
  },
  link: {
    color: '#5572EA',
    fontWeight: 'bold',
    display: 'block',
    marginTop: 10,
  },
  emission: {
    width: '100%',
    objectFit: 'cover',
    marginTop: 20,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

const VisitsDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.container} onClose={onClose}>
      <Typography className={classes.heading}>Instructions for Truck Visits (By Low-Emission Fuel)</Typography>
      <Typography className={classes.desc}>
        1. In the Data Summary tab of Babaco Fulfillment, go to the <b>2 - Truck Visits (By Low-Emission Fuel)</b>.
        Clicking on the button allows you to copy the number of visits, which you will then paste into the corresponding
        form fields in the WAIRE report filing. You will need to do this process of copying and pasting for each data
        point.
      </Typography>

      <Box className={classes.imgContainer}>
        <img src="/assets/waire-trips-step-2-header.png" alt="step 2 header" className={classes.img} />
        <img src="/assets/waire-visits-step-2-body.png" alt="step 2 body" className={classes.img} />
      </Box>

      <Typography className={classes.desc}>
        2. Log into your WAIRE Program Online Portal (POP) at:{' '}
        <Link href="https://xappp.aqmd.gov/isr" target="_blank" className={classes.link}>
          https://xappp.aqmd.gov/isr
        </Link>
      </Typography>

      <Typography className={classes.desc}>
        3. Go to <b>Section 3: WAIRE Menu</b>
      </Typography>

      <img src="/assets/waire-visits-step-3-menu.png" alt="step 3 menu" className={classes.menuImg} />

      <Typography className={classes.desc}>
        4. For Truck Visits, as opposed to the previous section, Truck Trips, you have to provide data for two distinct
        sections: ZE (Zero Emissions) and NZE (Near-Zero Emissions). For each of these two sections, you need to provide
        a number, the Number of Visits for each Equipment Class and Fuel Type combination, of which there are 10. These
        numbers are available in Babaco Fulfillment to copy and paste into WAIRE.
      </Typography>

      <img src="/assets/waire-visits-step-4-emission.png" alt="step 4 emission" className={classes.emission} />
    </Dialog>
  );
};

export default VisitsDialog;
