import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../constants';
import { get } from '../utils/axios';

export const useBolLocations = () => {
  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.bol_filter_locations,
    queryFn: async () => {
      const { data } = await get('/locations');
      return data;
    },
    placeholderData: { inbound: [], outbound: [] },
    select: (data) => {
      const inbound = data?.locations
        ?.filter((loc) => loc.closed > 0)
        ?.map((loc) => ({ location: loc.location, total: loc.closed, value: loc._id }));

      const outbound = data?.locations
        ?.filter((loc) => loc.total > 0)
        ?.map((loc) => ({ location: loc.location, total: loc.total, value: loc._id }));

      return { inbound, outbound };
    },
    staleTime: Infinity,
    refetchInterval: Infinity,
  });

  return { locations };
};
