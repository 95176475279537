import moment from 'moment-timezone';

export const formatDate = (data: any, timeZone = 'America/New_York', showTz = false) => {
  const parsed = showTz
    ? moment.tz(data, timeZone).format('ddd MMM D, YYYY h:mm a z')
    : moment.tz(data, timeZone).format('ddd MMM D, YYYY h:mm a');

  return parsed;
};

export const beautifyFieldName = (fieldName) => fieldName?.replace('_', ' ');

export const getRedirectUrlBasedOnFeaturesAvailable = (features: { bol?: boolean; waire?: boolean } = {}) => {
  let redirectUrl = '/location-list';
  if (features?.bol) redirectUrl = '/bol-list';
  else if (features?.waire) redirectUrl = '/waire';

  return redirectUrl;
};
