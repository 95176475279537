import Roboto from './fonts/Roboto-Regular.ttf';
import RobotoItalic from './fonts/Roboto-Italic.ttf';
import RobotoBold from './fonts/Roboto-Bold.ttf';

import { css } from 'styled-components';

export const fontFaces = css`
  @font-face {
    font-family: 'Roboto';
    src: url(${Roboto}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Italic';
    src: url(${RobotoItalic}) format('truetype');
  }

  @font-face {
    font-family: 'Roboto Bold';
    src: url(${RobotoBold}) format('truetype');
  }
`;
