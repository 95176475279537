import { makeStyles } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import { DownloadOutline, FilterVariant, HelpCircleOutline } from 'mdi-material-ui';
import moment from 'moment';
import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { generateReport } from '../../api/waire';
import { TOAST_CONFIG } from '../../constants';
import { toastError } from '../../utils/toastError';

const useStyles = makeStyles(() => ({
  dialogContainer: {
    '& .MuiDialog-paper': {
      maxWidth: 480,
      padding: '80px 40px 20px 40px',
      height: 520,
    },
  },
  download: {
    color: '#5572EA',
    cursor: 'pointer',
  },
  btn: {
    color: '#5572EA',
    background: 'transparent',
    border: 'none',
    marginRight: 2,
  },
  heading: {
    textAlign: 'center',
    fontWeight: 'bold',
    fontSize: 22,
  },
  desc: {
    fontSize: 16,
    maxWidth: '75%',
    margin: '20px auto',
    lineHeight: 1.1,
  },
  filter: {
    width: '60%',
    margin: '0 auto',
    boxShadow: '0 3px 6px 0px #00000029',
  },
}));

interface ComponentProps {
  filters: Record<string, any>;
  total: number;
  type: 'all' | 'compliance';
  setActiveToolbarTab?: (string) => void;
}

export default function WaireDownloadReport({ filters, total, setActiveToolbarTab, type }: ComponentProps) {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  const { mutate, isLoading } = useMutation(() => generateReport(filters), {
    onError: toastError,
    onSuccess: () => {
      toast.success('Report is being generated, it will be sent to your account email in few minutes.', TOAST_CONFIG);
    },
  });

  const onGenerateReport = () => {
    if (!total) {
      toast.error('No records exist for this timeframe.', TOAST_CONFIG);
      return;
    }

    if (type === 'compliance') {
      mutate();
      return;
    }

    const datesExists = filters.endDate && filters.startDate;

    if (!datesExists) {
      toast.error("Please select date range in 'Filter and Search' to generate report.", TOAST_CONFIG);
      return;
    }

    const diff = moment(filters.endDate).diff(moment(filters.startDate), 'days');

    if (diff > 365) {
      toast.error(
        `The timeframe for generating a report should be less than 1 year. Your current timeframe was ${diff} days.`,
        TOAST_CONFIG,
      );
      return;
    }

    if (diff < 0) {
      toast.error(`The selected timeframe is invalid. End Date cannot be less than Start Date.`, TOAST_CONFIG);
      return;
    }

    mutate();
  };

  return (
    <span className={classes.download}>
      <button className={classes.btn} onClick={onGenerateReport}>
        <DownloadOutline fontSize="small" style={{ marginRight: '4px' }} />
        <span>{isLoading ? 'Loading...' : 'Send Report'}</span>
      </button>

      {type === 'all' && (
        <HelpCircleOutline
          fontSize="small"
          style={{ marginBottom: '2px' }}
          onClick={() => {
            setOpen(true);
            setActiveToolbarTab('');
          }}
        />
      )}

      <Dialog open={open} className={classes.dialogContainer} onClose={() => setOpen(false)}>
        <Typography className={classes.heading}>DOWNLOAD REQUIREMENTS</Typography>
        <Typography className={classes.desc}>
          <ol>
            <li>You must choose Filter and Search first before you can download your Waire Report.</li>
            <li>You must choose a timeframe. </li>
            <li>You can only download up to 1 year at a time.</li>
          </ol>
          <div style={{ margin: '20px 0px 20px 0px' }}>Your Report:</div>
          <ul>
            <li>Will be sent as a link to your email.</li>
            <li>You can leave this page while the Report is processing.</li>
          </ul>
        </Typography>

        <IconButton
          color="primary"
          className={classes.filter}
          onClick={() => {
            setOpen(false);
            setActiveToolbarTab?.('Filter and Search');
          }}
        >
          <FilterVariant style={{ marginRight: '8px' }} />
          Filter and Search
        </IconButton>
      </Dialog>
    </span>
  );
}
