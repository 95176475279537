import React, { useEffect, useState } from 'react';
import { Button, Divider, makeStyles, MenuProps, TextField, Typography } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import styled from 'styled-components';
import { Col, Row } from 'reactstrap';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import SwapVertIcon from '@material-ui/icons/SwapVert';
import Edit from '@material-ui/icons/Edit';
import { ReorderHorizontal } from 'mdi-material-ui';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { patch, post, get } from '../../utils/axios';
import { TOAST_CONFIG } from '../../constants';

export const FieldList = styled.div``;
export const FieldRow = styled.div`
  margin: 36px 0 12px;

  .row {
    align-items: center;
  }
`;
export const FieldType = styled.div`
  font-family: 'Roboto Italic';
  padding: 16px;
  border: 1px solid #cccccc;
`;

export const FieldInfoContainer = styled.div`
  position: relative;
`;

const dropDownOptions = [
  {
    label: 'Text',
    value: 'string',
  },
  {
    label: 'Dropdown',
    value: 'list',
  },
];

const useStyles = makeStyles(() => ({
  fieldHeader: {
    fontWeight: 'bold',
  },
  fieldName: {
    fontSize: '12px',
    fontWeight: 'bold',
    position: 'absolute',
    top: '-22px',
  },
  dragHandle: {
    color: '#ccc',
  },
  centerCol: {
    display: 'flex',
    justifyContent: 'center',
  },
  fieldsGrid: { display: 'grid', gridTemplateColumns: '30px 50% 10% 10% 10%', gridGap: '10px', maxWidth: 800 },
  hints: {
    color: '#999999',
    fontSize: 12,
    fontStyle: 'italic',
    marginTop: 10,
  },
  listValues: {
    padding: '10px 16px',
    maxHeight: 400,
    overflowY: 'scroll',
    width: '175%',
  },
  listValue: {
    fontStyle: 'italic',
    padding: '5px 0',
    display: 'grid',
    gridTemplateColumns: '5fr 1fr',
  },
  addMoreItems: {
    display: 'grid',
    gridTemplateColumns: '4fr 1fr',
    gridGap: '20px',
    width: '600px',
    marginTop: 24,
  },
  col: {
    maxWidth: 800,
  },
}));

const Tags = () => {
  const classes = useStyles();

  const { handleSubmit, control, watch, setValue } = useForm();

  const [fields, setFields] = useState([]);
  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [editable, setEditable] = useState(false);
  const [moreItemsValue, setMoreItemsValue] = useState('');
  const [formId, setFormId] = useState('');

  const watchFieldType = watch('fieldType');

  const getTags = async () => {
    setLoading(true);

    try {
      const { data } = await get(`/inspections/tags`);
      setFields(data.tags);
      setFormId(data._id);
    } catch (error) {
      setApiError(error?.response?.data?.message);
    }

    setLoading(false);
  };

  useEffect(() => {
    getTags();
  }, []);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result.map((el: any, i) => ({
      ...el,
      order: i + 1,
    }));
  };

  const saveOrEdit = async (tags) => {
    setLoading(true);

    try {
      if (formId) {
        const { data } = await patch(`/inspections/tags/${formId}`, { tags });
        setFields(data.tags);
      } else {
        const { data } = await post(`/inspections/tags`, { tags });
        setFields(data.tags);
        setFormId(data._id);
      }
    } catch (error) {
      setApiError(error?.response?.data?.message);

      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }

    setLoading(false);
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    if (!result.destination) return;

    const orderedItems = reorder(fields, result.source.index, result.destination.index);

    await saveOrEdit(orderedItems);
  };

  const handleChangeCheckbox = async (name: string, property: 'mandatory' | 'visible') => {
    const twoFieldsAlreadyActive = fields.filter((f) => f.visible).length >= 2;
    const selectedField = fields.find((col) => col.name === name);

    if (!selectedField.visible && twoFieldsAlreadyActive) {
      toast.error(
        'Two fields are already active. Please make one field inactive to make this field active.',
        TOAST_CONFIG,
      );
      return;
    }

    const newCols = fields.map((col) => {
      if (col.name !== name) return col;

      const update = { ...col, [property]: !col[property] };

      if (property === 'mandatory' && !col['mandatory']) update['visible'] = true;
      else if (property === 'visible' && col['visible']) update['mandatory'] = false;

      return update;
    });

    await saveOrEdit(newCols);
  };

  const MenuProps: Partial<MenuProps> = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: 'bottom',
      horizontal: 'left',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  };

  const validate = (field: any): boolean => {
    if (fields?.find((x) => x?.name?.toLowerCase() === field.fieldName?.toLowerCase())) {
      toast.error('Field already exists', TOAST_CONFIG);

      return true;
    }

    return false;
  };

  const handleCreate = async (field: any) => {
    if (validate(field)) return;

    try {
      setIsSubmitting(true);

      const twoFieldsAlreadyActive = fields.filter((f) => f.visible).length >= 2;

      const newField = {
        mandatory: !twoFieldsAlreadyActive,
        visible: !twoFieldsAlreadyActive,
        order: fields?.length + 1 || 1,
        name: field.fieldName,
        type: field.fieldType,
      };

      if (field.fieldType === 'list') {
        newField['values'] = field.fieldValues.split(',').map((value) => ({ value, visible: true }));
      }

      const newFields = [...fields, newField];

      await saveOrEdit(newFields);

      setValue('fieldName', '');
      setValue('fieldType', '');
      setValue('fieldValues', '');
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }

    setIsSubmitting(false);
  };

  const addValues = async () => {
    const newFields = [...fields];
    newFields[0].values.push({ value: moreItemsValue, visible: true });
    setMoreItemsValue('');
    setFields(newFields);
    await saveOrEdit(newFields);
  };

  const changeValueVisiblity = async (checked: boolean, valueIdx: number) => {
    const newFields = [...fields];
    newFields[0].values[valueIdx].visible = checked;
    setFields(newFields);
    await saveOrEdit(newFields);
  };

  return (
    <>
      <Row className="mb-5">
        <Col md={10} className={classes.col}>
          <Typography variant="h5" className="mb-3">
            Manage Tags
          </Typography>
          <Typography>
            Tags allow your inspectors to categorize Container Inspections from the mobile app. You have a limit of two
            fields and two field types: text/number and drop downs with a single selection. After you add a field, you
            can reorder them, make them mandatory, or hide them — this will affect how they appear and function in the
            mobile app. If you hide a field, it will no longer appear for your inspectors (but will not affect previous
            Container Inspections you created).
          </Typography>
        </Col>
      </Row>
      <Row className="mb-5">
        <Col md={10} className={classes.col}>
          <Typography>
            <strong>Add a New Field</strong>
          </Typography>
          <Divider className="mt-1 mb-3" />
          <FormComponent
            onSubmit={handleSubmit(handleCreate)}
            error={apiError?.message}
            hasBorder={false}
            hasPadding={false}
          >
            <Controller
              name="fieldName"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoComplete="off"
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  id="fieldName"
                  label="Enter New Field Name"
                  name="fieldName"
                  className="mb-3"
                />
              )}
              rules={{
                required: 'Field Name required',
              }}
            />

            <Controller
              name="fieldType"
              control={control}
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl variant="outlined" component="fieldset" margin="normal">
                    <InputLabel id="field-type-label">Choose Field Type</InputLabel>
                    <Select
                      labelId="field-type-label"
                      value={value}
                      onChange={onChange}
                      MenuProps={MenuProps}
                      label="Choose Field Type"
                    >
                      <MenuItem key="list" value="list">
                        Dropdown
                      </MenuItem>
                      <MenuItem key="string" value="string">
                        Text
                      </MenuItem>
                    </Select>
                  </FormControl>
                );
              }}
              rules={{
                required: 'Field Type required',
              }}
            />

            {watchFieldType === 'list' && (
              <Controller
                name="fieldValues"
                control={control}
                defaultValue=""
                render={({ field: { onChange, value }, fieldState: { error } }) => (
                  <TextField
                    autoFocus
                    onChange={onChange}
                    error={!!error}
                    helperText={error ? error.message : 'Ex: Apples,Oranges,Cherries'}
                    value={value}
                    variant="outlined"
                    fullWidth
                    id="fieldValues"
                    label="Dropdown Values"
                    name="fieldValues"
                  />
                )}
                rules={{
                  required: 'Dropdown Values required',
                }}
              />
            )}

            <Loader isLoading={isSubmitting} small className="mt-2">
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                ADD FIELD
              </Button>
              <Typography className={classes.hints}>
                At this time, only drop down menu fields are editable. If you no longer want to use a field, select the
                Hide checkbox.
              </Typography>
            </Loader>
          </FormComponent>
        </Col>
      </Row>
      <Row>
        <Col>
          <Loader isLoading={loading} wide>
            <div className={classes.fieldsGrid}>
              <div style={{ width: '50px' }}>
                <SwapVertIcon />
              </div>
              <Col xs={4} sm={10}>
                <Typography className={classes.fieldHeader}>Current Fields</Typography>
              </Col>
              <Col xs={4} sm={2} className={classes.centerCol}>
                <Typography className={classes.fieldHeader}>Required</Typography>
              </Col>
              <Col xs={4} sm={2} className={classes.centerCol}>
                <Typography className={classes.fieldHeader}>Show/Hide</Typography>
              </Col>
              <Col xs={4} sm={2} className={classes.centerCol}>
                <Typography className={classes.fieldHeader}>Edit</Typography>
              </Col>
              <Divider className="mt-1 mb-1" style={{ width: '780px' }} />
            </div>

            <Row>
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable">
                  {(provided) => (
                    <FieldList {...provided.droppableProps} ref={provided.innerRef}>
                      {fields
                        .sort((a, b) => a.order - b.order)
                        .map((item, index) => (
                          <Draggable key={`${item.name}_${index}`} draggableId={`${item.name}_${index}`} index={index}>
                            {(provided) => (
                              <FieldRow ref={provided.innerRef} {...provided.draggableProps}>
                                <div className={classes.fieldsGrid}>
                                  <div style={{ width: '50px' }}>
                                    <div {...provided.dragHandleProps}>
                                      <ReorderHorizontal className={classes.dragHandle} />
                                    </div>
                                  </div>
                                  <Col sm={10}>
                                    <FieldInfoContainer>
                                      <Typography className={classes.fieldName}>{item.name}</Typography>
                                      <FieldType>
                                        {item.type === 'list' ? (
                                          <span>Choose from list of items</span>
                                        ) : (
                                          <span>Text</span>
                                        )}
                                      </FieldType>
                                    </FieldInfoContainer>

                                    {item.type === 'list' && (
                                      <div className={classes.listValues}>
                                        {item.values.map((val, idx) => (
                                          <div key={idx} className={classes.listValue}>
                                            {val.value}
                                            <Checkbox
                                              checked={val.visible}
                                              onChange={(e) => changeValueVisiblity(e.target.checked, idx)}
                                            />
                                          </div>
                                        ))}
                                      </div>
                                    )}

                                    {editable && item.type === 'list' && (
                                      <div className={classes.addMoreItems}>
                                        <TextField
                                          autoFocus
                                          onChange={(e) => setMoreItemsValue(e.target.value)}
                                          value={moreItemsValue}
                                          variant="outlined"
                                          fullWidth
                                          id="extraListValues"
                                          label="Add More Items"
                                          name="extraListValues"
                                        />

                                        <Button fullWidth variant="contained" color="primary" onClick={addValues}>
                                          ADD
                                        </Button>
                                      </div>
                                    )}
                                  </Col>
                                  <Col xs={2} className={classes.centerCol}>
                                    <Checkbox
                                      checked={item.mandatory}
                                      onChange={() => handleChangeCheckbox(item.name, 'mandatory')}
                                      style={{ alignSelf: 'start' }}
                                    />
                                  </Col>
                                  <Col xs={2} className={classes.centerCol}>
                                    <Checkbox
                                      checked={item.visible}
                                      onChange={() => handleChangeCheckbox(item.name, 'visible')}
                                      style={{ alignSelf: 'start' }}
                                    />
                                  </Col>
                                  <Col xs={2} className={classes.centerCol}>
                                    <Edit
                                      onClick={() => (item.type === 'list' ? setEditable(!editable) : null)}
                                      style={{
                                        marginTop: '8px',
                                        color: item.type !== 'list' ? '#999999' : '',
                                        cursor: item.type !== 'list' ? 'not-allowed' : 'pointer',
                                      }}
                                    />
                                  </Col>
                                </div>
                              </FieldRow>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </FieldList>
                  )}
                </Droppable>
              </DragDropContext>
            </Row>
          </Loader>
        </Col>
      </Row>
    </>
  );
};

export default Tags;
