import { darken } from '@material-ui/core';
import { createTheme } from '@material-ui/core/styles';
import { DefaultTheme } from 'styled-components';

const DefaultMuiTheme = createTheme();

export const MuiTheme = createTheme({
  overrides: {
    MuiPickersDay: {
      daySelected: {
        backgroundColor: '#ffe202',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiToolbar: {
      gutters: {
        padding: '0 !important',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: '0',
      },
      input: {
        padding: '16px 14px',
      },
    },
    MuiInput: {
      underline: {
        '&:after': {
          borderBottom: '2px solid black',
        },
      },
    },
    MuiInputLabel: {
      outlined: {
        fontSize: '14px',
      },
    },
    MuiButton: {
      containedPrimary: {
        backgroundColor: '#ffe202',
        color: '#000',

        '&:hover': {
          backgroundColor: darken('#ffe202', 0.1),
        },
      },
      contained: {
        fontWeight: 'bold',
        borderRadius: '0',
      },
    },
    MuiIconButton: {
      root: {
        color: 'black !important',
      },
      colorPrimary: {
        borderRadius: '10px',
        fontSize: '16px',
        backgroundColor: '#ffe202',
        color: 'black',

        '&:hover': {
          backgroundColor: darken('#ffe202', 0.1),
        },
      },
    },
    MuiTouchRipple: {
      root: {
        color: 'rgba(255,226,2,0.4)',
      },
    },
    MuiRadio: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'rgba(255,226,2,0.04)',
        },
        '&$checked': {
          '&:hover': {
            backgroundColor: 'rgba(255,226,2,0.04)',
          },
        },
      },
      root: {
        '& .MuiIconButton-label': {
          color: '#ffe202 !important',
        },
        '& span': {
          '& svg:nth-of-type(1)': {
            color: '#707070',
          },
        },
        '&$checked span': {
          '& svg:nth-of-type(1)': {
            color: '#707070',
          },
          '& svg:nth-of-type(2)': {
            color: '#ffe202',
            transform: 'scale(1.6)',
          },
        },
      },
    },
    MuiCheckbox: {
      colorSecondary: {
        '&:hover': {
          backgroundColor: 'rgba(255,226,2,0.04)',
        },
        '&$checked': {
          '&:hover': {
            backgroundColor: 'rgba(255,226,2,0.04)',
          },
        },
      },
      root: {
        '& svg': {
          visibility: 'hidden',
        },
        '&$checked span': {
          color: '#ffe202',

          '& svg': {
            visibility: 'visible',
          },
        },
        '&$checked.Mui-disabled span': {
          color: '#F7F0B7',
        },
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: '2px solid #707070',
        borderTop: '4px solid black',
        '@supports ( -moz-appearance:none )': {
          borderBottom: '2px solid #707070',
          borderTop: '4px solid black',
        },
      },
    },
    MuiTableCell: {
      head: {
        fontSize: '14px',
        fontWeight: 'bold',
        border: 'none',
        whiteSpace: 'nowrap',
      },
      root: {
        padding: '8px 16px',
        borderBottom: 'none',
        whiteSpace: 'nowrap',
      },
    },
    MuiTableRow: {
      root: {
        fontSize: '16px',
        '&:hover': {
          background: '#EEEEEE !important',
        },
      },
    },
    MuiTypography: {
      h5: {
        fontSize: '22px',
        fontWeight: 'bold',
      },
    },
    MuiListItem: {
      root: {
        color: 'black',

        '& .MuiListItemIcon-root': {
          color: 'black',
          minWidth: DefaultMuiTheme.spacing(5),
        },

        '&.Mui-selected': {
          backgroundColor: 'rgba(0, 0, 0, 0.08)',
          '& .MuiListItemIcon-root': {
            // color: '#ffe202',
          },
        },
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#707070',
      },
    },
  },
});

export const BabacoTheme: DefaultTheme = {
  name: 'babaco-theme',
  colors: {
    yellow: '#ffe202',
  },
  spacing: {
    xxs: '4px',
    xs: '8px',
    sm: '12px',
    md: '24px',
    lg: '36px',
  },
};
export type CustomThemeInterface = typeof BabacoTheme;
