import React from 'react';

export const svgs = {
  containerInspections: (
    <svg
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M20 6H2V2H20V6M11.03 12H8V10.5C8 10.22 8.22 10 8.5 10H13.04C14.84 8.87 17.07 8.7 19 9.5V7H3V20H11.82C9.7 17.8 9.44 14.5 11.03 12M23.39 21L22 22.39L18.88 19.32C18.19 19.75 17.37 20 16.5 20C14 20 12 18 12 15.5S14 11 16.5 11 21 13 21 15.5C21 16.38 20.75 17.21 20.31 17.9L23.39 21M19 15.5C19 14.12 17.88 13 16.5 13S14 14.12 14 15.5 15.12 18 16.5 18 19 16.88 19 15.5Z"
      />
    </svg>
  ),
  viewInspections: (
    <svg style={{ width: '24px', height: '24px' }} viewBox="0 0 24 24">
      <path
        fill="currentColor"
        d="M3,3H21V7H3V3M4,8H20V21H4V8M9.5,11A0.5,0.5 0 0,0 9,11.5V13H15V11.5A0.5,0.5 0 0,0 14.5,11H9.5Z"
      />
    </svg>
  ),
  viewInspectors: (
    <svg
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 24 24"
    >
      <path
        fill="currentColor"
        d="M16.5,12A2.5,2.5 0 0,0 19,9.5A2.5,2.5 0 0,0 16.5,7A2.5,2.5 0 0,0 14,9.5A2.5,2.5 0 0,0 16.5,12M9,11A3,3 0 0,0 12,8A3,3 0 0,0 9,5A3,3 0 0,0 6,8A3,3 0 0,0 9,11M16.5,14C14.67,14 11,14.92 11,16.75V19H22V16.75C22,14.92 18.33,14 16.5,14M9,13C6.67,13 2,14.17 2,16.5V19H9V16.75C9,15.9 9.33,14.41 11.37,13.28C10.5,13.1 9.66,13 9,13Z"
      />
    </svg>
  ),
  tags: (
    <svg
      style={{
        width: '24px',
        height: '24px',
      }}
      viewBox="0 0 24 24"
    >
      <path d="M5.5,9A1.5,1.5 0 0,0 7,7.5A1.5,1.5 0 0,0 5.5,6A1.5,1.5 0 0,0 4,7.5A1.5,1.5 0 0,0 5.5,9M17.41,11.58C17.77,11.94 18,12.44 18,13C18,13.55 17.78,14.05 17.41,14.41L12.41,19.41C12.05,19.77 11.55,20 11,20C10.45,20 9.95,19.78 9.58,19.41L2.59,12.42C2.22,12.05 2,11.55 2,11V6C2,4.89 2.89,4 4,4H9C9.55,4 10.05,4.22 10.41,4.58L17.41,11.58M13.54,5.71L14.54,4.71L21.41,11.58C21.78,11.94 22,12.45 22,13C22,13.55 21.78,14.05 21.42,14.41L16.04,19.79L15.04,18.79L20.75,13L13.54,5.71Z" />
    </svg>
  ),
  manageLogistics: (
    <svg xmlns="http://www.w3.org/2000/svg" width={25.132} height={19.509}>
      <path d="M0 19.509v-2.984a3.6 3.6 0 0 1 .565-1.963 3.442 3.442 0 0 1 1.571-1.335 24.506 24.506 0 0 1 3.87-1.367 15.95 15.95 0 0 1 4.047-.518 14.784 14.784 0 0 1 2.073.147q1.037.147 2.073.356v1.665a5.263 5.263 0 0 0-2.34 1.728 4.291 4.291 0 0 0-.927 2.7v1.571Zm12.817 0v-1.6a2.674 2.674 0 0 1 .346-1.333 2.447 2.447 0 0 1 .974-.96 10.443 10.443 0 0 1 2.34-.872 10.65 10.65 0 0 1 2.5-.291 11.141 11.141 0 0 1 2.513.283 9.172 9.172 0 0 1 2.325.88 2.232 2.232 0 0 1 .974.938 2.752 2.752 0 0 1 .346 1.355v1.6Zm6.157-6.943a2.851 2.851 0 0 1-2.1-.856 2.851 2.851 0 0 1-.856-2.1 2.851 2.851 0 0 1 .856-2.1 2.851 2.851 0 0 1 2.1-.856 2.851 2.851 0 0 1 2.1.856 2.851 2.851 0 0 1 .856 2.1 2.851 2.851 0 0 1-.856 2.1 2.851 2.851 0 0 1-2.099.856Zm-8.922-3.142a4.55 4.55 0 0 1-3.346-1.367 4.55 4.55 0 0 1-1.367-3.346 4.55 4.55 0 0 1 1.367-3.346A4.55 4.55 0 0 1 10.053 0a4.55 4.55 0 0 1 3.346 1.367 4.55 4.55 0 0 1 1.367 3.346 4.55 4.55 0 0 1-1.367 3.346 4.55 4.55 0 0 1-3.346 1.366Z" />
    </svg>
  ),
  tripsData: (
    <svg xmlns="http://www.w3.org/2000/svg" width={23.39} height={20.791}>
      <path d="M5.263 20.791 0 15.528l5.263-5.263 1.364 1.364-2.924 2.924h9.291v1.949H3.7l2.924 2.924Zm10.655-4.288a.935.935 0 0 1-.682-.292.935.935 0 0 1-.292-.682.919.919 0 0 1 .292-.7.962.962 0 0 1 .682-.276.947.947 0 0 1 .7.276.946.946 0 0 1 .276.7.962.962 0 0 1-.276.682.92.92 0 0 1-.7.289Zm3.9 0a.935.935 0 0 1-.682-.292.935.935 0 0 1-.292-.682.919.919 0 0 1 .292-.7.962.962 0 0 1 .682-.276.947.947 0 0 1 .7.276.947.947 0 0 1 .276.7.962.962 0 0 1-.276.682.92.92 0 0 1-.702.289Zm-1.689-5.977-1.364-1.364 2.924-2.924H10.4V4.289h9.291l-2.924-2.924L18.127 0l5.263 5.263ZM3.575 6.238a.935.935 0 0 1-.682-.292.935.935 0 0 1-.292-.682.92.92 0 0 1 .292-.7.962.962 0 0 1 .682-.276.947.947 0 0 1 .7.276.947.947 0 0 1 .276.7.962.962 0 0 1-.276.682.919.919 0 0 1-.702.291Zm3.9 0a.935.935 0 0 1-.682-.292.935.935 0 0 1-.292-.682.92.92 0 0 1 .292-.7.962.962 0 0 1 .682-.276.947.947 0 0 1 .7.276.947.947 0 0 1 .276.7.962.962 0 0 1-.276.682.919.919 0 0 1-.703.291Z" />
    </svg>
  ),
  createLogisticCustomer: (
    <svg xmlns="http://www.w3.org/2000/svg" width={29.523} height={19.1}>
      <path d="M14.577 9.07a7.126 7.126 0 0 0 1.184-2.03 7.026 7.026 0 0 0 .384-2.429 7.026 7.026 0 0 0-.384-2.429 7.127 7.127 0 0 0-1.184-2.03 4.981 4.981 0 0 1 4.105.707 4.26 4.26 0 0 1 1.768 3.752 4.26 4.26 0 0 1-1.768 3.752 4.981 4.981 0 0 1-4.105.707Zm6.643 10.025v-2.891a5.644 5.644 0 0 0-.8-2.922 6.062 6.062 0 0 0-2.768-2.276q5.32.676 7.273 1.968a3.79 3.79 0 0 1 1.953 3.229v2.891Zm3.383-8.888V7.132h-3.076V5.287H24.6V2.212h1.845v3.075h3.075v1.845h-3.073v3.075ZM9.688 9.223a4.5 4.5 0 0 1-3.321-1.292A4.5 4.5 0 0 1 5.075 4.61a4.5 4.5 0 0 1 1.292-3.321A4.5 4.5 0 0 1 9.688-.003a4.5 4.5 0 0 1 3.321 1.292 4.5 4.5 0 0 1 1.292 3.321 4.5 4.5 0 0 1-1.292 3.321 4.5 4.5 0 0 1-3.322 1.297ZM0 19.099v-2.891a3.511 3.511 0 0 1 .569-1.953 3.492 3.492 0 0 1 1.522-1.307 24.334 24.334 0 0 1 3.952-1.415 15.1 15.1 0 0 1 3.644-.431 14.908 14.908 0 0 1 3.629.431 24.566 24.566 0 0 1 3.936 1.415 3.591 3.591 0 0 1 1.538 1.307 3.444 3.444 0 0 1 .584 1.953v2.891ZM9.687 7.382a2.688 2.688 0 0 0 1.984-.784 2.688 2.688 0 0 0 .784-1.984 2.688 2.688 0 0 0-.784-1.984 2.688 2.688 0 0 0-1.984-.784 2.688 2.688 0 0 0-1.984.784 2.688 2.688 0 0 0-.784 1.984 2.688 2.688 0 0 0 .784 1.984 2.688 2.688 0 0 0 1.984.785Zm-7.842 9.872h15.684v-1.046a1.829 1.829 0 0 0-.246-.923 1.669 1.669 0 0 0-.769-.677 19 19 0 0 0-3.6-1.322 14.455 14.455 0 0 0-3.229-.338 14.25 14.25 0 0 0-3.214.338 20.613 20.613 0 0 0-3.644 1.322 1.61 1.61 0 0 0-.723.661 1.827 1.827 0 0 0-.261.938ZM9.687 4.615Zm0 12.639Z" />
    </svg>
  ),
};
