import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AuthContext } from '../../auth';
import Table from '../../components/Table';
import { formatDate } from '../../utils/helpers';
import { QUERY_KEYS } from '../../constants';
import { toastError } from '../../utils/toastError';
import { get } from '../../utils/axios';

const ManageLogisticLocation = (props) => {
  const history = useHistory();

  const locationId = props.match.params?.id;

  const { user } = useContext(AuthContext);

  const { data, isLoading } = useQuery(
    [QUERY_KEYS.waire_location_customers, locationId],
    async () => {
      const { data } = await get(`/locations/waire/${locationId}`);
      return data;
    },
    {
      onError: toastError,
    },
  );

  const columns = [
    {
      title: 'Customers',
      field: 'name',
      mandatory: true,
      default: true,
    },
    {
      title: 'Records',
      field: 'records',
      mandatory: true,
      default: true,
    },
    {
      title: 'Trips',
      field: 'trips',
      mandatory: true,
      default: true,
    },
    {
      title: 'Visits',
      field: 'visits',
      mandatory: true,
      default: true,
    },
    {
      title: 'Created',
      field: 'created',
      default: true,
      render: (data) => (data?.created ? formatDate(data?.created, user?.timezone) : '-'),
    },
    {
      title: 'Signed In',
      field: 'lastSignIn',
      default: true,
      render: (data) => (data?.lastSignIn ? formatDate(data?.lastSignIn, user?.timezone) : '-'),
    },
    {
      title: 'Email',
      field: 'email',
      mandatory: true,
      default: true,
    },
    {
      title: 'Address',
      field: 'address',
      mandatory: true,
      default: true,
    },
  ];

  const onRowClick = (_, rowData) => {
    history.push(`/logistics/${rowData._id}`);
  };

  const headerText = (
    <div style={{ fontWeight: 'bold', marginBottom: 40, fontSize: 16 }}>Location: {data?.locationName || ''}</div>
  );

  return (
    <Table
      pages={[data?.customers || []]}
      columns={columns}
      onRowClick={onRowClick}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      autoTableCell
      headerText={headerText}
    />
  );
};

export default ManageLogisticLocation;
