import React, { useContext } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { useMutation, useQuery } from 'react-query';
import Table from '../../components/Table';
import { AuthContext } from '../../auth';
import { QUERY_KEYS } from '../../constants';
import { formatDate } from '../../utils/helpers';
import { toastError } from '../../utils/toastError';
import { get, patch } from '../../utils/axios';
import { queryClient } from '../../api/queryClient';

type ApiResult = {
  name: string;
  locations: {
    _id: string;
    disabled: boolean;
    name: string;
    createdAt: Date;
    records: number;
    trips: number;
    visits: number;
    mostRecent?: Date;
  }[];
};

const ManageLogisticCustomer = () => {
  const params: { id: string } = useParams();

  const { user } = useContext(AuthContext);

  const { data, isLoading } = useQuery<ApiResult>({
    queryKey: [QUERY_KEYS.logistic_customer, params.id],
    queryFn: async () => {
      const { data } = await get(`/logistic/${params.id}`);
      return data;
    },
    select: (data: any) => {
      return {
        name: data?.name || '',
        locations: data?.locations?.sort((a, b) => moment(b.mostRecent).unix() - moment(a.mostRecent).unix()) || [],
      };
    },
    onError: toastError,
  });

  const { mutate } = useMutation(
    async (location: string) => {
      await patch(`/logistic/${params.id}`, { location });
      queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.logistic_customer, params.id] });
    },
    {
      onError: toastError,
    },
  );

  const changeLocationStatus = async (e, location: string) => {
    e.stopPropagation();
    mutate(location);
  };

  const columns = [
    {
      title: 'Show/Hide',
      field: 'disabled',
      default: true,
      render: (data) => <Checkbox checked={!data.disabled} onClick={(e) => changeLocationStatus(e, data._id)} />,
    },
    { title: 'Location', field: 'name', default: true },
    { title: 'Records', field: 'records', default: true },
    { title: 'Trips', field: 'trips', default: true },
    { title: 'Visits', field: 'visits', default: true },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    {
      title: 'Most Recent',
      field: 'mostRecent',
      default: true,
      render: (data) => (data?.mostRecent ? formatDate(data.mostRecent, user?.timezone) : '-'),
    },
  ];

  return (
    <Table
      pages={[data?.locations || []]}
      columns={columns}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      sorting={true}
      autoTableCell
      headerText={`WAIRE Compliance: Manage 3PL Customers - ${data?.name || ''}`}
      description={
        <div style={{ marginBottom: 60 }}>
          If you click “Hide” in the Show/Hide column below, the inspector’s name and signature will not appear on the
          mobile app in the list of Locations when they fill out an Inspection. If you select “Show” in the Show/Hide
          column below, the Locations will appear on the mobile app.
        </div>
      }
      headerRefresh={false}
    />
  );
};

export default ManageLogisticCustomer;
