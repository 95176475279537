import styled, { css } from 'styled-components';

export const LogoWrapper = styled.div<{ filled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;

  .MuiSvgIcon-root {
    color: ${({ theme }) => theme.colors.yellow};
  }

  ${(props) => {
    if (props.filled) {
      return css`
        font-size: 36px;
        padding: ${({ theme }) => theme.spacing.sm};
        background: black;
        color: white;
        box-shadow: #00000029 0px 13px 23px;

        .MuiSvgIcon-root {
          font-size: 45px;
        }
      `;
    }
  }}
`;

export const TextWrapper = styled.div`
  display: inline-flex;
  margin-left: ${({ theme }) => theme.spacing.xs};
  font-size: 20px;

  > span {
    color: ${({ theme }) => theme.colors.yellow};
    font-family: 'Roboto Italic';
  }
`;
