import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Checkbox,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import { Magnify, CalendarMonth, ChevronDown, ChevronUp } from 'mdi-material-ui';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Col, Row } from 'reactstrap';
import clsx from 'clsx';
import FormComponent from '../FormComponent';
import Loader from '../Loader';
import { AuthContext } from '../../auth';
import { beautifyFieldName } from '../../utils/helpers';
import { get } from '../../utils/axios';
import { useBolLocations } from '../../hooks/useBolLocations';

interface ComponentProps {
  cancel?: () => void;
  apply: (fields) => any;
  isSubmitting?: boolean;
  values?: any;
  setKeys?: (value: string) => void;
}

const useStyles = makeStyles(() => ({
  formLabel: {
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #ADADAD',
    paddingBottom: '0.5rem',
    fontWeight: 'bold',
  },
  formLabelExpand: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'space-between',
    cursor: 'pointer',
  },
  wrapper: {
    minWidth: '360px',
  },
  radio: {
    appearance: 'none',
    borderRadius: '25px',
    border: '2px solid #707070',
    padding: '8px',
    marginRight: '10px',
    marginLeft: '13px',
    background: 'rgba(255,226,2,0.04)',
  },
  checked: {
    background: 'rgba(255,226,2,0.04)',
  },
}));

export default function TableFilterBol({ values, isSubmitting, cancel, apply, setKeys }: ComponentProps) {
  const classes = useStyles();

  const {
    user: { form: formConfig = [] },
  } = useContext(AuthContext);

  const { handleSubmit, control, setValue } = useForm();

  const [fields, setFields] = useState([]);
  const [fieldValues, setFieldValues] = useState(JSON.parse(localStorage.getItem('bol-field-values')) || {});
  const [showInputFields, setShowInputFields] = useState(false);
  const [showAlertFields, setShowAlertFields] = useState(false);

  const { locations } = useBolLocations();

  const fetchFields = async (field: string, value: string) => {
    try {
      const { data } = await get(`/bols/input-fields`, {
        field: field.replace('search.', ''),
        value,
      });
      setFields(data);
    } catch (error) {
      setFields([]);
    }
  };

  const updateFieldValues = (field: string, value: string) => {
    const newValues = { ...fieldValues };
    newValues[field] = value;
    setFieldValues(newValues);
    localStorage.setItem('bol-field-values', JSON.stringify(newValues));
  };

  useEffect(() => {
    Object.keys(values).forEach((key) => {
      setValue(key, values[key]);
    });
  }, []);

  const handleFilter = (data = {}) => {
    apply(data);
  };

  const renderDynamicFields = () =>
    formConfig?.length && (
      <>
        <FormControl component="fieldset" className="mb-2 mt-2">
          <FormLabel
            component="legend"
            className={clsx(classes.formLabel, classes.formLabelExpand, { 'mb-2': true })}
            onClick={() => setShowInputFields(!showInputFields)}
          >
            <span>Input Fields</span>
            <ListItemIcon>{showInputFields ? <ChevronDown /> : <ChevronUp />}</ListItemIcon>
          </FormLabel>

          {showInputFields &&
            formConfig?.map((formEl, i) => {
              if (formEl.type === 'object') {
                return formEl.children?.map((childEl) => {
                  const identifier = `search.${formEl.name}.${childEl.name}`;

                  switch (childEl.type) {
                    case 'string':
                      return (
                        <div key={identifier}>
                          <Controller
                            key={i}
                            name={identifier}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                options={fields}
                                getOptionLabel={(option) => option || ''}
                                onChange={(e, o) => {
                                  onChange(o || '');
                                  updateFieldValues(`${formEl.name}.${childEl.name}`, o);
                                }}
                                onBlur={() => {
                                  setFields([]);
                                }}
                                value={value || fieldValues[`${formEl.name}.${childEl.name}`]}
                                popupIcon={<Magnify />}
                                noOptionsText="Search"
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value={value}
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      id={identifier}
                                      name={identifier}
                                      label={`${formEl.name}: ${childEl.name}`}
                                      onChange={(e) => {
                                        fetchFields(identifier, e.target.value);
                                        updateFieldValues(`${formEl.name}.${childEl.name}`, e.target.value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      );

                    case 'number':
                      return (
                        <div key={identifier}>
                          <Controller
                            key={i}
                            name={identifier}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                options={fields}
                                getOptionLabel={(option) => option || ''}
                                onChange={(e, o) => {
                                  onChange(o || '');
                                  updateFieldValues(`${formEl.name}.${childEl.name}`, o);
                                }}
                                onBlur={() => {
                                  setFields([]);
                                }}
                                value={value || fieldValues[`${formEl.name}.${childEl.name}`]}
                                popupIcon={<Magnify />}
                                noOptionsText="Search"
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value={value}
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      id={identifier}
                                      name={identifier}
                                      label={`${formEl.name}: ${childEl.name}`}
                                      onChange={(e) => {
                                        fetchFields(identifier, e.target.value);
                                        updateFieldValues(`${formEl.name}.${childEl.name}`, e.target.value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      );

                    case 'list':
                      return (
                        <div key={identifier}>
                          <Controller
                            key={i}
                            name={identifier}
                            control={control}
                            render={({ field: { onChange, value }, fieldState: { error } }) => (
                              <Autocomplete
                                options={fields}
                                getOptionLabel={(option) => option || ''}
                                onChange={(e, o: any) => {
                                  onChange(o || '');
                                  updateFieldValues(`${formEl.name}.${childEl.name}`, o);
                                }}
                                onBlur={() => {
                                  setFields([]);
                                }}
                                value={value || fieldValues[`${formEl.name}.${childEl.name}`]}
                                popupIcon={<Magnify />}
                                noOptionsText="Search"
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value={value}
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      id={identifier}
                                      name={identifier}
                                      label={`${formEl.name}: ${childEl.name}`}
                                      onChange={(e) => {
                                        fetchFields(identifier, e.target.value);
                                        updateFieldValues(`${formEl.name}.${childEl.name}`, e.target.value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            )}
                          />
                        </div>
                      );

                    default:
                      return null;
                  }
                });
              } else {
                const identifier = `search.${formEl.name}`;
                switch (formEl.type) {
                  case 'string':
                    return (
                      <div key={identifier}>
                        <Controller
                          key={i}
                          name={identifier}
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => {
                            return (
                              <Autocomplete
                                options={fields}
                                getOptionLabel={(option) => option || ''}
                                onChange={(e, o) => {
                                  onChange(o || '');
                                  fetchFields(identifier, o);
                                  updateFieldValues(formEl.name, o);
                                }}
                                onBlur={() => {
                                  setFields([]);
                                }}
                                value={value || fieldValues[formEl.name]}
                                popupIcon={<Magnify />}
                                noOptionsText="Search"
                                renderInput={(params) => {
                                  return (
                                    <TextField
                                      {...params}
                                      error={!!error}
                                      helperText={error ? error.message : null}
                                      value={value}
                                      variant="outlined"
                                      margin="normal"
                                      fullWidth
                                      id={identifier}
                                      name={identifier}
                                      label={formEl.name}
                                      onChange={(e) => {
                                        fetchFields(identifier, e.target.value);
                                        updateFieldValues(formEl.name, e.target.value);
                                      }}
                                    />
                                  );
                                }}
                              />
                            );
                          }}
                        />
                      </div>
                    );

                  case 'number':
                    return (
                      <div key={identifier}>
                        <Controller
                          key={i}
                          name={identifier}
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              options={fields}
                              getOptionLabel={(option) => option || ''}
                              onChange={(e, o) => {
                                onChange(o || '');
                                updateFieldValues(formEl.name, o);
                              }}
                              onBlur={() => {
                                setFields([]);
                              }}
                              value={value || fieldValues[formEl.name]}
                              popupIcon={<Magnify />}
                              noOptionsText="Search"
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id={identifier}
                                    name={identifier}
                                    label={formEl.name}
                                    onChange={(e) => {
                                      fetchFields(identifier, e.target.value);
                                      updateFieldValues(formEl.name, e.target.value);
                                    }}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                    );

                  case 'list':
                    return (
                      <div key={identifier}>
                        <Controller
                          key={i}
                          name={identifier}
                          control={control}
                          render={({ field: { onChange, value }, fieldState: { error } }) => (
                            <Autocomplete
                              options={fields}
                              getOptionLabel={(option) => option || ''}
                              onChange={(e, o: any) => {
                                onChange(o || '');
                                updateFieldValues(formEl.name, o);
                              }}
                              onBlur={() => {
                                setFields([]);
                              }}
                              value={value || fieldValues[formEl.name]}
                              popupIcon={<Magnify />}
                              noOptionsText="Search"
                              renderInput={(params) => {
                                return (
                                  <TextField
                                    {...params}
                                    error={!!error}
                                    helperText={error ? error.message : null}
                                    value={value}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id={identifier}
                                    name={identifier}
                                    label={formEl.name}
                                    onChange={(e) => {
                                      fetchFields(identifier, e.target.value);
                                      updateFieldValues(formEl.name, e.target.value);
                                    }}
                                  />
                                );
                              }}
                            />
                          )}
                        />
                      </div>
                    );

                  default:
                    return null;
                }
              }
            })}
        </FormControl>
      </>
    );

  const renderAlertFields = () => {
    return (
      <>
        <FormLabel
          component="legend"
          className={clsx(classes.formLabel, classes.formLabelExpand, { 'mb-2': true })}
          onClick={() => setShowAlertFields(!showAlertFields)}
        >
          <span>Alerts</span>
          <ListItemIcon>{showAlertFields ? <ChevronDown /> : <ChevronUp />}</ListItemIcon>
        </FormLabel>

        {showAlertFields && (
          <Controller
            key={'alerts'}
            name={'alerts'}
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => {
              return (
                <RadioGroup name="alerts" value={value} onChange={onChange}>
                  <FormControlLabel value="any" control={<Radio />} label="Any Alerts" />
                  {formConfig?.map((formEl) => {
                    if (formEl.type === 'object') {
                      return formEl.children?.map((childEl) => {
                        const identifier = `${formEl.name}.${childEl.name}`;

                        return (
                          <FormControlLabel
                            key={identifier}
                            value={identifier}
                            control={<Radio />}
                            label={`Inbound has different ${beautifyFieldName(formEl.name)}:${beautifyFieldName(
                              childEl.name,
                            )}`}
                          />
                        );
                      });
                    }

                    const identifier = `${formEl.name}`;
                    return (
                      <FormControlLabel
                        key={identifier}
                        value={identifier}
                        control={<Radio />}
                        label={`Inbound has different ${beautifyFieldName(formEl.name)}`}
                      />
                    );
                  })}
                </RadioGroup>
              );
            }}
          />
        )}
      </>
    );
  };

  const renderLateDelivery = () => {
    return (
      <>
        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mb-2': true, 'mt-3': true })}>
          Late Delivery
        </FormLabel>
        <Controller
          name="late"
          control={control}
          defaultValue={false}
          render={({ field: { onChange, value } }) => (
            <FormControl component="fieldset">
              <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label="Yes" />
            </FormControl>
          )}
        />
      </>
    );
  };

  return (
    <div className={classes.wrapper}>
      <FormComponent onSubmit={handleSubmit(handleFilter)} hasBorder={false} hasPadding={false}>
        <FormControl component="fieldset" className="mb-3">
          <FormLabel component="legend" className={classes.formLabel}>
            <Row className="mt-2">
              <Col md={6}>Timeframe</Col>
              <Col md={6} className="reset">
                <span onClick={cancel}>Reset</span>
              </Col>
            </Row>
          </FormLabel>
          <Row className="mt-2">
            <Col md={6}>
              <Controller
                defaultValue={null}
                name="outboundDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const maxDate = new Date();
                  maxDate.setDate(maxDate.getDate() + 30);
                  return (
                    <KeyboardDatePicker
                      keyboardIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '112px',
                          }}
                        >
                          <CalendarMonth />
                          {value ? (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>{moment(value).format('MM/DD/yyyy')}</p>
                          ) : (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>Start Date</p>
                          )}
                        </div>
                      }
                      InputAdornmentProps={{ position: 'start' }}
                      style={!value ? { backgroundColor: '#F7F0B7', boxShadow: '0 3px 6px 0px #00000029' } : {}}
                      okLabel={<span style={{ color: '#000' }}>Ok</span>}
                      cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                      maxDateMessage={`Max selectable date is ${moment(maxDate).format('MM/DD/yyyy')}`}
                      maxDate={maxDate}
                      inputVariant="outlined"
                      disableToolbar
                      id="outboundDate"
                      name="outboundDate"
                      format="MM/DD/yyyy"
                      views={['year', 'month', 'date']}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Col>
            <Col md={6}>
              <Controller
                defaultValue={null}
                name="inboundDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const maxDate = new Date();

                  maxDate.setDate(maxDate.getDate() + 30);
                  return (
                    <KeyboardDatePicker
                      keyboardIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '112px',
                          }}
                        >
                          <CalendarMonth />
                          {value ? (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>{moment(value).format('MM/DD/yyyy')}</p>
                          ) : (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>End Date</p>
                          )}
                        </div>
                      }
                      InputAdornmentProps={{ position: 'start' }}
                      style={!value ? { backgroundColor: '#F7F0B7', boxShadow: '0 3px 6px 0px #00000029' } : {}}
                      okLabel={<span style={{ color: '#000' }}>Ok</span>}
                      cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                      maxDateMessage={`Max selectable date is ${moment(maxDate).format('MM/DD/yyyy')}`}
                      maxDate={maxDate}
                      inputVariant="outlined"
                      disableToolbar
                      id="inboundDate"
                      name="inboundDate"
                      format="MM/DD/yyyy"
                      views={['year', 'month', 'date']}
                      value={value}
                      onChange={onChange}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </FormControl>
        <FormLabel component="legend" className={classes.formLabel}>
          Status
        </FormLabel>
        <>
          <Controller
            name="status.open"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <FormControl component="fieldset">
                <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label={`Open`} />
              </FormControl>
            )}
          />
          <Controller
            name="status.closed"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <FormControl component="fieldset">
                <FormControlLabel control={<Checkbox checked={value} onChange={onChange} />} label={`Closed`} />
              </FormControl>
            )}
          />
        </>
        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mt-2': true, 'mb-2': true })}>
          Locations
        </FormLabel>
        <>
          <Controller
            name="outboundLocation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl variant="outlined" component="fieldset" className="mb-1" margin="normal">
                  <Select value={value || 'select'} onChange={onChange}>
                    <MenuItem value="select" disabled>
                      Outbound
                    </MenuItem>
                    {locations?.outbound?.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.location} ({option.total})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
          <Controller
            name="inboundLocation"
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl variant="outlined" component="fieldset" className="mb-2" margin="normal">
                  <Select value={value || 'select'} onChange={onChange}>
                    <MenuItem value="select" disabled>
                      Inbound
                    </MenuItem>
                    {locations?.inbound?.map((option, i) => (
                      <MenuItem key={i} value={option.value}>
                        {option.location} ({option.total})
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        </>

        {renderDynamicFields()}
        {renderAlertFields()}
        {renderLateDelivery()}

        <Row className="mt-2">
          <Col>
            {!!cancel && (
              <Button type="button" fullWidth variant="contained" color="default" onClick={cancel}>
                Cancel
              </Button>
            )}
          </Col>
          <Col>
            <Loader isLoading={isSubmitting} small>
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                APPLY
              </Button>
            </Loader>
          </Col>
        </Row>
      </FormComponent>
    </div>
  );
}
