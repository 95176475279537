import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { IconButton } from '@material-ui/core';
import clsx from 'clsx';

interface ComponentProps {
  onClick?: () => void;
  active?: boolean;
  title: string;
  icon: any;
  children: React.ReactNode;
  className?: any;
  floatingCardStyle?: any;
}

const useStyles = makeStyles(() => ({
  floatingCardWrapper: {
    position: 'relative',
  },
  floatingCard: {
    position: 'absolute',
    top: '100%',
    left: 0,
    zIndex: 11,
    background: '#fff',
    minWidth: '100%',
  },
  box: {
    border: '1px solid #CCCCCC',
    padding: '16px',
  },
}));

export default function TableToolbarCard({
  active,
  onClick,
  title,
  icon,
  className,
  children,
  floatingCardStyle,
}: ComponentProps) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const isActive = active !== undefined ? active : expanded;

  return (
    <div className={clsx(classes.floatingCardWrapper, className)}>
      <IconButton color="primary" onClick={() => (onClick ? onClick() : setExpanded(!expanded))}>
        {icon}
        &nbsp;{title}
      </IconButton>
      {isActive && <div className={clsx(classes.floatingCard, classes.box, floatingCardStyle)}>{children}</div>}
    </div>
  );
}
