import React, { useContext } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchBillingByLocation } from '../../api/billing';
import { AuthContext } from '../../auth';
import { QUERY_KEYS } from '../../constants';
import { toastError } from '../../utils/toastError';
import { Table } from 'antd';
import { formatDate } from '../../utils/helpers';

const BillingByLocation = () => {
  const params: any = useParams();
  const { user } = useContext(AuthContext);

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.customer_billing_history_by_location, params.id],
    queryFn: () => fetchBillingByLocation(params.id),
    onError: toastError,
  });

  const expandedRowRender = (data: any[]) => {
    const columns = [
      { key: '1', render: () => '', width: 200 },
      { key: 'invoice-code', dataIndex: 'invoice', width: 140 },
      { key: 'invoice-date', render: (item) => formatDate(item.createdAt, user.timezone), width: 240 },
      { key: 'bols', dataIndex: 'bols', width: 100 },
      { key: 'inspections', dataIndex: 'inspections', width: 100 },
      { key: 'waires', dataIndex: 'waires', width: 100 },
      { key: 'transactions', render: (item) => item.bols + item.inspections + item.waires, width: 100 },
      { key: 'overage', dataIndex: 'overage', width: 100 },
      {
        key: 'transaction-fee',
        render: (item) => `$${Number(item.transactionFee).toFixed(2)}`,
        width: 100,
      },
      { key: 'overage-fee', render: (item) => `$${Number(item.overageFee).toFixed(2)}`, width: 100 },
      {
        key: 'total',
        render: (item) => `$${Number(item.transactionFee + item.overageFee).toFixed(2)}`,
        width: 100,
      },
    ];

    return (
      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        showHeader={false}
        bordered={false}
        rowKey={(item) => item._id}
        size="middle"
      />
    );
  };

  const renderSummary = (items) => {
    let bols = 0,
      inspections = 0,
      overage = 0,
      overageFee = 0,
      transactions = 0,
      transactionFee = 0,
      waires = 0,
      total = 0;

    items.forEach(({ location }) => {
      bols += location.bols;
      inspections += location.inspections;
      overage += location.overage;
      overageFee += location.overageFee;
      transactions += location.bols + location.inspections + location.waires;
      transactionFee += location.transactionFee;
      waires += location.waires;
      total += location.transactionFee + location.overageFee;
    });

    return (
      <Table.Summary fixed={'bottom'}>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={1}>
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0} colSpan={2}></Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            {bols}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}>
            {inspections}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} colSpan={1}>
            {waires}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1}>
            {transactions}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            {overage}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1}>
            ${Number(transactionFee).toFixed(2)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={1}>
            ${Number(overageFee).toFixed(2)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9} colSpan={1}>
            ${Number(total).toFixed(2)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const columns = [
    { title: 'Location', key: 'location', render: (item) => item.location.firstName, width: 200 },
    { title: 'Invoices #', key: 'invoice-code', render: (item) => <span>({item.invoices.length})</span>, width: 140 },
    { title: 'Invoice Date', key: 'invoice-date', render: () => '-', width: 240 },
    { title: 'BOLs', key: 'bols', render: (item) => item.location.bols, width: 100 },
    { title: 'CIs', key: 'inspections', render: (item) => item.location.inspections, width: 100 },
    { title: 'WAIRE', key: 'waires', render: (item) => item.location.waires, width: 100 },
    {
      title: 'Transactions',
      key: 'transactions',
      render: (item) => item.location.bols + item.location.inspections + item.location.waires,
      width: 140,
    },
    { title: 'Overage', key: 'overage', render: (item) => item.location.overage, width: 100 },
    {
      title: 'Transaction Fee',
      key: 'transaction-fee',
      render: (item) => `$${item.location.transactionFee}`,
      width: 180,
    },
    { title: 'Overage Fee', key: 'overage-fee', render: (item) => `$${item.location.overageFee}`, width: 180 },
    {
      title: 'Total',
      key: 'total',
      render: (item) => `$${item.location.transactionFee + item.location.overageFee}`,
      width: 100,
    },
  ];

  return (
    <Table
      className="header"
      scroll={{ y: window.innerHeight / 2 }}
      columns={columns}
      expandable={{ expandedRowRender: (item) => expandedRowRender(item.invoices || []) }}
      dataSource={data}
      pagination={false}
      bordered={false}
      rowKey={(item) => item._id}
      size="middle"
      summary={renderSummary}
    />
  );
};

export default BillingByLocation;
