import styled from 'styled-components';

export const FieldList = styled.div``;
export const FieldRow = styled.div`
  margin: 36px 0 12px;

  .row {
    align-items: center;
  }
`;
export const FieldType = styled.div`
  font-family: 'Roboto Italic';
  padding: 16px;
  border: 1px solid #cccccc;
`;

export const FieldInfoContainer = styled.div`
  position: relative;
`;
