import moment from 'moment';
import { get } from '../utils/axios';
import { PAGE_LIMIT } from '../constants';

export const fetchBols = async (page = 1, filters: any = {}, locationId?: string) => {
  let status = filters?.status?.open && !filters?.status?.closed && 1;
  if (!filters?.status?.open && filters?.status?.closed) status = 0;

  const params: any = {
    page,
    limit: String(PAGE_LIMIT),
    startDate: filters?.outboundDate && moment(filters.outboundDate).toISOString(),
    endDate: filters?.inboundDate && moment(filters.inboundDate).toISOString(),
    status,
    outbound: filters?.outboundLocation && filters?.outboundLocation,
    inbound: filters?.inboundLocation && filters?.inboundLocation,
    late: filters?.late,
    differences: filters?.alerts,
    search: [],
  };

  for (const field in filters?.search) {
    if (filters?.search?.[field] && typeof filters?.search?.[field] !== 'object')
      params.search.push(`${field}:${filters?.search?.[field]}`);
    else if (typeof filters?.search?.[field] !== 'object') {
      for (const subfield in filters?.search?.[field]) {
        if (filters?.search?.[field]?.[subfield])
          params.search.push(`${field}.${subfield}:${filters?.search?.[field]}`);
      }
    }
  }

  let url = locationId ? `/locations/${locationId}?` : `/bols?`;

  for (const param in params) {
    if (params[param]?.length || params[param] === true || typeof params[param] === 'number')
      url += `${param}=${params[param]}&`;
  }

  const { data } = await get(url);

  return data;
};

export const fetchLocation = async (locationId: string) => {
  const { data } = await get(`/locations/${locationId}/summary`);

  return data;
};

export const fetchBolSummary = async () => {
  const { data } = await get(`/bols/summary`);

  return data;
};
