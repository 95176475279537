import styled, { css } from 'styled-components';

export const ColSelectWrapper = styled.div``;
export const ColumnCard = styled.div`
  padding: 12px 0;
`;

export const CTitle = styled.div`
  font-size: 16px;
  display: inline;
`;
