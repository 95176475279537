import { Button, Checkbox, FormControlLabel, FormGroup, makeStyles, Typography } from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { Col, Row } from 'reactstrap';

import FormComponent from '../../components/FormComponent';
import { AuthContext } from '../../auth';
import Loader from '../../components/Loader';
import { patch } from '../../utils/axios';
import InputFieldsEmailSettings from '../../components/InputFieldsEmailSettings';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  rightCol: {
    paddingLeft: `${theme.spacing(6)}px`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(3)}px`,
    },
  },
  containerSpecific: {
    marginTop: '36px',
  },
  subHeading: {
    fontWeight: 1000,
  },
}));

const PageEmailSettings = () => {
  const classes = useStyles();
  const { user, setUser } = useContext(AuthContext);

  const { handleSubmit, control, setValue } = useForm();

  const [apiError, setApiError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState([]);

  const normalizeForm = () => {
    const newForm = [];

    user?.form?.forEach((item) => {
      if (item.type === 'location' || item.type === 'string' || item.type === 'number' || item.type === 'list') {
        newForm.push(item);
      } else if (item.type === 'object') {
        item.children.forEach((child) => {
          if (child.type === 'location' || child.type === 'string' || child.type === 'number' || child.type === 'list')
            newForm.push({ ...child, name: `${item.name}:${child.name}` });
        });
      }
    });

    setForm(newForm);
  };

  useEffect(() => {
    normalizeForm();
  }, []);

  const toggleAllManually = (value) => {
    setValue('alerts.general.outboundCreated', value);
    setValue('alerts.general.inboundCreated', value);
    setValue('alerts.bol.lateInbound', value);
    setValue('alerts.general.inboundOnly', value);
    setValue('alerts.general.inboundOnlyIssues', value);

    form?.forEach((item) => {
      setValue(`alerts.bol.different[${item.name}]`, value);
    });
  };

  const handleEditSettings = async (fields) => {
    setApiError(null);
    setLoading(true);

    try {
      const { alerts } = fields;
      const { all, bol, general } = alerts;
      const { different, lateInbound } = bol;

      const notification = {
        late: all ? true : lateInbound,
        creation: {
          outbound: all ? true : general?.outboundCreated,
          inbound: all ? true : general?.inboundCreated,
          inboundOnly: all ? true : general?.inboundOnly,
          inboundOnlyIssues: all ? true : general?.inboundOnlyIssues,
        },
        difference: different,
      };

      const { data } = await patch(`/company/notifications`, {
        notification,
      });

      setUser({ ...user, notification: data.notificationSettings });

      toast.success('Preferences saved', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      setApiError(error?.response?.data?.message);

      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setLoading(false);
  };

  const areAllNotificationsChecked = () => {
    let checked = true;
    const { notification = {} } = user;
    const { creation = {}, late, difference = {} } = notification;

    if (!creation?.inbound || !creation?.outbound || !creation?.inboundOnly || !creation?.inboundOnlyIssues) {
      checked = false;
    }

    if (!late) {
      checked = false;
    }

    form?.forEach((item) => {
      if (!difference?.[item?.name]) {
        checked = false;
      }
    });

    return checked;
  };

  const uncheckAll = () => {
    setValue('alerts.all', false);
  };

  return (
    <Loader isLoading={loading}>
      <Row>
        <Col lg={6} className={classes.rightCol}>
          <FormComponent
            className={classes.form}
            hasBorder={false}
            hasPadding={false}
            onSubmit={handleSubmit(handleEditSettings)}
            error={apiError?.message}
          >
            <Typography variant="h5">
              <b>Email Settings</b>
            </Typography>

            <div className={classes.containerSpecific}>
              <Controller
                defaultValue={areAllNotificationsChecked()}
                name="alerts.all"
                control={control}
                render={({ field: { onChange, value } }) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={value}
                          onChange={(e) => {
                            toggleAllManually(e.target.checked);
                            onChange(e);
                          }}
                        />
                      }
                      label="Receive an Email for All Items"
                    />
                  );
                }}
              />
              <Typography variant="subtitle1" className="mt-3">
                <span className={classes.subHeading}>OUTBOUND-TO-INBOUND</span>
              </Typography>

              <Typography variant="subtitle1" className="mt-3 mb-2">
                <b>General:</b>
              </Typography>

              <FormGroup row>
                <Controller
                  defaultValue={user?.notification?.creation?.outbound || false}
                  name="alerts.general.outboundCreated"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) => {
                              uncheckAll();
                              onChange(e);
                            }}
                          />
                        }
                        label="Outbound Created"
                      />
                    );
                  }}
                />
              </FormGroup>
              <FormGroup row>
                <Controller
                  defaultValue={user?.notification?.creation?.inbound || false}
                  name="alerts.general.inboundCreated"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) => {
                              uncheckAll();
                              onChange(e);
                            }}
                          />
                        }
                        label="Inbound Submitted"
                      />
                    );
                  }}
                />
              </FormGroup>

              <InputFieldsEmailSettings
                uncheckAll={uncheckAll}
                form={form}
                difference={user.notification.difference}
                control={control}
              />

              <Typography variant="subtitle1" className="mt-2 mb-2">
                <b>Receive an email for time-based alerts:</b>
              </Typography>
              <FormGroup row>
                <Controller
                  defaultValue={user?.notification?.late || false}
                  name="alerts.bol.lateInbound"
                  control={control}
                  render={({ field: { onChange, value }, fieldState: { error } }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) => {
                              uncheckAll();
                              onChange(e);
                            }}
                          />
                        }
                        label="Not Delivered On Time"
                      />
                    );
                  }}
                />
              </FormGroup>

              <Typography variant="subtitle1" className="mt-5 mb-2">
                <b>INBOUND-ONLY:</b>
              </Typography>

              <FormGroup row>
                <Controller
                  defaultValue={user?.notification?.creation?.inboundOnly || false}
                  name="alerts.general.inboundOnly"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) => {
                              uncheckAll();
                              onChange(e);
                            }}
                          />
                        }
                        label="Inbound-Only Submitted"
                      />
                    );
                  }}
                />
              </FormGroup>

              <FormGroup row>
                <Controller
                  defaultValue={user?.notification?.creation?.inboundOnlyIssues || false}
                  name="alerts.general.inboundOnlyIssues"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={value}
                            onChange={(e) => {
                              uncheckAll();
                              onChange(e);
                            }}
                          />
                        }
                        label="Issues Reported"
                      />
                    );
                  }}
                />
              </FormGroup>
            </div>

            <Button type="submit" fullWidth variant="contained" color="primary" className="mt-5">
              APPLY
            </Button>
          </FormComponent>
        </Col>
      </Row>
    </Loader>
  );
};

export default PageEmailSettings;
