import moment from 'moment';
import { get } from '../utils/axios';
import { PAGE_LIMIT } from '../constants';

export const fetchInspections = async (page = 1, filters: any = {}, inspector?: string) => {
  const params: any = {
    page,
    limit: PAGE_LIMIT,
    startDate: filters?.startDate && moment(filters.startDate).toISOString(),
    endDate: filters?.endDate && moment(filters.endDate).toISOString(),
    tags: [],
  };

  if (filters?.inspector) params['inspector'] = filters.inspector;

  if (filters?.location) params['location'] = filters.location;

  if (filters?.issues) params['issues'] = filters?.issues === '0' ? true : false;

  if (filters?.tags) {
    Object.keys(filters.tags).forEach((tag: string) => {
      if (filters?.tags?.[tag]) {
        params.tags.push(`${tag}:${filters.tags?.[tag]}`);
      }
    });
  }

  let url = '/inspections';

  if (inspector) url = `/inspectors/${inspector}/inspections`;

  const { data } = await get(url, params);

  return data;
};

export const fetchInspectionsSummary = async (inspector?: string) => {
  let url = '/inspections/summary';
  if (inspector) url = `/inspectors/${inspector}/summary`;

  const { data } = await get(url);
  return data;
};

export const generateReport = async (filters: any = {}) => {
  const params: any = {
    startDate: filters?.startDate && moment(filters.startDate).toISOString(),
    endDate: filters?.endDate && moment(filters.endDate).toISOString(),
    tags: [],
  };

  if (filters?.inspector) params['inspector'] = filters.inspector;

  if (filters?.location) params['location'] = filters.location;

  if (filters?.issues) params['issues'] = filters?.issues === '0' ? true : false;

  if (filters?.tags) {
    Object.keys(filters.tags).forEach((tag: string) => {
      if (filters?.tags?.[tag]) {
        params.tags.push(`${tag}:${filters.tags?.[tag]}`);
      }
    });
  }

  const { data } = await get('/inspections/generate-report', params);
  return data;
};
