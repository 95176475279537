import { Typography, makeStyles } from '@material-ui/core';
import { ChartBox } from 'mdi-material-ui';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import { useInfiniteQuery, useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { fetchBolSummary, fetchBols } from '../../api/bol';
import { queryClient } from '../../api/queryClient';
import { fetchUserDetails } from '../../api/user';
import { AuthContext } from '../../auth';
import Table from '../../components/Table';
import TableFilterBol from '../../components/TableFilterBol';
import { PAGE_LIMIT, QUERY_KEYS } from '../../constants';
import { useBolLocations } from '../../hooks/useBolLocations';
import { patch } from '../../utils/axios';
import { getDynamicFields } from '../../utils/getDynamicFields';
import { formatDate } from '../../utils/helpers';
import { bolListKeys } from '../../utils/queryKeys';
import { toastError } from '../../utils/toastError';

const useStyles = makeStyles((theme) => ({
  colAlert: {
    display: 'block',
    color: '#FF0000',
    whiteSpace: 'nowrap',
  },
  filters: {
    width: '100%',
    display: 'flex',
    gap: 5,
    marginBottom: 8,
    flexWrap: 'wrap',
    wordWrap: 'break-word',
  },
}));

type Bols = {
  bols: any[];
  total: number;
  open: number;
  closed: number;
};

const PageBolList = () => {
  const classes = useStyles();
  const history = useHistory();

  const { setUser, user } = useContext(AuthContext);

  const [filters, setFilters] = useState<any>({});

  const { data: summary } = useQuery(QUERY_KEYS.bol_summary, () => {
    return fetchBolSummary();
  });

  const { locations } = useBolLocations();

  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, unknown, Bols>({
    queryKey: [QUERY_KEYS.bols, ...bolListKeys(filters)],
    queryFn: ({ pageParam = 1 }) => fetchBols(pageParam, filters),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
  });

  const bols = data?.pages?.map((item) => item.bols);
  const total = data?.pages?.[0]?.total || 0;
  const open = data?.pages?.[0]?.open || 0;
  const closed = data?.pages?.[0]?.closed || 0;
  const hasMoreData = data?.pages?.length * PAGE_LIMIT < total;

  const { mutate } = useMutation(({ bols }: { bols: { columns: string[] } }) => patch(`/settings`, { bols }), {
    onSuccess: async () => {
      const data = await fetchUserDetails();
      setUser(data);
    },
  });

  const saveColumnOrder = (columns: any[]) => {
    const activeColumns = columns
      .filter((column: { active: boolean; title: string }) => column.active)
      .map((column) => column.title);

    mutate({ bols: { columns: activeColumns } });
  };

  const handleRefresh = async () => {
    setFilters({});
    queryClient.resetQueries({ queryKey: [QUERY_KEYS.bols] });
  };

  useEffect(() => {
    return () => {
      localStorage.removeItem('bol-field-values');
    };
  }, []);

  const isDefault = (title: string) => !!user?.columns?.bols.find((item) => item === title);

  const columns = [
    {
      title: 'Status',
      field: 'status',
      mandatory: true,
      default: isDefault('Status'),
    },
    { title: 'BOL #', field: 'code', mandatory: true, default: true },
    {
      title: 'Outbound',
      field: 'outbound',
      default: isDefault('Outbound'),
      render: (data) => data?.outbound?.creator?.firstName,
    },
    {
      title: 'Date Opened',
      field: 'outboundDate',
      default: isDefault('Date Opened'),
      render: (data) =>
        data?.outbound?.date ? formatDate(data?.outbound?.date, data?.outbound?.creator?.timezone) : '-',
    },
    ...getDynamicFields(user?.form),
    {
      title: 'Inbound',
      field: 'inbound',
      default: isDefault('Inbound'),
      render: (data) => data?.inbound?.creator?.firstName || '-',
    },
    {
      title: 'Date Closed',
      field: 'inboundDate',
      default: isDefault('Date Closed'),
      render: (data) => (data?.inbound?.date ? formatDate(data?.inbound?.date, data?.inbound?.creator?.timezone) : '-'),
    },
    {
      title: 'Late',
      field: 'late',
      default: isDefault('Late'),
      render: (data) => (data?.late ? <div className={classes.colAlert}>Yes</div> : 'No'),
    },
    {
      title: 'Alerts',
      field: 'differences',
      default: isDefault('Alerts'),
      render: (data) =>
        data?.differences?.length
          ? data?.differences?.map((diff) => (
              <div className={classes.colAlert} key={diff}>
                {diff}
              </div>
            ))
          : '-',
    },
  ];

  const onRowClick = (_, rowData) => {
    history.push(`/bol/${rowData.code}`);
  };

  const toolbarContent = [
    {
      isExpandable: true,
      title: 'Account Summary',
      icon: <ChartBox />,
      content: (
        <>
          <Typography className="text-nowrap">
            {user?.firstName}&nbsp;{user?.lastName}
          </Typography>
          <Typography className="text-nowrap">{user?.company}</Typography>
          <Typography className="text-nowrap">{user?.address}</Typography>
          <Typography className="text-nowrap">{user?.phone}</Typography>
          <Typography className="text-nowrap">{user?.email}</Typography>
          <Typography className="text-nowrap">
            Created: {user?.createdAt && formatDate(user?.createdAt, user?.timezone)}
          </Typography>
          <Typography className="text-nowrap">
            Signed in: {user?.lastSignIn && formatDate(user?.lastSignIn, user?.timezone)}
          </Typography>
          <Typography className="text-nowrap">Total BOLs: {summary?.total}</Typography>
          <Typography className="text-nowrap">Open BOLs: {summary?.open}</Typography>
          <Typography className="text-nowrap">Closed BOLs: {summary?.closed}</Typography>
        </>
      ),
    },
  ];

  const generateFields = (fields = {}, parent = '') => {
    return Object.keys(fields)
      .filter((field) => fields[field] || fields[field] === 'object')
      .map((field) =>
        typeof fields[field] === 'object' ? (
          generateFields(fields[field], field)
        ) : (
          <span key={field} style={{ marginLeft: 2 }}>
            <b>{parent ? `${parent}:${field}` : field}: </b>
            {fields[field]}
          </span>
        ),
      );
  };

  const inputFields = generateFields(filters?.search);

  const header = (
    <div className={classes.filters}>
      <span>
        <b>Total: </b>
        <span>{total}</span>
      </span>
      <span>
        <b>Open: </b>
        <span>{open}</span>
      </span>
      <span>
        <b>Closed: </b>
        <span>{closed}</span>
      </span>
      <span>
        <b> Timeframe: </b>
        {filters.inboundDate && filters.outboundDate ? (
          <span>
            {moment(filters.outboundDate).format('ddd MMM DD, YYYY')} -{' '}
            {moment(filters.inboundDate).format('ddd MMM DD, YYYY')}
          </span>
        ) : (
          <span>All</span>
        )}
      </span>
      <span>
        <b> Outbound: </b>
        <span>{locations?.outbound?.find((loc) => loc.value === filters?.outboundLocation)?.location || 'All'}</span>
      </span>
      <span>
        <b> Inbound: </b>
        <span>{locations?.inbound?.find((loc) => loc.value === filters?.inboundLocation)?.location || 'All'}</span>
      </span>
      <span>
        <b> Status: </b>
        {filters?.status?.open && <span>Open </span>}
        {filters?.status?.closed && <span>Closed </span>}
        {!filters?.status?.open && !filters?.status?.closed && <span>All</span>}
      </span>
      {inputFields.length > 0 ? (
        inputFields
      ) : (
        <span>
          <b> Input Fields: </b>
          <span>All</span>
        </span>
      )}
      {
        <span>
          <b> Alerts: </b>
          {filters?.alerts ? <span style={{ textTransform: 'capitalize' }}>{filters.alerts}</span> : <span>All</span>}
        </span>
      }
      <span>
        <b> Late: </b>
        <span>{filters?.late ? 'Yes' : 'No'} </span>
      </span>
    </div>
  );

  return (
    <Table
      pages={bols || []}
      columns={columns}
      onRowClick={onRowClick}
      toolbarContent={toolbarContent}
      showToolbar={true}
      isLoading={isLoading}
      filterComponent={TableFilterBol}
      hasMoreData={hasMoreData}
      autoTableCell
      headerText="View BOLs"
      header={header}
      headerRefresh
      headerHits={summary?.total || 0}
      filters={filters}
      setFilters={setFilters}
      fetchNextPage={fetchNextPage}
      onRefresh={handleRefresh}
      saveColumnOrder={saveColumnOrder}
    />
  );
};

export default PageBolList;
