import { CircularProgress } from '@material-ui/core';
import React from 'react';
import { LoadingWrapper, ProgressWrapper } from './Loader.style';

interface ComponentProps {
  isLoading: boolean;
  children?: React.ReactNode;
  wide?: boolean;
  small?: boolean;
  className?: any;
}

export default function Loader({ isLoading, children, className, wide = false, small = false }: ComponentProps) {
  return (
    <LoadingWrapper className={className}>
      {children}
      {isLoading && (
        <ProgressWrapper wide={wide}>
          <CircularProgress
            size={small ? 24 : 40}
            // color={'inherit'}
          />
        </ProgressWrapper>
      )}
    </LoadingWrapper>
  );
}
