import React, { useContext, useEffect, useState } from 'react';
import { AccountCircle, ChevronDown, ChevronUp } from 'mdi-material-ui';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuIcon from '@material-ui/icons/Menu';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Logout as LogoutIcon } from 'mdi-material-ui';
import { Divider, useMediaQuery, useTheme } from '@material-ui/core';
import { Link, Redirect, Route, Switch, useLocation, matchPath, useHistory } from 'react-router-dom';
import clsx from 'clsx';
import LogoText from '../../components/LogoText';
import { adminRoutes, superAdminRoutes } from '../../routes';
import PrivateRoute from '../../routes/privateRoute';
import { AuthContext } from '../../auth';
import { USER_TYPES } from '../../types';
import { getRedirectUrlBasedOnFeaturesAvailable } from '../../utils/helpers';

const APP_VERSION = process.env.REACT_APP_VERSION;

const drawerWidth = 260;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    backgroundColor: '#333333',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    minHeight: '64px',
  },
  main: {
    paddingTop: theme.spacing(8),
    width: '100%',
  },
  menuButton: {
    // marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    top: 'initial',
    padding: '0 12px',
    backgroundColor: '#EEEEEE',
  },
  drawerOpen: {
    top: 'initial',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    backgroundColor: '#EEEEEE',
  },
  drawerClose: {
    top: 'initial',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    backgroundColor: '#EEEEEE',
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(9),
    marginLeft: theme.spacing(7) + 1,
    transition: 'margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    height: '100%',
    overflowY: 'auto',
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(9)}px ${theme.spacing(3)}px`,
    },
    [theme.breakpoints.down('md')]: {
      padding: `${theme.spacing(9)}px ${theme.spacing(2)}px`,
    },
  },
  contentDrawerOpen: {
    marginLeft: drawerWidth,
    transition: 'margin 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
  },
  contentNoDrawer: {
    marginLeft: 0,
  },
  link: {
    textDecoration: 'none',
    color: 'inherit',
  },
  listItemText: () => ({
    '&>span': {
      fontSize: '12px',
      fontWeight: 'bold',
    },
  }),
  accountItemWrapper: {
    whiteSpace: 'pre-wrap',
  },
  accountItemPrimary: {
    fontSize: '14px',
    fontWeight: 'bold',
    width: 180,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  accountItemSecondary: {
    '& p': {
      margin: 0,
      fontSize: '12px',
      color: 'black',
      width: 180,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
}));

interface ComponentProps {
  children?: React.ReactNode;
}

const AppLayout = (props: ComponentProps) => {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('md'));
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showMenus, setShowMenus] = useState({ bols: true, containers: true, waire: true });
  const classes = useStyles({ drawerOpen });
  const location = useLocation();
  const { user, logout } = useContext(AuthContext);
  const history = useHistory();

  const routes = (user?.superAdmin ? superAdminRoutes : adminRoutes) || [];

  useEffect(() => {
    setDrawerOpen(matches);
  }, [matches]);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const shouldHideDrawer = () => {
    // @ts-ignore
    return !routes.find((route: any) => {
      if (route.hasSubMenu) {
        return route?.routes?.find((subRoute) => {
          return matchPath(location.pathname, {
            path: subRoute.url,
            exact: true,
            strict: false,
          });
        });
      }
      return matchPath(location.pathname, {
        path: route.url,
        exact: true,
        strict: false,
      });
    })?.private;
  };

  const logoutUser = async () => {
    await logout();

    history.replace('/login');
  };

  useEffect(() => {
    if (location.pathname === '/') {
      if (user?.emailVerified && user?.type === USER_TYPES.ADMIN) {
        history.replace(getRedirectUrlBasedOnFeaturesAvailable(user?.features));
      } else history.replace('/login');
    } else if (location.pathname !== '/' && !user) history.replace('/login');
  }, []);

  const renderRoute = (route) => {
    const Icon = route.icon;

    const featureExistsButNotEnabled = route.enableOnFeature && !user?.features?.[route.enableOnFeature];

    if (route.hasSubMenu) {
      if (featureExistsButNotEnabled) return null;

      return (
        <div key={route.title}>
          <div
            key={route.title}
            className={classes.link}
            onClick={() => setShowMenus({ ...showMenus, [route.key]: !showMenus[route.key] })}
          >
            <ListItem button selected={false} alignItems="center">
              <ListItemIcon>{route.svg ? route.svg : <Icon />}</ListItemIcon>
              <ListItemText primary={route.title} className={classes.listItemText} />
              <ListItemIcon>{showMenus[route.key] ? <ChevronDown /> : <ChevronUp />}</ListItemIcon>
            </ListItem>
          </div>
          {showMenus[route.key] && (
            <div style={{ paddingLeft: drawerOpen ? '2.2rem' : '0rem' }}>
              {route.routes.filter((route) => route.showOnMenu).map(renderRoute)}
            </div>
          )}
        </div>
      );
    }

    if (featureExistsButNotEnabled) return null;

    return (
      <Link key={route.url} to={route.url} className={classes.link}>
        <ListItem button selected={location.pathname === route.url} alignItems="center">
          <ListItemIcon>{route.svg ? route.svg : <Icon />}</ListItemIcon>
          <ListItemText primary={route.title} className={classes.listItemText} />
        </ListItem>
      </Link>
    );
  };

  const registerRoutes = () => {
    return routes.map((route) => {
      if (route.hasSubMenu) {
        return route.routes.map((subRoute) => (
          <Route key={subRoute.url} exact path={subRoute.url} component={subRoute.component} />
        ));
      } else if (route.private) {
        return <PrivateRoute key={route.url} exact path={route.url} component={route.component} />;
      }

      return <Route key={route.url} exact path={route.url} component={route.component} />;
    });
  };

  return (
    <div className={`${classes.root} app-wrapper`}>
      <CssBaseline />
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar className={classes.toolbar}>
          <LogoText />
        </Toolbar>
      </AppBar>
      <main className={`${classes.main} main-wrapper`}>
        {!shouldHideDrawer() && (
          <Drawer
            variant="permanent"
            className={clsx(classes.drawer, {
              [classes.drawerOpen]: drawerOpen,
              [classes.drawerClose]: !drawerOpen,
            })}
            classes={{
              paper: clsx({
                [classes.drawerOpen]: drawerOpen,
                [classes.drawerClose]: !drawerOpen,
              }),
            }}
          >
            <List style={{ paddingBottom: '200px' }}>
              <ListItem button onClick={toggleDrawer}>
                <ListItemIcon>
                  <MenuIcon />
                </ListItemIcon>
              </ListItem>
              <ListItem alignItems="flex-start">
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                {drawerOpen && (
                  <div className={classes.accountItemWrapper}>
                    <div className={classes.accountItemPrimary}>{`${user?.firstName} ${user?.lastName}`}</div>
                    <div className={classes.accountItemSecondary}>
                      <p>{user?.company}</p>
                    </div>
                    <div className={classes.accountItemSecondary}>{`v ${APP_VERSION}`}</div>
                  </div>
                )}
              </ListItem>
              <Divider />
              {/* @ts-ignore */}
              {routes.filter((route) => route.showOnMenu).map(renderRoute)}
              <ListItem button onClick={logoutUser}>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText primary="Logout" className={classes.listItemText} />
              </ListItem>
            </List>
          </Drawer>
        )}
        <div
          className={clsx(classes.content, {
            [classes.contentDrawerOpen]: drawerOpen,
            [classes.contentNoDrawer]: shouldHideDrawer(),
          })}
          id="scrollableDiv"
        >
          <Switch>
            {registerRoutes()}
            <Route
              exact
              path="/404"
              component={() => <h1 style={{ textAlign: 'center', margin: '48px' }}>404 Page not found</h1>}
            />
            <Redirect to="/404" />
          </Switch>
          {props.children}
        </div>
      </main>
    </div>
  );
};

export default AppLayout;
