import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Link from '@material-ui/core/Link';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import { HammerWrench } from 'mdi-material-ui';
import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { Row } from 'reactstrap';
import { AuthContext } from '../../auth';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { get } from '../../utils/axios';
import { toastError } from '../../utils/toastError';
import WaireDownloadReport from '../WaireDownloadReport';
import { Logistic } from '../WaireFilter/WaireFilter';
import TripsDialog from './TripsDialog';
import VisitsDialog from './VisitsDialog';
import WaireButton from './WaireButton';
import WaireDialog from './WaireDialog';
import WaireMenu from './WaireMenu';

const useStyles = makeStyles(() => ({
  link: {
    color: '#5572EA',
    fontWeight: 700,
  },
  report: {
    display: 'flex',
    marginTop: 30,
    marginLeft: 720,
  },
  filters: {
    display: 'grid',
    gridTemplateColumns: '240px 280px 280px',
    alignItems: 'center',
    gap: '2rem',
    margin: '30px 0 60px 0',
  },
  trips: {
    marginTop: '60px',
    display: 'flex',
    flexDirection: 'column',
  },
  heading: {
    display: 'inline-block',
  },
  headingNumber: {
    display: 'inline-block',
    backgroundColor: 'black',
    padding: '2px 14px',
    color: 'white',
    fontSize: 26,
    fontWeight: 'bold',
    marginRight: '10px',
  },
  headingTitle: {
    display: 'inline-block',
    fontSize: 26,
    fontWeight: 'bold',
  },
  desc: {
    maxWidth: 800,
  },
  instructionsText: {
    color: '#5572EA',
    fontSize: 14,
    fontWeight: 700,
    margin: '20px 0',
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  divider: {
    margin: '60px 0',
    maxWidth: 1000,
    height: '2px !important',
  },
  visitTypes: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    maxWidth: 1000,
  },
  visitTypeTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginBottom: 10,
  },
  emission: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 20,
  },
}));

type ZeroEmission = {
  class8: number;
  'class4-7': number;
  'class2E-3': number;
};

type NearZeroEmission = Omit<ZeroEmission, 'class2E-3'>;

interface Summary {
  trips: {
    class8: number;
    'class2E-7': number;
  };
  visits: {
    batteryElectric: ZeroEmission;
    hydrogenFuelCell: ZeroEmission;
    naturalGas: NearZeroEmission;
    lng: NearZeroEmission;
  };
}

const WaireSummary: React.FC = () => {
  const { user } = useContext(AuthContext);

  const classes = useStyles();

  // menu
  const [menu, setMenu] = useState<null | HTMLElement>(null);
  const [year, setYear] = useState(new Date().getFullYear().toString());

  // filters
  const [creator, setCreator] = useState<string | undefined>(undefined);
  const [logistic, setLogistic] = useState<string | undefined>(undefined);

  // filter dialog
  const [dialog, setDialog] = useState(false);

  // instructions dialog
  const [instructions, setInstructions] = useState<'trips' | 'visits'>(null);

  const selectYear = (year: string) => {
    setYear(year);
    setMenu(null);
    setDialog(false);
  };

  const { data: summary } = useQuery<Summary>({
    queryKey: [QUERY_KEYS.waire_summary, year, creator, logistic],
    queryFn: async () => {
      const params = { year };

      if (creator && creator !== 'all') params['creator'] = creator;
      if (logistic && logistic !== 'all') params['logistic'] = logistic;
      const { data } = await get(`/waires/summary`, params);
      return data;
    },
    placeholderData: null,
    onError: toastError,
    enabled: !!year,
    refetchInterval: 30000,
  });

  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    select: (data) => [{ _id: 'all', firstName: 'All' }, ...data],
    placeholderData: [],
  });

  const { data: logistics } = useQuery<Logistic[]>({
    queryKey: QUERY_KEYS.all_logistics_customers_filter,
    queryFn: async () => {
      const { data } = await get('/logistic/all');
      return data;
    },
    select: (data) => [{ _id: 'all', name: 'All' }, ...data],
    placeholderData: [],
  });

  const disabled = !year || !summary;

  const menuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setMenu(event.currentTarget);
  };

  const menuClose = () => {
    setMenu(null);
  };

  const copyText = async (text: number) => {
    try {
      if (!year && !summary) {
        setDialog(true);
      }

      if (summary) {
        await window.navigator.clipboard.writeText(String(text));

        toast.success(`${text} Copied!`, {
          position: 'top-right',
          autoClose: 1000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
        });
      }
    } catch (error) {
      toast.error(error.message, TOAST_CONFIG);
    }
  };

  return (
    <Box style={{ display: 'flex', flexDirection: 'column' }}>
      <Box className={classes.report}>
        <WaireDownloadReport
          filters={{ year, logistic, location: creator }}
          type="compliance"
          total={summary?.trips?.class8 || 0 + summary?.trips?.['class2E-7'] || 0}
        />
      </Box>

      <Box className={classes.filters}>
        <WaireMenu menu={menu} menuOpen={menuOpen} menuClose={menuClose} selectYear={selectYear} year={year} />
        <FormControl variant="outlined" component="fieldset">
          <InputLabel id="location">By Location</InputLabel>
          <Select
            value={creator}
            onChange={(e: any) => {
              setCreator(e.target.value);
            }}
            labelId="location"
            label={'By Location'}
          >
            {locations?.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.firstName}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {user?.features?.logistic && (
          <FormControl variant="outlined" component="fieldset">
            <InputLabel id="customer">Choose 3PL Customer</InputLabel>
            <Select
              value={logistic}
              onChange={(e: any) => {
                setLogistic(e.target.value);
              }}
              labelId="customer"
              label={'Choose 3PL Customer'}
            >
              {logistics?.map((option) => (
                <MenuItem key={option._id} value={option._id}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Box>

      <Typography variant="body2" className={classes.desc}>
        Clicking any of the buttons below, allows you to copy the number of Trips (Section 1) or Visits (Section 2)
        which you can paste in the corresponding form fields in the WAIRE report filing at{' '}
        <Link href="https://xappp.aqmd.gov/isr" className={classes.link} target="_blank">
          https://xappp.aqmd.gov/isr
        </Link>
        . You will need to do this process of copying and pasting for each data point below (all 12 buttons).
      </Typography>

      <Box className={classes.trips}>
        <Box className={classes.heading}>
          <Typography className={classes.headingNumber}>1</Typography>
          <Typography className={classes.headingTitle}>Truck Trips (By Equipment Class)</Typography>
        </Box>

        <Typography className={classes.instructionsText} onClick={() => setInstructions('trips')}>
          <HammerWrench /> Detailed Instructions for Truck <i>Trips</i>
        </Typography>

        <WaireButton disabled={disabled} copyText={() => copyText(summary?.trips?.class8 || 0)}>
          Class 8 - {summary?.trips?.class8 || 0} Trips
        </WaireButton>

        <WaireButton disabled={disabled} copyText={() => copyText(summary?.trips?.['class2E-7'] || 0)}>
          Class 2b-7 - {summary?.trips?.['class2E-7'] || 0} Trips
        </WaireButton>
      </Box>

      <Divider className={classes.divider} />

      <Box>
        <Box className={classes.heading}>
          <Typography className={classes.headingNumber}>2</Typography>
          <Typography className={classes.headingTitle}>Truck Visits (By Low-Emission Fuel Type)</Typography>
        </Box>

        <Typography className={classes.instructionsText} onClick={() => setInstructions('visits')}>
          <HammerWrench />
          Detailed Instructions for Truck <i>Visits</i>
        </Typography>

        <Typography className={classes.emission}>ZE (Zero-Emission)</Typography>

        <Row sm={2} md={2} lg={1} className={classes.visitTypes}>
          <Box>
            <Typography className={classes.visitTypeTitle}>Battery Electric</Typography>

            <WaireButton disabled={disabled} copyText={() => copyText(summary?.visits?.batteryElectric?.class8 || 0)}>
              Class 8 / Battery Electric - {summary?.visits?.batteryElectric?.class8 || 0} Visits
            </WaireButton>

            <WaireButton
              disabled={disabled}
              copyText={() => copyText(summary?.visits?.batteryElectric?.['class4-7'] || 0)}
            >
              Class 4-7 / Battery Electric - {summary?.visits?.batteryElectric?.['class4-7'] || 0} Visits
            </WaireButton>

            <WaireButton
              disabled={disabled}
              copyText={() => copyText(summary?.visits?.batteryElectric?.['class2E-3'] || 0)}
            >
              Class 2b-3 / Battery Electric - {summary?.visits?.batteryElectric?.['class2E-3'] || 0} Visits
            </WaireButton>
          </Box>

          <Box>
            <Typography className={classes.visitTypeTitle}>Hydrogen Fuel Cell</Typography>

            <WaireButton disabled={disabled} copyText={() => copyText(summary?.visits?.hydrogenFuelCell?.class8 || 0)}>
              Class 8 / Hydgrogen Fuel Cell - {summary?.visits?.hydrogenFuelCell?.class8 || 0} Visits
            </WaireButton>

            <WaireButton
              disabled={disabled}
              copyText={() => copyText(summary?.visits?.hydrogenFuelCell?.['class4-7'] || 0)}
            >
              Class 4-7 / Hydgrogen Fuel Cell - {summary?.visits?.hydrogenFuelCell?.['class4-7'] || 0} Visits
            </WaireButton>

            <WaireButton
              disabled={disabled}
              copyText={() => copyText(summary?.visits?.hydrogenFuelCell?.['class2E-3'] || 0)}
            >
              Class 2b-3 / Hydgrogen Fuel Cell - {summary?.visits?.hydrogenFuelCell?.['class2E-3'] || 0} Visits
            </WaireButton>
          </Box>
        </Row>

        <Divider className={classes.divider} />

        <Typography className={classes.emission}>NZE (Near Zero-Emission)</Typography>

        <Row sm={2} md={2} lg={1} className={classes.visitTypes}>
          <Box>
            <Typography className={classes.visitTypeTitle}>Natural Gas</Typography>

            <WaireButton disabled={disabled} copyText={() => copyText(summary?.visits?.naturalGas?.class8 || 0)}>
              Class 8 / Natural Gas - {summary?.visits?.naturalGas?.class8 || 0} Visits
            </WaireButton>

            <WaireButton disabled={disabled} copyText={() => copyText(summary?.visits?.naturalGas?.['class4-7'] || 0)}>
              Class 4-7 / Natural Gas - {summary?.visits?.naturalGas?.['class4-7'] || 0} Visits
            </WaireButton>
          </Box>

          <Box>
            <Typography className={classes.visitTypeTitle}>Liquified Petroleum Gas</Typography>

            <WaireButton disabled={disabled} copyText={() => copyText(summary?.visits?.lng?.class8 || 0)}>
              Class 8 / Liquified Petroleum Gas - {summary?.visits?.lng?.class8 || 0} Visits
            </WaireButton>

            <WaireButton disabled={disabled} copyText={() => copyText(summary?.visits?.lng?.['class4-7'] || 0)}>
              Class 4-7 / Liquified Petroleum Gas - {summary?.visits?.lng?.['class4-7'] || 0} Visits
            </WaireButton>
          </Box>
        </Row>
      </Box>

      <WaireDialog open={dialog} onClose={() => setDialog(false)}>
        <WaireMenu menu={menu} menuOpen={menuOpen} menuClose={menuClose} selectYear={selectYear} year={year} />
      </WaireDialog>

      <TripsDialog open={instructions === 'trips'} onClose={() => setInstructions(null)} />

      <VisitsDialog open={instructions === 'visits'} onClose={() => setInstructions(null)} />
    </Box>
  );
};

export default WaireSummary;
