import React, { useContext } from 'react';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'react-query';
import Table from '../../components/Table';
import { AuthContext } from '../../auth';
import { QUERY_KEYS } from '../../constants';
import { formatDate } from '../../utils/helpers';
import { toastError } from '../../utils/toastError';
import { get } from '../../utils/axios';

const ManageLogisticCustomers = () => {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const { data, isLoading } = useQuery({
    queryKey: QUERY_KEYS.all_logistics_customers,
    queryFn: async () => {
      const { data } = await get(`/logistic`);
      return data;
    },
    onError: toastError,
  });

  const columns = [
    {
      field: 'edit',
      default: true,
      render: (data) => <Edit onClick={() => history.push(`/logistics/${data._id}`)} style={{ cursor: 'pointer' }} />,
    },
    { title: 'Customer', field: 'name', default: true },
    { title: 'Locations', field: 'locations', default: true },
    { title: 'Records', field: 'records', default: true },
    { title: 'Trips', field: 'trips', default: true },
    { title: 'Visits', field: 'visits', default: true },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    {
      title: 'Most Recent',
      field: 'mostRecent',
      default: true,
      render: (data) => (data?.mostRecent ? formatDate(data?.mostRecent, user?.timezone) : '-'),
    },
  ];

  return (
    <Table
      pages={[data || []]}
      columns={columns}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      sorting={true}
      autoTableCell
      headerText={
        <Typography variant="h5" className="mb-5">
          WAIRE Compliance: Manage 3PL Customers
        </Typography>
      }
      headerRefresh={false}
    />
  );
};

export default ManageLogisticCustomers;
