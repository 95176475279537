import { toast } from 'react-toastify';

export const PAGE_LIMIT = 30;

export const QUERY_KEYS: Record<string, string> = {
  bols: 'bols',
  bols_from_location: 'bols_from_location',
  bols_location_summary: 'bols_location_summary',
  waire_location_customers: 'waire_location_customers',
  locations: 'locations',
  inspections: 'inspections',
  inspections_summary: 'inspections_summary',
  inspectors: 'inspectors',
  waires: 'waires',
  waire_locations: 'waire_locations',
  customers: 'customers',
  customer_locations: 'customer_locations',
  customer_summary: 'customer_summary',
  customer_profile: 'customer_profile',
  inspection: 'inspection',
  container_inspections_filter_inspectors: 'container_inspections_filter_inspectors',
  container_inspections_filter_tags: 'container_inspections_filter_tags',
  bol_filter_locations: 'bol_filter_locations',
  waire_summary: 'waire_summary',
  bol_summary: 'bol_summary',
  bol_form: 'bol_form',
  user_details: 'user_details',
  all_locations: 'all_locations',
  all_logistics_customers: 'all_logistics_customers',
  all_logistics_customers_filter: 'all_logistics_customers_filter',
  logistic_customer: 'logistic_customer',
  customer_billing_history_by_location: 'customer_billing_history_by_location',
  customer_billing_history_by_invoice: 'customer_billing_history_by_invoice',
  customer_upcoming_invoices: 'customer_upcoming_invoices',
};

export const TOAST_CONFIG = {
  position: toast.POSITION.TOP_RIGHT,
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};
