import { makeStyles } from '@material-ui/core/styles';
import { Table } from 'antd';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchUpcomingBilling } from '../../api/billing';
import { QUERY_KEYS } from '../../constants';
import { toastError } from '../../utils/toastError';
import React from 'react';

const useStyles = makeStyles(() => ({
  summary: {
    paddingTop: 20,
    paddingBottom: 20,
    display: 'flex',
    gap: 10,
  },
}));

const UpcomingBilling = () => {
  const params: any = useParams();

  const classes = useStyles();

  const { data } = useQuery({
    queryKey: [QUERY_KEYS.customer_upcoming_invoices, params.id],
    queryFn: () => fetchUpcomingBilling(params.id),
    onError: toastError,
  });

  const columns = [
    { key: '1', render: () => '', width: 55 },
    {
      title: 'Location',
      key: 'Location',
      render: (item) => <span>{item.location}</span>,
      width: 240,
    },
    { title: 'BOLs', key: 'bols', render: (item) => item.bols, width: 100 },
    { title: 'CIs', key: 'inspections', render: (item) => item.inspections, width: 100 },
    { title: 'WAIRE', key: 'waires', render: (item) => item.waires, width: 100 },
    {
      title: 'Transactions',
      key: 'transactions',
      render: (item) => item.bols + item.inspections + item.waires,
      width: 140,
    },
    { title: 'Overage', key: 'overage', render: (item) => item.overage, width: 100 },
    {
      title: 'Transaction Fee',
      key: 'transaction-fee',
      render: (item) => `$${Number(item.transactionFee).toFixed(2)}`,
      width: 180,
    },
    {
      title: 'Overage Fee',
      key: 'overage-fee',
      render: (item) => `$${Number(item.overageFees).toFixed(2)}`,
      width: 180,
    },
    {
      title: 'Total',
      key: 'total',
      render: (item) => `$${Number(item.transactionFee + item.overageFees).toFixed(2)}`,
      width: 100,
    },
  ];

  const renderSummary = (items) => {
    let bols = 0,
      inspections = 0,
      overage = 0,
      transactions = 0,
      overageFees = 0,
      transactionFee = 0,
      waires = 0,
      total = 0;

    items.forEach((item) => {
      bols += item.bols;
      inspections += item.inspections;
      overage += item.overage;
      transactions += item.transactions;
      overageFees += item.overageFees;
      transactionFee += item.transactionFee;
      waires += item.waires;
      total += item.transactionFee + item.overageFees;
    });

    return (
      <Table.Summary fixed={'bottom'}>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0} colSpan={1}></Table.Summary.Cell>
          <Table.Summary.Cell index={1} colSpan={1}>
            Total
          </Table.Summary.Cell>
          <Table.Summary.Cell index={2} colSpan={1}>
            {bols}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={3} colSpan={1}>
            {inspections}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={8} colSpan={1}>
            {waires}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={5} colSpan={1}>
            {transactions}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1}>
            {overage}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={7} colSpan={1}>
            ${Number(transactionFee).toFixed(2)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={6} colSpan={1}>
            ${Number(overageFees).toFixed(2)}
          </Table.Summary.Cell>
          <Table.Summary.Cell index={9} colSpan={1}>
            ${Number(total).toFixed(2)}
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <div>
      <div className={classes.summary}>
        <span>
          <b>Upcoming Invoice Date: </b>
          {data?.nextInvoiceDate}
        </span>
        <span>
          <b>Invoice#: </b>
          {data?.nextInvoiceNumber}
        </span>
      </div>
      <Table
        scroll={{ y: window.innerHeight / 2 }}
        className="header"
        columns={columns}
        dataSource={data?.upcomingInvoices || []}
        pagination={false}
        bordered={false}
        rowKey={(item) => item.location}
        size="middle"
        summary={renderSummary}
      />
    </div>
  );
};

export default UpcomingBilling;
