export const visits = {
  zeroEmission: [
    { value: 'Class 8 / Electric', label: 'Class 8 / Battery Electric' },
    { value: 'Class 4-7 / Electric', label: 'Class 4-7 / Battery Electric' },
    { value: 'Class 2E-3 / Electric', label: 'Class 2E-3 / Battery Electric' },
    { value: 'Class 8 / Fuel Cell', label: 'Class 8 / Hydrogen Fuel Cell' },
    { value: 'Class 4-7 / Fuel Cell', label: 'Class 4-7 / Hydrogen Fuel Cell' },
    { value: 'Class 2E-3 / Fuel Cell', label: 'Class 2E-3 / Hydrogen Fuel Cell' },
  ],
  nearZeroEmission: [
    { value: 'Class 8 / Natural Gas', label: 'Class 8 / Natural Gas' },
    { value: 'Class 4-7 / Natural Gas', label: 'Class 4-7 / Natural Gas' },
    { value: 'Class 8 / Liquefied Petroleum Gas (propane or LNP)', label: 'Class 8 / Liquified Petroleum Gas' },
    { value: 'Class 4-7 / Liquefied Petroleum Gas (propane or LNP)', label: 'Class 4-7 / Liquified Petroleum Gas' },
  ],
};

export const FUEL_TYPES = {
  Electric: 'Battery Electric',
  'Fuel Cell': 'Hydrogen Fuel Cell',
  'Liquefied Petroleum Gas (propane or LNP)': 'Liquefied Petroleum Gas',
  'Natural Gas': 'Natural Gas',
  'Compressed Natural Gas (CNG)': 'Natural Gas',
  'Liquefied Natural Gas (LNG)': 'Natural Gas',
};

export const REFETCH_INTERVAL = 60 * 2 * 1000;

export const STALE_TIME = 60 * 1000;
