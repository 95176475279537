import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';
import Edit from '@material-ui/icons/Edit';
import React, { useContext } from 'react';
import { useInfiniteQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { fetchCustomers } from '../../api/customer';
import { queryClient } from '../../api/queryClient';
import { AuthContext } from '../../auth';
import Table from '../../components/Table';
import { PAGE_LIMIT, QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { Customer } from '../../types';
import { patch } from '../../utils/axios';
import { formatDate } from '../../utils/helpers';
import { toastError } from '../../utils/toastError';

type CustomerData = {
  customers: Customer[];
  total: number;
};

const Customers = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const { data, isLoading, fetchNextPage } = useInfiniteQuery<any, unknown, CustomerData>({
    queryKey: [QUERY_KEYS.customers],
    queryFn: ({ pageParam = 1 }) => fetchCustomers(pageParam),
    getNextPageParam: (_, pages) => {
      return pages.length + 1;
    },
    onError: toastError,
    onSuccess: () => {
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.customer_profile] });
    },
  });

  const total = data?.pages?.[0]?.total || 0;
  const hasMoreData = data?.pages?.length * PAGE_LIMIT < total;
  const customers = data?.pages?.map((item) => item.customers);

  const updateCustomerFeatures = async (event, customer, features) => {
    event.stopPropagation();

    try {
      await patch(`/customers/${customer}`, features);
      queryClient.resetQueries({ queryKey: [QUERY_KEYS.customers] });
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }
  };

  const columns = [
    {
      title: '',
      field: '',
      default: true,
      render: (data) => <Edit onClick={() => history.push(`/customers/${data._id}`)} style={{ cursor: 'pointer' }} />,
      minWidth: 30,
    },
    {
      title: 'BOL',
      field: 'bol',
      default: true,
      render: (data) => (
        <Checkbox
          checked={data.features.bol}
          onClick={(event) => updateCustomerFeatures(event, data._id, { features: { bol: !data.features.bol } })}
        />
      ),
    },
    {
      title: 'Inspection',
      field: 'inspection',
      default: true,
      render: (data) => (
        <Checkbox
          checked={data.features.inspection}
          onClick={(event) =>
            updateCustomerFeatures(event, data._id, { features: { inspection: !data.features.inspection } })
          }
        />
      ),
    },
    {
      title: 'WAIRE',
      field: 'waire',
      default: true,
      render: (data) => (
        <Checkbox
          checked={data.features.waire}
          onClick={(event) => updateCustomerFeatures(event, data._id, { features: { waire: !data.features.waire } })}
        />
      ),
    },
    {
      title: '3PL',
      field: 'logistic',
      default: true,
      render: (data) => (
        <Checkbox
          disabled={!data.features.waire}
          checked={data.features.logistic}
          onClick={(event) =>
            updateCustomerFeatures(event, data._id, {
              features: { logistic: !data.features.logistic },
            })
          }
        />
      ),
    },
    { title: 'Customer', field: 'name', default: true },
    { title: 'Admin', field: 'admin', default: true },
    { title: 'Email', field: 'email', default: true },
    { title: 'Locations', field: 'units.locations', default: true },
    { title: 'BOLs', field: 'units.bols', default: true },
    { title: 'CIs', field: 'units.inspections', default: true },
    { title: 'WAIRE', field: 'units.waires', default: true },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    {
      title: 'Last Signed In',
      field: 'signIn',
      default: true,
      render: (data) => (data?.signIn ? formatDate(data?.signIn, user?.timezone) : '-'),
    },
  ];

  const headerText = (
    <Typography variant="body1" style={{ marginBottom: '30px', fontWeight: 'bold', fontSize: '24px' }}>
      Customers
    </Typography>
  );

  return (
    <Table
      pages={customers || []}
      columns={columns}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      hasMoreData={hasMoreData}
      autoTableCell
      sorting={false}
      headerText={headerText}
      headerRefresh={false}
      fetchNextPage={fetchNextPage}
    />
  );
};

export default Customers;
