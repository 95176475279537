/* eslint-disable react/display-name */
import MaterialTable, { Icons } from '@material-table/core';
import { IconButton, Paper, Typography, makeStyles } from '@material-ui/core';
import AddBox from '@material-ui/icons/AddBox';
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import clsx from 'clsx';
import { FilterVariant, Refresh } from 'mdi-material-ui';
import React, { forwardRef, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import TableColumnSelect from '../TableColumnSelect';
import TableToolbarCard from '../TableToolbarCard';
import WaireDownloadReport from '../WaireDownloadReport';
import { removeExtraValues } from '../../utils/removeExtraValues';

const tableIcons: Icons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

interface SelectedColsI {
  field: string;
  title: string;
  active: boolean;
  sorting?: boolean;
}

const useStyles = makeStyles((theme) => ({
  heading: {
    marginBottom: 2,
    fontWeight: 'bold',
  },
  table: {
    boxShadow: 'none',
    '& .MuiTableCell-root': {
      '&:first-of-type': {
        maxWidth: '400px',
        whiteSpace: 'normal',
      },
    },
    '& .MuiTableCell-head': {
      'border-top': '2px solid #707070',
    },
  },
  tableLocation: {
    '& .MuiTableCell-root': {
      '&:first-of-type': {
        maxWidth: 'initial',
        whiteSpace: 'nowrap',
      },
    },
  },
  autoTableCell: {
    '& .MuiTableCell-root': {
      width: 'auto !important',
      '&:last-of-type': {
        width: '100% !important',
      },
    },
  },
  floatingCardWrapper: {
    position: 'relative',
  },
  floatingCard: {
    top: '100%',
    left: 0,
    zIndex: 11,
    position: 'absolute',
    background: '#fff',
    border: '1px solid #CCCCCC',
    padding: '16px',
    minWidth: '100%',
  },
  columnsTab: {
    top: '100%',
    left: 'initial',
    right: 0,
    zIndex: 11,
    position: 'absolute',
    background: '#fff',
    border: '1px solid #CCCCCC',
    padding: '16px',
    minWidth: '100%',
    width: 'max-content',
  },
  filterTab: {
    marginLeft: 'auto',
    [theme.breakpoints.down('md')]: {
      marginLeft: '0px',
    },
  },
  right: {
    left: 'auto',
    right: 0,
  },
  trips: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  toolbar: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: '1rem',
    marginBottom: 8,
    [theme.breakpoints.down('md')]: {
      justifyContent: 'flex-start',
    },
    [theme.breakpoints.down('sm')]: {
      alignItems: 'flex-start',
      flexDirection: 'column',
    },
  },
}));

export default function Table({
  isLoading,
  pages,
  columns,
  toolbarContent,
  selectedRow,
  filterComponent,
  filterCardText = 'Filter and Search',
  filterFacets,
  hasMoreData,
  autoTableCell,
  sorting = false,
  handleSortChange = () => {},
  type,
  headerText,
  description,
  headerRefresh,
  headerHits,
  showToolbar = false,
  showShowAndHideColumns = true,
  waire = false,
  filters,
  setFilters,
  fetchNextPage,
  onRefresh,
  header,
  activeTab,
  setActiveTab,
  saveColumnOrder,
  ...rest
}: any) {
  const classes = useStyles();
  const [selectedColumns, setSelectedColumns] = useState<SelectedColsI[]>(
    columns.map((col) => ({ ...col, active: !!col.default })),
  );

  const [activeToolbarTab, setActiveToolbarTab] = useState(null);

  const setColumns = (columns) => {
    setSelectedColumns(columns);

    if (saveColumnOrder) {
      saveColumnOrder(columns);
    }
  };

  const data =
    pages?.reduce((acc: any[], curr: any[]) => {
      acc = [...acc, ...curr];
      return acc;
    }, []) || [];

  const options = {
    toolbar: showToolbar,
    sorting: sorting,
    search: false,
    paging: false,
    draggable: false,
    rowStyle: (rowData) => {
      return {
        backgroundColor: selectedRow === rowData.tableData.id ? '#EEE' : '#FFF',
        ...(rowData.status === 'Open' && { color: '#008000' }),
        ...{ fontSize: '13px' },
      };
    },
  };

  const FilterComponent = filterComponent;
  const finalToolbar = [
    toolbarContent.map((tabData, i) =>
      tabData.isExpandable ? (
        <TableToolbarCard
          title={tabData.title}
          icon={tabData.icon}
          active={activeToolbarTab === tabData.title}
          onClick={() => {
            setActiveToolbarTab(tabData.title === activeToolbarTab ? null : tabData.title);
          }}
          key={i}
          floatingCardStyle={!showShowAndHideColumns && classes.right}
        >
          {tabData.content}
        </TableToolbarCard>
      ) : (
        tabData.content
      ),
    ),
  ];

  if (waire) {
    finalToolbar.push(
      <div style={{ display: 'block', flexGrow: 1, textAlign: 'end' }}>
        <WaireDownloadReport
          filters={filters}
          total={data.length}
          setActiveToolbarTab={setActiveToolbarTab}
          type="all"
        />
      </div>,
    );
  }

  if (filterComponent) {
    finalToolbar.push(
      <TableToolbarCard
        title={filterCardText}
        icon={<FilterVariant />}
        active={(activeToolbarTab || activeTab) === 'Filter and Search'}
        onClick={() => setActiveToolbarTab('Filter and Search' === activeToolbarTab ? null : 'Filter and Search')}
        key={toolbarContent.length + 1}
        className={classes.filterTab}
        floatingCardStyle={!showShowAndHideColumns && classes.right}
      >
        <FilterComponent
          isSubmitting={isLoading}
          apply={(fltrs) => {
            setFilters((prev) => ({
              ...removeExtraValues(prev),
              ...removeExtraValues(fltrs),
            }));
            setActiveToolbarTab(null);
            setActiveTab && setActiveTab(null);
          }}
          cancel={() => {
            localStorage.removeItem('location-field-values');
            localStorage.removeItem('bol-field-values');
            localStorage.removeItem('inspection-field-values');
            localStorage.removeItem('waire-field-values');
            setFilters({});
            setActiveToolbarTab('');
            setActiveTab && setActiveTab(null);
          }}
          values={filters || {}}
          filterFacets={filterFacets}
        />
      </TableToolbarCard>,
    );
  }

  if (showShowAndHideColumns) {
    finalToolbar.push(
      <TableToolbarCard
        title="Show and Hide Columns"
        icon={<ViewColumn />}
        active={activeToolbarTab === 'Show and Hide Columns'}
        onClick={() =>
          setActiveToolbarTab('Show and Hide Columns' === activeToolbarTab ? null : 'Show and Hide Columns')
        }
        key={toolbarContent.length + 2}
        floatingCardStyle={classes.columnsTab}
      >
        <TableColumnSelect columns={selectedColumns} setColumns={setColumns} />
      </TableToolbarCard>,
    );
  }

  if (waire) {
    finalToolbar.unshift(
      <div key={'trips'} className={classes.trips} onClick={onRefresh}>
        <IconButton>
          <Refresh />
        </IconButton>
        <Typography variant="body1">Refresh to See the Latest Trips</Typography>
      </div>,
    );
  }

  const components = {
    Toolbar: (props) => {
      return (
        <div className={classes.toolbar}>
          {finalToolbar.map((el) => el)}
          {/* <MTableToolbar {...props} /> */}
          {header}
        </div>
      );
    },
    Container: (props) => (
      <Paper
        className={clsx(
          classes.table,
          type === 'location' ? classes.tableLocation : '',
          autoTableCell ? classes.autoTableCell : '',
        )}
        {...props}
      />
    ),
  };

  const onOrderChange = (orderedColumnId, orderDirection) => {
    if (orderedColumnId === -1) {
      handleSortChange(null, null);
    } else {
      handleSortChange(selectedColumns.filter((c) => c.active)[orderedColumnId].field, orderDirection);
    }
  };

  return (
    <>
      <Typography variant="h6" className={classes.heading}>
        {headerText} {headerHits >= 0 && <i style={{ color: 'slategray' }}>({headerHits})</i>}
        {headerRefresh && (
          <IconButton style={{ marginLeft: '8px' }} onClick={onRefresh}>
            <Refresh />
          </IconButton>
        )}
      </Typography>

      {description && (
        <Typography variant="body2" className="mt-2 mb-4">
          {description}
        </Typography>
      )}

      <InfiniteScroll
        dataLength={data.length}
        next={fetchNextPage}
        hasMore={hasMoreData && !activeToolbarTab}
        loader={''}
        scrollableTarget="scrollableDiv"
        style={{ overflow: 'visible' }}
      >
        <MaterialTable
          title=""
          icons={tableIcons}
          options={options}
          columns={selectedColumns
            .filter((c) => c.active)
            .map((c) => ({ ...c, sorting: !!c.sorting, customSort: () => {} }))}
          data={data}
          components={components}
          isLoading={isLoading}
          {...rest}
          onOrderChange={onOrderChange}
        />
      </InfiniteScroll>
    </>
  );
}
