import { QueryClient } from 'react-query';
import { REFETCH_INTERVAL, STALE_TIME } from '../utils/constants';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchInterval: REFETCH_INTERVAL,
      retry: 2,
    },
  },
});
