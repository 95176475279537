import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Button,
  Radio,
  RadioGroup,
  FormControl,
  FormControlLabel,
  FormLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { CalendarMonth } from 'mdi-material-ui';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { Col, Row } from 'reactstrap';
import { useQuery } from 'react-query';
import clsx from 'clsx';
import FormComponent from '../FormComponent';
import Loader from '../Loader';
import { AuthContext } from '../../auth';
import { get } from '../../utils/axios';
import { QUERY_KEYS } from '../../constants';

interface ComponentProps {
  cancel?: () => void;
  apply: (fields) => any;
  isSubmitting?: boolean;
}

const useStyles = makeStyles(() => ({
  formLabel: {
    width: '100%',
    color: '#000',
    borderBottom: '1px solid #ADADAD',
    paddingBottom: '0.5rem',
    fontWeight: 'bold',
  },
  formLabelExpand: {
    display: 'flex',
    alignItems: 'center',
    justifyConter: 'space-between',
    cursor: 'pointer',
  },
  wrapper: {
    minWidth: '360px',
  },
  radio: {
    appearance: 'none',
    borderRadius: '25px',
    border: '2px solid #707070',
    padding: '8px',
    marginRight: '10px',
    marginLeft: '13px',
    background: 'rgba(255,226,2,0.04)',
  },
  checked: {
    background: 'rgba(255,226,2,0.04)',
  },
}));

export default function ContainerInspectionFilter({ isSubmitting, cancel, apply }: ComponentProps) {
  const classes = useStyles();

  const { user } = useContext(AuthContext);

  const { handleSubmit, control } = useForm();

  const [fieldValues, setFieldValues] = useState(JSON.parse(localStorage.getItem('inspection-field-values')) || {});

  const history = useHistory();
  const params = new URLSearchParams(history?.location?.search);
  const inspector = params.get('inspector');

  const { data: locations } = useQuery({
    queryKey: QUERY_KEYS.all_locations,
    queryFn: async () => {
      const { data } = await get('/locations/all');
      return data;
    },
    placeholderData: [],
  });

  const { data: inspectors } = useQuery({
    queryKey: QUERY_KEYS.container_inspections_filter_inspectors,
    queryFn: async () => {
      const { data } = await get('/inspectors');
      return data;
    },
    placeholderData: [],
  });

  const { data: tagsValues } = useQuery({
    queryKey: QUERY_KEYS.container_inspections_filter_tags,
    queryFn: async () => {
      const { data } = await get('/inspections/tags/values');
      return data;
    },
    placeholderData: {},
  });

  const handleFilter = (data = {}) => {
    apply(data);
  };

  const updateFieldValues = (field: string, value: string) => {
    const newValues = { ...fieldValues };
    newValues[field] = value;
    setFieldValues(newValues);
    localStorage.setItem('inspection-field-values', JSON.stringify(newValues));
  };

  return (
    <div className={classes.wrapper}>
      <FormComponent onSubmit={handleSubmit(handleFilter)} hasBorder={false} hasPadding={false}>
        <FormControl component="fieldset" className="mb-3">
          <FormLabel component="legend" className={classes.formLabel}>
            <Row className="mt-2">
              <Col md={6}>Timeframe</Col>
              <Col md={6} className="reset">
                <span onClick={cancel}>Reset</span>
              </Col>
            </Row>
          </FormLabel>
          <Row className="mt-2">
            <Col md={6}>
              <Controller
                defaultValue={fieldValues['startDate'] ? moment(fieldValues['startDate']) : null}
                name="startDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const maxDate = new Date();
                  maxDate.setDate(maxDate.getDate() + 30);
                  return (
                    <KeyboardDatePicker
                      keyboardIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '112px',
                          }}
                        >
                          <CalendarMonth />
                          {value ? (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>{moment(value).format('MM/DD/yyyy')}</p>
                          ) : (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>Start Date</p>
                          )}
                        </div>
                      }
                      InputAdornmentProps={{ position: 'start' }}
                      style={!value ? { backgroundColor: '#F7F0B7', boxShadow: '0 3px 6px 0px #00000029' } : {}}
                      okLabel={<span style={{ color: '#000' }}>Ok</span>}
                      cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                      maxDateMessage={`Max selectable date is ${moment(maxDate).format('MM/DD/yyyy')}`}
                      maxDate={maxDate}
                      inputVariant="outlined"
                      disableToolbar
                      id="outboundDate"
                      name="outboundDate"
                      format="MM/DD/yyyy"
                      views={['year', 'month', 'date']}
                      value={value}
                      onChange={(date) => {
                        onChange(date);
                        updateFieldValues('startDate', date.format('MM/DD/yyyy'));
                      }}
                    />
                  );
                }}
              />
            </Col>
            <Col md={6}>
              <Controller
                defaultValue={fieldValues['endDate'] ? moment(fieldValues['endDate']) : null}
                name="endDate"
                control={control}
                render={({ field: { onChange, value } }) => {
                  const maxDate = new Date();

                  maxDate.setDate(maxDate.getDate() + 30);
                  return (
                    <KeyboardDatePicker
                      keyboardIcon={
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                            width: '112px',
                          }}
                        >
                          <CalendarMonth />
                          {value ? (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>{moment(value).format('MM/DD/yyyy')}</p>
                          ) : (
                            <p style={{ fontSize: '16px', marginTop: '16px' }}>End Date</p>
                          )}
                        </div>
                      }
                      InputAdornmentProps={{ position: 'start' }}
                      style={!value ? { backgroundColor: '#F7F0B7', boxShadow: '0 3px 6px 0px #00000029' } : {}}
                      okLabel={<span style={{ color: '#000' }}>Ok</span>}
                      cancelLabel={<span style={{ color: '#000' }}>Cancel</span>}
                      maxDateMessage={`Max selectable date is ${moment(maxDate).format('MM/DD/yyyy')}`}
                      maxDate={maxDate}
                      inputVariant="outlined"
                      disableToolbar
                      id="inboundDate"
                      name="inboundDate"
                      format="MM/DD/yyyy"
                      views={['year', 'month', 'date']}
                      value={value}
                      onChange={(date) => {
                        onChange(date);
                        updateFieldValues('endDate', date.format('MM/DD/yyyy'));
                      }}
                    />
                  );
                }}
              />
            </Col>
          </Row>
        </FormControl>

        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mt-2': true, 'mb-2': true })}>
          Locations
        </FormLabel>
        <Controller
          name="location"
          control={control}
          defaultValue=""
          render={({ field: { onChange, value } }) => {
            return (
              <FormControl variant="outlined" component="fieldset" className="mb-1" margin="normal">
                <InputLabel id="container-inspection-location">Choose from {locations?.length} Locations</InputLabel>
                <Select
                  value={value || fieldValues['location']}
                  onChange={(e) => {
                    onChange(e.target.value);
                    updateFieldValues('location', e?.target?.value as unknown as string);
                  }}
                  labelId="container-inspection-location"
                  label={`Choose from ${locations?.length} Locations`}
                >
                  {locations?.map((option, i) => (
                    <MenuItem key={i} value={option._id}>
                      {option.firstName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            );
          }}
        />

        {!inspector && (
          <>
            <FormLabel component="legend" className={clsx(classes.formLabel, { 'mt-2': true, 'mb-2': true })}>
              Inspectors
            </FormLabel>
            <Controller
              name="inspector"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value } }) => {
                return (
                  <FormControl variant="outlined" component="fieldset" className="mb-1" margin="normal">
                    <InputLabel id="container-inspection-inspector">
                      Choose from {inspectors?.length} Inspectors
                    </InputLabel>
                    <Select
                      value={value || fieldValues['inspector']}
                      onChange={(e) => {
                        onChange(e.target.value);
                        updateFieldValues('inspector', e?.target?.value as unknown as string);
                      }}
                      labelId="container-inspection-inspector"
                      label={`Choose from ${inspectors?.length} Inspectors`}
                    >
                      {inspectors?.map((option, i) => (
                        <MenuItem key={i} value={option._id}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                );
              }}
            />
          </>
        )}

        <>
          <FormLabel component="legend" className={clsx(classes.formLabel, { 'mb-2': true, 'mt-3': true })}>
            Issues
          </FormLabel>
          <Controller
            name="issues"
            control={control}
            defaultValue={false}
            render={({ field: { onChange, value } }) => (
              <RadioGroup
                name="issues"
                value={value || fieldValues['issues']}
                onChange={(e) => {
                  onChange(e.target.value);
                  updateFieldValues('issues', e.target.value);
                }}
              >
                <FormControlLabel value={'0'} control={<Radio />} label={'Yes'} />
                <FormControlLabel value={'1'} control={<Radio />} label={'No'} />
              </RadioGroup>
            )}
          />
        </>

        <FormLabel component="legend" className={clsx(classes.formLabel, { 'mb-2': true, 'mt-3': true })}>
          Tags
        </FormLabel>

        {user?.tags?.map((tag) => (
          <Controller
            key={tag.name}
            name={`tags.${tag.name}`}
            control={control}
            defaultValue=""
            render={({ field: { onChange, value } }) => {
              return (
                <FormControl variant="outlined" component="fieldset" className="mb-1" margin="normal">
                  <InputLabel id={tag.name}>
                    Choose from Tag {tag.name} ({tagsValues?.[tag.name]?.length})
                  </InputLabel>
                  <Select
                    value={value || fieldValues[`tags.${tag.name}`]}
                    onChange={(e) => {
                      onChange(e.target.value);
                      updateFieldValues(`tags.${tag.name}`, e?.target?.value as unknown as string);
                    }}
                    labelId={tag.name}
                    label={`Choose from Tag ${tag.name} (${tagsValues?.[tag.name]?.length})`}
                  >
                    {tagsValues?.[tag.name]?.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              );
            }}
          />
        ))}

        <Row className="mt-2">
          <Col>
            {!!cancel && (
              <Button type="button" fullWidth variant="contained" color="default" onClick={cancel}>
                Cancel
              </Button>
            )}
          </Col>
          <Col>
            <Loader isLoading={isSubmitting} small>
              <Button type="submit" fullWidth variant="contained" color="primary" disabled={isSubmitting}>
                APPLY
              </Button>
            </Loader>
          </Col>
        </Row>
      </FormComponent>
    </div>
  );
}
