import React, { useContext } from 'react';
import { Font, PDFDownloadLink } from '@react-pdf/renderer';
import { makeStyles } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import JsBarcode from 'jsbarcode';
import { AuthContext } from '../../auth';
import { formatDate } from '../../utils/helpers';
import { FileDownload } from 'mdi-material-ui';
import InspectionPDF from './InspectionPDF';
import Roboto from '../../styles/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../styles/fonts/Roboto-Bold.ttf';
import { get } from '../../utils/axios';
import { useQuery } from 'react-query';
import { QUERY_KEYS } from '../../constants';
import { toastError } from '../../utils/toastError';

Font.register({ family: 'Roboto', src: Roboto });
Font.register({ family: 'RobotoBold', src: RobotoBold });

const useStyles = makeStyles(() => ({
  pageContainer: { width: 1000, display: 'flex', flexDirection: 'column' },
  pdfDownload: {
    marginLeft: 'auto',
    cursor: 'pointer',
  },
  inspectionContainer: {
    display: 'grid',
    gridTemplateColumns: '500px 500px',
    border: '1px solid #ccc',
    padding: '48px 32px',
    gridGap: 20,
  },
  containerDetails: { borderRight: '1px solid #ccc', position: 'relative', paddingBottom: 100 },
  code: { fontSize: 28, fontWeight: 'bold', marginBottom: 6 },
  inspectionType: { fontSize: 16, fontWeight: 'bold' },
  text: { lineHeight: 1.6 },
  tags: { marginTop: 20 },
  issuesContainer: { marginTop: 20 },
  issuesHeading: { lineHeight: 1.8, marginBottom: 6 },
  issue: { lineHeight: 2.4, display: 'flex', alignItems: 'center' },
  checkbox: {
    border: '1px solid #000',
    width: 22,
    height: 24,
    marginRight: 8,
  },
  inspection: { margin: '20px 0' },
  inspectionHeading: { marginBottom: 12 },
  inspectionText: { display: 'flex', alignItems: 'center' },
  signature: {
    height: 150,
    width: '90%',
    border: '1px solid #ccc',
    marginTop: 10,
  },
  imagesHeading: { borderBottom: '1px solid #ccc', paddingBottom: 16, width: '80%' },
  imageContainer: { marginTop: 10 },
  imageSection: { borderBottom: '1px solid #ccc', paddingBottom: 16, width: '80%' },
  logo: {
    width: 240,
    height: 56,
    objectFit: 'fill',
    position: 'absolute',
    bottom: 0,
  },
}));

const Inspection = () => {
  const classes = useStyles();
  const { user } = useContext(AuthContext);

  const params: any = useParams();

  const { data: inspection } = useQuery({
    queryKey: [QUERY_KEYS.inspection, params.id],
    queryFn: async () => {
      const { data } = await get(`/inspections/${params.id}`);
      return data;
    },
    onError: toastError,
  });

  const totalImages = inspection?.issues?.reduce((acc, issue) => {
    acc += issue?.photos?.length || 0;
    return acc;
  }, 0);

  const moreThanOnePageOfImages = totalImages > 18;

  const canvas = document.createElement('canvas');
  JsBarcode(canvas, inspection?.code, { displayValue: false });

  const generatePDFDownloadLink = () => {
    return (
      <PDFDownloadLink
        document={
          <InspectionPDF
            containerInspection={inspection}
            timezone={user?.timezone}
            moreThanOnePageOfImages={moreThanOnePageOfImages}
          />
        }
        fileName={`container_inspection_${inspection.code}.pdf`}
        className={classes.pdfDownload}
      >
        {({ blob, url, loading, error }) => <FileDownload className="mb-3" />}
      </PDFDownloadLink>
    );
  };

  if (inspection) {
    return (
      <div className={classes.pageContainer}>
        <div style={{ alignSelf: 'flex-end' }}>{generatePDFDownloadLink()}</div>

        <div className={classes.inspectionContainer}>
          <div className={classes.containerDetails}>
            <div style={{ marginBottom: '18px' }}>
              <div className={classes.code}>{inspection.code}</div>

              <div className={classes.inspectionType}>
                {`${inspection.type === '17' ? '1' : ''}7-Pt CTPAT Container Inspection`}
              </div>
            </div>

            <div>
              <div className={classes.text}>Location: {inspection?.location}</div>

              <div className={classes.text}>Date: {formatDate(inspection?.createdAt, user?.timezone, true)}</div>

              <div className={classes.text}>
                Seal Number (On Arrival): {inspection?.seal?.arrival && inspection.seal.arrival}
              </div>

              <div className={classes.text}>
                Seal Number (On Departure): {inspection?.seal?.departure && inspection.seal.departure}
              </div>

              {inspection?.tags && (
                <div className={classes.tags}>
                  <b>Custom Tags</b>
                  {Object.keys(inspection.tags).map((tag, idx) => (
                    <div key={idx} className={classes.text}>
                      {tag}: {inspection.tags[tag]}
                    </div>
                  ))}
                </div>
              )}

              <div className={classes.issuesContainer}>
                <div className={classes.issuesHeading}>
                  <b>Container Areas with Issues</b>
                </div>

                <div
                  style={{
                    display: 'grid',
                    gridTemplateColumns: inspection.type === '17' ? '1fr 1fr' : '1fr',
                  }}
                >
                  {inspection.issues.map((issue, idx) => {
                    return (
                      <div
                        key={idx}
                        className={classes.issue}
                        style={{
                          gridColumn: idx <= 8 ? '1 / 2' : '2 / 3',
                          gridRow: idx > 8 ? ((idx + 1) % 10) + 1 : idx + 1,
                        }}
                      >
                        <span
                          className={classes.checkbox}
                          style={{
                            backgroundColor: issue.issue ? 'red' : 'none',
                          }}
                        />
                        <span>
                          [{idx + 1}] {issue.name}
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>

              <div className={classes.inspection}>
                <div className={classes.inspectionHeading}>
                  <b>Agricultural Inspection for Pest Contamination</b>
                </div>

                <div className={classes.inspectionText}>
                  <span
                    className={classes.checkbox}
                    style={{
                      backgroundColor: inspection?.extraInspection?.agriculture ? '#ffe202' : 'none',
                    }}
                  />
                  <span>Yes - I made an Agricultural Inspection for Pest Contamination.</span>
                </div>
              </div>

              <div className={classes.inspection}>
                <div className={classes.inspectionHeading}>
                  <b>Hardware Inspection</b>
                </div>

                <div className={classes.inspectionText}>
                  <span
                    className={classes.checkbox}
                    style={{
                      width: 27,
                      backgroundColor: inspection?.extraInspection?.hardware ? '#ffe202' : 'none',
                    }}
                  />
                  <span>
                    <span style={{ display: 'inline-block' }}>Yes - I made a Hardware Inspection</span>
                    <span style={{ display: 'inline-block' }}>
                      (Doors, Handles, Rods, Hasps, Rivets, Brackets, etc.)
                    </span>
                  </span>
                </div>
              </div>

              <div>
                <div>Inspector: {inspection?.inspector?.name}</div>
                <img
                  src={inspection?.inspector?.signature}
                  alt="signature"
                  className={classes.signature}
                  height="150px"
                />
              </div>
            </div>

            <img src="/assets/ctpat-logo.png" alt="CTPAT logo" className={classes.logo} />
          </div>

          <div>
            <div className={classes.imagesHeading}>
              <b>Photo Evidence of Issues</b>
            </div>

            <div className={classes.imageContainer}>
              {inspection.issues.map((issue, idx) => {
                if (issue.issue) {
                  return (
                    <div key={idx} className={classes.imageSection}>
                      <div style={{ lineHeight: 3 }}>
                        [{idx + 1}] {issue.name}
                      </div>

                      <div
                        style={{
                          width: '80%',
                          display: 'grid',
                          gridTemplateColumns: `repeat(${moreThanOnePageOfImages ? '5' : '3'}, 1fr)`,
                          gridGap: 8,
                        }}
                      >
                        {issue?.photos?.map((photo: string, photoIdx: number) => (
                          <img
                            key={photoIdx}
                            src={photo}
                            alt={`${issue.name}-image-${photoIdx}`}
                            style={{
                              width: moreThanOnePageOfImages ? 70 : 100,
                              height: 140,
                              objectFit: 'fill',
                              border: '1px solid gray',
                              marginBottom: 8,
                            }}
                          />
                        ))}
                      </div>

                      {issue.comment && <div>{issue.comment}</div>}
                    </div>
                  );
                }

                return <span key={idx}></span>;
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return null;
};

export default Inspection;
