import { Button, TextField, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Controller, useForm } from 'react-hook-form';
import { Col, Row } from 'reactstrap';
import { toast } from 'react-toastify';
import iosDevices from 'ios-device-list';
import androidDevices from 'android-device-list';

import FormComponent from '../../components/FormComponent';
import Loader from '../../components/Loader';
import { get, post } from '../../utils/axios';

const useStyles = makeStyles((theme) => ({
  form: {
    width: '100%', // Fix IE 11 issue.
    border: 'none',
  },
  rightCol: {
    paddingLeft: `${theme.spacing(6)}px`,
    [theme.breakpoints.down('md')]: {
      paddingLeft: `${theme.spacing(3)}px`,
    },
  },
  formLabel: {},
  containerSpecific: {},
}));

const PageSupport = () => {
  const classes = useStyles();

  const { handleSubmit, control, setValue, watch } = useForm();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [locationList, setLocationList] = useState([]);
  const [platformValue, setPlatformValue] = useState(null);
  const [deviceInputValue, setDeviceInputValue] = useState('');
  const [deviceList, setDeviceList] = useState([]);

  const fetchLocations = async () => {
    try {
      const { data } = await get('/locations/support');

      setLocationList(data || []);
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  // devices
  const parseDeviceList = (list: any[]) => {
    const unique = Array.from(new Set(list)).map((x) => ({
      label: x,
      value: x,
    }));

    return unique.slice(0, 50);
  };

  const getDeviceList = () => {
    const ios = iosDevices
      .devices()
      .filter(
        (x) =>
          !x.Generation.toLowerCase().includes('apple tv') &&
          !x.Generation.toLowerCase().includes('apple watch') &&
          !x.Generation.toLowerCase().includes('airpods') &&
          !x.Generation.toLowerCase().includes('home'),
      )
      .map((x) => `Apple ${x.Generation}`);
    const android = androidDevices.deviceList().map((x) => `${x.brand} ${x.name}`);

    return { ios, android };
  };

  useEffect(() => {
    const iosFiltered = getDeviceList().ios.filter((x) => x.toLowerCase().includes(deviceInputValue.toLowerCase()));
    const androidFiltered = getDeviceList().android.filter((x) =>
      x.toLowerCase().includes(deviceInputValue.toLowerCase()),
    );

    if (platformValue === 'iOS') {
      setDeviceList(parseDeviceList([...iosFiltered]));
    }
    if (platformValue === 'Android') {
      setDeviceList(parseDeviceList([...androidFiltered]));
    }
  }, [platformValue, deviceInputValue]);

  const handleDeviceInputChange = (event) => {
    setDeviceInputValue(event.target.value);
  };

  // os
  const osListIOS = [
    'iOS 8.0',
    'iOS 8.1',
    'iOS 8.2',
    'iOS 8.3',
    'iOS 8.4',
    'iOS 9.0',
    'iOS 9.1',
    'iOS 9.2',
    'iOS 9.3',
    'iOS 10.0',
    'iOS 10.1',
    'iOS 10.2',
    'iOS 10.3',
    'iOS 11.0',
    'iOS 11.1',
    'iOS 11.2',
    'iOS 11.3',
    'iOS 11.4',
    'iOS 12.0',
    'iOS 12.1',
    'iOS 12.2',
    'iOS 12.3',
    'iOS 12.4',
    'iOS 12.5',
    'iOS 13.0',
    'iOS / iPadOS 13.1',
    'iOS / iPadOS 13.2',
    'iOS / iPadOS 13.3',
    'iOS / iPadOS 13.4',
    'iOS / iPadOS 13.5',
    'iOS / iPadOS 13.6',
    'iOS / iPadOS 13.7',
    'iOS / iPadOS 14.0',
    'iOS / iPadOS 14.1',
    'iOS / iPadOS 14.2',
    'iOS / iPadOS 14.3',
    'iOS / iPadOS 14.4',
    'iOS / iPadOS 14.5',
    'iOS / iPadOS 14.6',
    'iOS / iPadOS 14.7',
    'iOS / iPadOS 14.8',
    'iOS / iPadOS 15.0',
    'iOS / iPadOS 15.1',
    'iOS / iPadOS 15.2',
    'iOS / iPadOS 15.3',
    'iOS / iPadOS 15.4',
  ];

  const osListAndroid = ['Android 6', 'Android 7', 'Android 8', 'Android 9', 'Android 10', 'Android 11', 'Android 12'];

  const onSubmit = async (fields) => {
    const { priority, subject, platform, device, operatingSystem, location, description } = fields;
    setIsSubmitting(true);

    const emailContent = `
      Platform: ${platform}<br/>
      Device: ${device}<br/>
      Operating System: ${operatingSystem}<br/>
      Location: ${location}<br/>

      ${description}
    `;

    try {
      await post(`/support`, {
        priority,
        subject,
        content: emailContent,
      });

      toast.success('Request submitted', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

    setIsSubmitting(false);
  };

  const watchPlatform = watch('platform');

  return (
    <>
      <Row>
        <Col>
          <Typography className="mb-4" variant="h5">
            <b>Support</b>
          </Typography>
        </Col>
      </Row>
      <Row>
        <Col md={5} className="mt-2">
          <FormComponent
            onSubmit={handleSubmit(onSubmit)}
            className={classes.form}
            hasBorder={false}
            hasPadding={false}
          >
            <Controller
              name="priority"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const dropDownOptions = [
                  {
                    label: 'Normal',
                    value: 'Normal',
                  },
                  {
                    label: 'Urgent',
                    value: 'Urgent',
                  },
                ];

                return (
                  <Autocomplete
                    options={dropDownOptions}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(e, o: any) => onChange(o?.value || '')}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={value}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="*Priority Level"
                        />
                      );
                    }}
                  />
                );
              }}
              rules={{
                required: 'Priority Level required',
              }}
            />

            <Controller
              name="subject"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  placeholder="*Subject"
                  style={{ marginTop: '12px' }}
                />
              )}
              rules={{
                required: '* Subject required',
              }}
            />

            <Controller
              name="platform"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const dropDownOptions = [
                  {
                    label: 'iOS',
                    value: 'iOS',
                  },
                  {
                    label: 'Android',
                    value: 'Android',
                  },
                ];

                return (
                  <Autocomplete
                    options={dropDownOptions}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(e, o: any) => {
                      onChange(o?.value || '');
                      setPlatformValue(o?.value);
                      setValue('device', '');
                      setValue('operatingSystem', '');
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={platformValue}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="*Choose your platform"
                          style={{ marginTop: '12px' }}
                        />
                      );
                    }}
                  />
                );
              }}
              rules={{
                required: 'Platform required',
              }}
            />

            <Controller
              name="device"
              control={control}
              render={({ field: { onChange }, fieldState: { error } }) => {
                const dropDownOptions = [
                  {
                    label: 'Other',
                    value: 'Other',
                  },
                  ...deviceList,
                ];

                return (
                  <Autocomplete
                    key={`${watchPlatform}1`}
                    options={dropDownOptions}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(e, o: any) => {
                      onChange(o?.value || '');
                    }}
                    disabled={!platformValue}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={deviceInputValue}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="*Choose your device"
                          style={{ marginTop: '12px' }}
                          onChange={handleDeviceInputChange}
                        />
                      );
                    }}
                  />
                );
              }}
              rules={{
                required: 'Device required',
              }}
            />

            <Controller
              name="operatingSystem"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const dropDownOptions = [
                  {
                    label: 'Other',
                    value: 'Other',
                  },
                  ...[...(platformValue === 'iOS' ? osListIOS : osListAndroid)].map((x) => ({ label: x, value: x })),
                ];

                return (
                  <Autocomplete
                    key={`${watchPlatform}2`}
                    options={dropDownOptions}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(e, o: any) => onChange(o?.value || '')}
                    disabled={!platformValue}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={value}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="*Choose your OS"
                          style={{ marginTop: '12px' }}
                        />
                      );
                    }}
                  />
                );
              }}
              rules={{
                required: 'Operating system required',
              }}
            />

            <Controller
              name="location"
              control={control}
              render={({ field: { onChange, value }, fieldState: { error } }) => {
                const dropDownOptions = locationList.map((location) => ({ label: location, value: location }));

                return (
                  <Autocomplete
                    options={dropDownOptions}
                    getOptionLabel={(option) => option.label || ''}
                    onChange={(e, o: any) => onChange(o?.value || '')}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          error={!!error}
                          helperText={error ? error.message : null}
                          value={value}
                          variant="outlined"
                          margin="normal"
                          fullWidth
                          placeholder="*Choose location"
                          style={{ marginTop: '12px' }}
                        />
                      );
                    }}
                  />
                );
              }}
              rules={{
                required: 'Location required',
              }}
            />

            <Controller
              name="description"
              control={control}
              defaultValue=""
              render={({ field: { onChange, value }, fieldState: { error } }) => (
                <TextField
                  autoFocus
                  onChange={onChange}
                  error={!!error}
                  helperText={error ? error.message : null}
                  value={value}
                  variant="outlined"
                  margin="normal"
                  fullWidth
                  placeholder="*Tell us about your issue"
                  multiline
                  rows={8}
                  rowsMax={12}
                  style={{ marginTop: '12px' }}
                />
              )}
              rules={{
                required: 'Description required',
              }}
            />

            <Loader isLoading={isSubmitting} className="mt-4">
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
                style={{ width: '100%', padding: '10px 0', fontWeight: 'bolder' }}
              >
                SUBMIT
              </Button>
            </Loader>
          </FormComponent>
        </Col>
      </Row>
    </>
  );
};

export default PageSupport;
