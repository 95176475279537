import { get } from '../utils/axios';

export const fetchUpcomingBilling = async (customerId: string) => {
  const url = `/billing/${customerId}/history/upcoming`;

  const { data } = await get(url);
  return data;
};

export const fetchBillingByLocation = async (customerId: string) => {
  const url = `/billing/${customerId}/history/locations`;

  const { data } = await get(url);
  return data;
};

export const fetchBillingByInvoice = async (customerId: string) => {
  const url = `/billing/${customerId}/history/invoices`;

  const { data } = await get(url);
  return data;
};
