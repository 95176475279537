import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  container: {
    '& .MuiDialog-paper': {
      maxWidth: 800,
      padding: '40px 60px',
    },
  },
  heading: {
    fontWeight: 'bold',
    fontSize: 20,
  },
  desc: {
    fontSize: 12,
    marginTop: 20,
    lineHeight: 1.1,
  },
  imgContainer: {
    marginTop: 20,
  },
  img: {
    width: 400,
    objectFit: 'cover',
  },
  link: {
    color: '#5572EA',
    fontWeight: 'bold',
    display: 'block',
    marginTop: 10,
  },
  compliance: {
    width: '100%',
    objectFit: 'cover',
    marginTop: 20,
  },
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

const TripsDialog: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <Dialog open={open} className={classes.container} onClose={onClose}>
      <Typography className={classes.heading}>Instructions for Truck Trips (By Class)</Typography>
      <Typography className={classes.desc}>
        1. In the Data Summary tab of Babaco Fulfillment, go to the <b>1 - Truck Trips (By Equipment Class)</b>.
        Clicking on the button allows you to copy the number of <i>trips</i>, which you will then paste in the
        corresponding form fields in the WAIRE report filing. You will need to do this process of copying and pasting
        for each data point.
      </Typography>

      <Box className={classes.imgContainer}>
        <img src="/assets/waire-trips-step-2-header.png" alt="step 2 header" className={classes.img} />
        <img src="/assets/waire-trips-step-2-body.png" alt="step 2 body" className={classes.img} />
      </Box>

      <Typography className={classes.desc}>
        2. Log into your WAIRE Program Online Portal (POP) at:{' '}
        <Link href="https://xappp.aqmd.gov/isr" target="_blank" className={classes.link}>
          https://xappp.aqmd.gov/isr
        </Link>
      </Typography>

      <Typography className={classes.desc}>
        3. Go to <b>Section 2: Compliance of the Annual WAIRE Report Filing</b>
      </Typography>

      <Typography className={classes.desc}>
        4. In the WAIRE application, you need to fill out two form fields with numbers that Babaco Fulfillment provides
        for you. Use each copied number from Babaco Fulfillment to paste in the corresponding field in WAIRE. In the
        Class 8 field, paste the Class 8 number you copied from Babaco Fulfillment. In the Class 2b-7, paste the Class
        2b-7 number you copied from Babaco Fulfillment:
      </Typography>

      <img src="/assets/waire-trips-step-4-compliance.png" alt="step 4 compliance" className={classes.compliance} />
    </Dialog>
  );
};

export default TripsDialog;
