import { get, patch } from '../utils/axios';
import { PAGE_LIMIT } from '../constants';
import moment from 'moment';

export const fetchCustomers = async (page = 1) => {
  const url = `/customers?page=${page}&limit=${PAGE_LIMIT}`;

  const { data } = await get(url);
  return data;
};

export const fetchCustomerLocations = async (page = 1, customerId: string) => {
  const { data } = await get(`/customers/${customerId}/locations?page=${page}&limit=${PAGE_LIMIT}`);
  return data;
};

export const fetchCustomerSummary = async (customerId: string) => {
  const { data } = await get(`/customers/${customerId}`);
  return data;
};

export const fetchCustomerProfile = async (customerId: string) => {
  const { data } = await get(`/customers/${customerId}/profile`);
  return data;
};

export const updateCustomerProfile = async (profile: any, fields: any, customerId: string) => {
  const toUpdate = {
    address: fields.address,
    features: {
      bol: fields.bol,
      waire: fields.waire,
      logistic: fields.logistic,
      inspection: fields.inspection,
    },
    email: fields.email,
    firstName: fields.firstName,
    lastName: fields.lastName,
    phone: fields.phoneNumber,
    locationFee: fields.locationFee,
    company: fields.company,
    overageFee: fields.overageFee,
    overageThreshold: fields.overageThreshold,
  };

  if (fields.password) toUpdate['password'] = fields.password;
  if (!profile?.billingCycle && fields.billingCycle)
    toUpdate['billingCycle'] = moment(fields.billingCycle).format('MM-DD-YYYY');

  await patch(`/customers/${customerId}`, toUpdate);
};
