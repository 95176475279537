import React, { useContext, useState, useCallback } from 'react';
import Checkbox from '@material-ui/core/Checkbox';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import moment from 'moment';
import { formatDate } from '../../utils/helpers';
import Table from '../../components/Table';
import { AuthContext } from '../../auth';
import { patch } from '../../utils/axios';
import { QUERY_KEYS, TOAST_CONFIG } from '../../constants';
import { fetchInspectors } from '../../api/inspector';
import { queryClient } from '../../api/queryClient';
import { toastError } from '../../utils/toastError';

const sortFnByField = {
  name: {
    asc: (a, b) => a.name.localeCompare(b.name),
    desc: (a, b) => b.name.localeCompare(a.name),
  },
  location: {
    asc: (a, b) => a.location.localeCompare(b.location),
    desc: (a, b) => b.location.localeCompare(a.location),
  },
  containers: {
    asc: (a, b) => a.containers - b.containers,
    desc: (a, b) => b.containers - a.containers,
  },
  issues: {
    asc: (a, b) => a.issues - b.issues,
    desc: (a, b) => b.issues - a.issues,
  },
  createdAt: {
    asc: (a, b) => moment(a.createdAt).unix() - moment(b.createdAt).unix(),
    desc: (a, b) => moment(b.createdAt).unix() - moment(a.createdAt).unix(),
  },
  mostRecentInspection: {
    asc: (a, b) => moment(a.mostRecentInspection).unix() - moment(b.mostRecentInspection).unix(),
    desc: (a, b) => moment(b.mostRecentInspection).unix() - moment(a.mostRecentInspection).unix(),
  },
};

const Inspectors = () => {
  const history = useHistory();

  const { user } = useContext(AuthContext);

  const [sort, setSort] = useState<{ field: string; order: 'asc' | 'desc' | '' }>({ field: '', order: '' });

  const sortData = useCallback(
    (data) => {
      const newData = [...data];

      if (sort.field && sort.order) {
        newData.sort(sortFnByField[sort.field][sort.order]);
        return newData;
      }

      return data;
    },
    [sort.field, sort.order],
  );

  const { data, isLoading } = useQuery({
    queryKey: QUERY_KEYS.inspectors,
    queryFn: () => fetchInspectors(),
    select: (data) => sortData(data),
    onError: toastError,
  });

  const changeInspectorStatus = async (e, id) => {
    e.stopPropagation();

    try {
      await patch(`/inspectors/${id}`, {});
      queryClient.invalidateQueries({ queryKey: QUERY_KEYS.inspectors });
    } catch (error) {
      toast.error(error?.response?.data?.message, TOAST_CONFIG);
    }
  };

  const columns = [
    {
      title: 'Show/Hide',
      field: 'disabled',
      default: true,
      render: (data) => <Checkbox checked={!data.disabled} onClick={(e) => changeInspectorStatus(e, data._id)} />,
    },
    { title: 'Inspector', field: 'name', default: true, sorting: true },
    { title: 'Location', field: 'location', default: true, sorting: true },
    { title: 'Containers', field: 'containers', default: true, sorting: true },
    { title: 'Issues', field: 'issues', default: true, sorting: true },
    {
      title: 'Date Created',
      field: 'createdAt',
      default: true,
      sorting: true,
      render: (data) => formatDate(data?.createdAt, user?.timezone),
    },
    {
      title: 'Most Recent',
      field: 'mostRecentInspection',
      default: true,
      sorting: true,
      render: (data) => formatDate(data?.mostRecentInspection, user?.timezone),
    },
  ];

  const onRowClick = (_, rowData) => {
    history.push(`/container-inspections?inspector=${rowData._id}`);
  };

  return (
    <Table
      pages={[data || []]}
      columns={columns}
      onRowClick={onRowClick}
      toolbarContent={[]}
      showToolbar={false}
      isLoading={isLoading}
      sorting={true}
      handleSortChange={(field: string, order: 'asc' | 'desc') => setSort({ field, order })}
      autoTableCell
      headerText="Manage Inspectors"
      description="If you click “Hide” in the Show/Hide column below, the inspector’s name and signature will not appear on the mobile app in the list of Existing Inspectors when they fill out an Inspection. If you select “Show” in the Show/Hide column below, the inspector’s name and signature will appear on the mobile app in the list of Existing Inspectors when they fill out an Inspection. By default, when an Inspector adds their name to a container inspection, it will be available for future use."
      headerRefresh={false}
    />
  );
};

export default Inspectors;
