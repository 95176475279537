import React from 'react';
import { Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, FormGroup, Typography } from '@material-ui/core';

const InputField = (props) => {
  const { defaultValue, name, control, uncheckAll, label } = props;

  return (
    <FormGroup row>
      <Controller
        defaultValue={defaultValue}
        name={name}
        control={control}
        render={({ field: { onChange, value } }) => {
          return (
            <FormControlLabel
              control={
                <Checkbox
                  checked={value}
                  onChange={(e) => {
                    uncheckAll();
                    onChange(e);
                  }}
                />
              }
              label={label}
            />
          );
        }}
      />
    </FormGroup>
  );
};

const InputFieldsEmailSettings = (props) => {
  const { control, form = [], uncheckAll, difference = {} } = props;

  const renderInputFields = () => {
    return form?.map((item, idx) => (
      <InputField
        key={idx}
        defaultValue={difference[item.name]}
        name={`alerts.bol.different[${item.name}]`}
        control={control}
        uncheckAll={uncheckAll}
        label={`Inbound has different ${item.name}`}
      />
    ));
  };

  return (
    <>
      {form?.length > 0 && (
        <Typography variant="subtitle1" className="mt-2 mb-2">
          <b>Receive an email for specific alerts:</b>
        </Typography>
      )}

      {renderInputFields()}
    </>
  );
};

export default InputFieldsEmailSettings;
